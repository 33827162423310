import React, { useCallback, useEffect, useState } from 'react';

import log from './log';

type DebugVariables = {
  DESKTOP_WHEEL_LINE_HEIGHT: number;
  DESKTOP_SCROLL_SPEED: number;
  MOBILE_SCROLL_SPEED: number;
  MOBILE_SCROLL_THROTTLE: number;
  ZOOM_WHEEL_SPEED: number;
  ZOOM_TRACKPAD_SPEED: number;
  SCROLL_INVERT: boolean;
  MICROPHONE_THRESHOLD: number;

  // Map type so we can type check the values
  [key: string]: any;
};

export type DebugStatusValue =
  | string
  | number
  | boolean
  | null
  | undefined
  | DebugStatus
  | DebugStatusValue[];

export type DebugStatus = {
  [key: string]: DebugStatusValue;
};

interface DebugContextData {
  variables: DebugVariables;
  triggers: { [key: string]: () => void };
  addTrigger: (trigger: string, callback: () => void) => void;
  removeTrigger: (trigger: string) => void;

  status: DebugStatus;
  setStatus: (key: string, value: boolean | number | string | DebugStatus) => void;

  debugPanelOpen: boolean;
  setDebugPanelOpen: (open: boolean) => void;
}

declare global {
  interface Window {
    debug: {
      variables: DebugVariables;
      triggers: { [key: string]: () => void };
      show: () => void;
    };
  }
}

const DefaultDebugVariables: DebugVariables = {
  DESKTOP_WHEEL_LINE_HEIGHT: 19,
  DESKTOP_SCROLL_SPEED: 3,
  MOBILE_SCROLL_SPEED: 1,
  MOBILE_SCROLL_THROTTLE: 100,
  ZOOM_WHEEL_SPEED: 0.2,
  ZOOM_TRACKPAD_SPEED: 0.2,
  MICROPHONE_THRESHOLD: 0.004,
  SCROLL_INVERT: true,
};

const Debug = React.createContext<DebugContextData>({} as DebugContextData);
const useDebug = () => React.useContext(Debug);

// This debug hook should be eventually removed when we reach a more stable state of the app.
// Since at the moment we have so many variables that still need iteration, it's easier if everyone
// is able to easily see the values and change them without needing a new PR.
const DebugProvider: React.FC = ({ children }) => {
  const [variables, setVariables] = useState<DebugVariables>(DefaultDebugVariables);
  const [triggers, setTriggers] = useState<{ [key: string]: () => void }>({});
  const [status, setStatus] = useState<DebugStatus>({});
  const [debugPanelOpen, setDebugPanelOpen] = useState(false);

  useEffect(() => {
    const handler = {
      set(target: any, key: string, value: any) {
        if (typeof value !== typeof DefaultDebugVariables[key]) {
          log(
            'error',
            `Attempted to set debug variable ${key} to a value of type ${typeof value}, but it must be of type ${typeof DefaultDebugVariables[
              key
            ]}.`
          );
          return false;
        }

        target[key] = value;
        setVariables({ ...variables, [key]: value });
        return true;
      },
    };

    const proxy = new Proxy(variables, handler);
    window.debug = {
      ...window.debug,
      variables: proxy,
    };

    return () => {
      window.debug = {
        ...window.debug,
        variables: DefaultDebugVariables,
      };
    };
  }, [variables]);

  // Trigger simba events
  useEffect(() => {
    window.debug.triggers = triggers;
    return () => {
      window.debug.triggers = {};
    };
  }, [triggers]);

  useEffect(() => {
    window.debug.show = () => setDebugPanelOpen(true);
  }, []);

  const addTrigger = useCallback((trigger: string, callback: () => void) => {
    setTriggers((triggers) => ({ ...triggers, [trigger]: callback }));
  }, []);

  const removeTrigger = useCallback((trigger: string) => {
    setTriggers((triggers) =>
      Object.keys(triggers)
        .filter((key) => key !== trigger)
        .reduce((acc: { [key: string]: () => void }, key) => {
          acc[key] = triggers[key];
          return acc;
        }, {})
    );
  }, []);

  const limitedSetStatus = useCallback(
    (key: string, value: boolean | number | string | DebugStatus) => {
      setStatus((status) => ({ ...status, [key]: value }));
    },
    []
  );

  return (
    <Debug.Provider
      value={{
        variables,
        triggers,
        addTrigger,
        removeTrigger,
        status,
        setStatus: limitedSetStatus,
        debugPanelOpen,
        setDebugPanelOpen,
      }}
    >
      {children}
    </Debug.Provider>
  );
};
export { DebugProvider, useDebug };
