import React from 'react';

import styles from './FabricError.module.scss';

const FabricError: React.FC = ({ children }) => {
  // Using <a> instead of <Link> to be as granular as possible
  // So if the error happens outside of the router, we still have a link

  return (
    <div className={styles.error}>
      <header>
        <a href="/" className={styles.dashboard_button}>
          ← dashboard
        </a>
      </header>
      <div className={styles.spacer} />
      <div className={styles.error_content}>{children}</div>
      <div className={styles.spacer} />
    </div>
  );
};

export default FabricError;
