import React from 'react';

import { CursorProps } from '../../hooks/cursor';
import { getTextColor } from '../../utils/color';

const CursorText: React.FC<CursorProps> = ({ color, style, ...props }) => {
  const outlineColor = getTextColor(color);

  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        marginTop: '-11px',
        marginLeft: '-7.9px',
        ...style,
      }}
      {...props}
    >
      <path
        d="M24.008 22.802H19.2m4.808 0H28.8m-4.792 0V11.225m0 11.577v13.706m0-25.283C22.826 7.689 19.137 5.848 15.6 6.01h-1.2m9.608 5.214c1.079-3.702 4.805-5.38 8.392-5.214h1.2m-9.592 30.496c1.068 3.663 4.82 5.648 8.392 5.483h1.2m-9.592-5.483c-1.218 3.644-4.8 5.65-8.408 5.483h-1.2"
        stroke={outlineColor}
        strokeWidth="6"
      />
      <path
        d="M20.4 22.792h3.608m0 0H27.6m-3.592 0v-11.57m0 11.57V36.49m0-25.268c-1.182-3.534-4.871-5.374-8.408-5.21m8.408 5.21c1.079-3.7 4.805-5.376 8.392-5.21M24.008 36.49c1.068 3.66 4.82 5.645 8.392 5.48m-8.392-5.48c-1.218 3.64-4.8 5.646-8.408 5.48"
        stroke={color}
        strokeWidth="4"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CursorText;
