import { Auth } from 'aws-amplify';
import { useEffect } from 'react';

import { useAuth } from './auth';
import { DesktopAuthDeepLink } from './todesktop';

const useHandleExtensionAuth = () => {
  const { user, isLoggedIn, authProcessing } = useAuth();

  useEffect(() => {
    if (!user?.attributes?.sub || !isLoggedIn || authProcessing) return;

    const sendAuthData = async () => {
      if (!user?.attributes?.sub) return;

      const session = await Auth.currentSession();
      const idToken = session.getIdToken().getJwtToken();
      const accessToken = session.getAccessToken().getJwtToken();
      const refreshToken = session.getRefreshToken().getToken();

      const iatIdToken = session.getIdToken().payload.iat;
      const iatAccessToken = session.getAccessToken().payload.iat;

      const data: DesktopAuthDeepLink = {
        idToken,
        accessToken,
        refreshToken,
        iatIdToken,
        iatAccessToken,
        userSub: user?.attributes.sub,
      };

      document.dispatchEvent(
        new CustomEvent('fabric-auth', {
          detail: data,
        })
      );
    };

    document.dispatchEvent(new CustomEvent('fabric-app-load'));

    document.addEventListener('fabric-needs-auth', () => {
      sendAuthData();
    });
  }, [user, isLoggedIn, authProcessing]);
};

export default useHandleExtensionAuth;
