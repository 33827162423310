import React from 'react';

const Mode: React.FC = () => {
  return (
    <div className="onboarding__content">
      <h3
        style={{
          maxWidth: '514px',
        }}
      >
        Switch between browser, screenshare and video call modes
      </h3>
      <p
        style={{
          maxWidth: '395px',
        }}
      >
        Dynamically switch to whatever mode best suits the current conversation or task
      </p>
    </div>
  );
};

export default Mode;
