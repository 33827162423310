import { HalFdoc, HalUser, NewFdoc, WoodyError } from '@fabric/woody-client';
import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

import { useAuth } from '../hooks/auth';
import { useWoody } from '../services/woody/WoodyProvider';
import { OptimisticDraft } from '../types/draftable';

export interface FdocStore {
  myFdocs: OptimisticDraft<HalFdoc>[];
  otherFdocs: OptimisticDraft<HalFdoc>[];
  listFdocs: Record<string, OptimisticDraft<HalFdoc>[]>;

  setMyFdocs: (
    fdocs:
      | OptimisticDraft<HalFdoc>[]
      | ((fdocs: OptimisticDraft<HalFdoc>[]) => OptimisticDraft<HalFdoc>[])
  ) => void;
  addOtherFdocs: (fdocs: OptimisticDraft<HalFdoc>[]) => void;
  setListFdocs: (listId: string, fdocs: OptimisticDraft<HalFdoc>[]) => void;

  reset: () => void;
}

const useFdocStore = create<FdocStore>()(
  persist(
    (set) => ({
      myFdocs: [],
      otherFdocs: [],
      listFdocs: {},
      setMyFdocs: (fdocs) =>
        set((state) => ({
          ...state,
          myFdocs: typeof fdocs === 'function' ? fdocs(state.myFdocs) : fdocs,
        })),
      addOtherFdocs: (fdocs) =>
        set((state) => ({
          ...state,
          otherFdocs: [...state.otherFdocs, ...fdocs],
        })),
      setListFdocs: (listId: string, fdocs: OptimisticDraft<HalFdoc>[]) =>
        set((state) => ({ ...state, listFdocs: { ...state.listFdocs, [listId]: fdocs } })),
      reset: () => set(() => ({ myFdocs: [], listFdocs: {} })),
    }),
    {
      name: 'fdoc',
      partialize: (state) => ({
        // for now we don't keep drafts because we wouldn't know the original fdoc
        myFdocs: state.myFdocs
          // after we have a way of knowing the original fdoc from a draft,
          // we can keep the drafts and actually prioritize them over the original fdoc
          // .sort((a, _) => (a.isDraft ? -1 : 1))
          // .map((fdoc) => {
          //   if (fdoc.type !== 'stored_file') return fdoc;
          //   return {
          //     ...fdoc,
          //     url: 'load',
          // };
          // })
          .filter((fdoc) => !fdoc.isDraft)
          .slice(0, 100),

        listFdocs: Object.fromEntries(
          Object.entries(state.listFdocs).map(([listId, fdocs]) => [listId, fdocs.slice(0, 100)])
        ),
      }),
    }
  )
);

export const useGetMyFdocs = () => {
  const { client } = useWoody();
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [fetched, setFetched] = useState(false);
  const [error, setError] = useState<WoodyError | null>(null);

  const sub = user?.attributes?.sub ?? '';

  const { myFdocs, setMyFdocs } = useFdocStore((state) => ({
    myFdocs: state.myFdocs,
    setMyFdocs: state.setMyFdocs,
  }));

  useEffect(() => {
    if (!sub || loading || fetched) return;

    const fetchNotes = async () => {
      setLoading(true);
      const response = await client.fetchFdocsByUserId(sub);

      setLoading(false);
      setFetched(true);

      if (response.error) {
        setError(response.error);
        return;
      }

      // replace all but keep the optimistic drafts
      setMyFdocs((fdocs) =>
        fdocs.filter((fdoc) => fdoc.isDraft).concat(response.data._embedded.fdocs)
      );
    };

    fetchNotes();
  }, [sub, loading, fetched, client, setMyFdocs]);

  // re-fetch notes every 15 seconds
  useEffect(() => {
    if (!sub || !fetched) return;

    const interval = setInterval(() => {
      setFetched(false);
    }, 15000);

    return () => clearInterval(interval);
  }, [sub, fetched]);

  return { myFdocs, setMyFdocs, loading, error };
};

export const useGetFdocById = (id) => {
  const { client } = useWoody();
  const [loading, setLoading] = useState(false);
  const [fetched, setFetched] = useState(false);
  const [error, setError] = useState<WoodyError | null>(null);

  const { otherFdocs, addOtherFdocs } = useFdocStore((state) => ({
    otherFdocs: state.otherFdocs,
    addOtherFdocs: state.addOtherFdocs,
  }));

  useEffect(() => {
    if (!id || loading || fetched) return;

    const fetchNotes = async () => {
      setLoading(true);
      const response = await client.fetchFdocById(id);

      setLoading(false);
      setFetched(true);

      if (response.error) {
        setError(response.error);
        return;
      }
      if (response.data) addOtherFdocs([response.data]);
    };

    fetchNotes();
  }, [loading, fetched, client, addOtherFdocs, id]);

  // re-fetch notes every 15 seconds
  useEffect(() => {
    if (!id || !fetched) return;

    const interval = setInterval(() => {
      setFetched(false);
    }, 15000);

    return () => clearInterval(interval);
  }, [id, fetched]);

  const fdoc = otherFdocs.find((f) => f.id === id) || null;
  return { fdoc, addOtherFdocs, loading, error };
};

export const createOptimisticFdoc = (
  user: HalUser,
  newFdoc: NewFdoc,
  commentCount: number = 0
): OptimisticDraft<HalFdoc> => {
  const optimisticId = uuidv4();

  return {
    id: optimisticId,
    draftId: optimisticId,
    commentCount,

    ...newFdoc,

    createdAt: new Date().toISOString(),
    modifiedAt: new Date().toISOString(),
    isDraft: true,

    _embedded: {
      user,
    },
    _links: {
      self: {
        href: `/notes/${optimisticId}`,
      },
    },
  };
};

export default useFdocStore;
