export const EVENT = {
  // Internal Events
  RECONNECTING: 'RECONNECTING',
  CONNECTING: 'CONNECTING',
  CONNECTING_LONGER_THAN_EXPECTED: 'CONNECTING_LONGER_THAN_EXPECTED',
  CONNECTED: 'CONNECTED',
  DISCONNECTED: 'DISCONNECTED',
  TRACK: 'TRACK',
  MESSAGE: 'MESSAGE',
  DATA: 'DATA',

  // Websocket Events
  SYSTEM: {
    INIT: 'system/init',
    DISCONNECT: 'system/disconnect',
    ERROR: 'system/error',
    INACTIVITY: 'system/inactivity',
    PING: 'system/ping',
  },
  SIGNAL: {
    OFFER: 'signal/offer',
    ANSWER: 'signal/answer',
    PROVIDE: 'signal/provide',
    CANDIDATE: 'signal/candidate',
  },
  MEMBER: {
    LIST: 'member/list',
    CONNECTED: 'member/connected',
    DISCONNECTED: 'member/disconnected',
  },
  CONTROL: {
    LOCKED: 'control/locked',
    RELEASE: 'control/release',
    REQUEST: 'control/request',
    REQUESTING: 'control/requesting',
    CLIPBOARD: 'control/clipboard',
    GIVE: 'control/give',
    KEYBOARD: 'control/keyboard',
  },
  CHAT: {
    MESSAGE: 'chat/message',
    EMOTE: 'chat/emote',
  },
  SCREEN: {
    CONFIGURATIONS: 'screen/configurations',
    RESOLUTION: 'screen/resolution',
    SET: 'screen/set',
  },
  BROADCAST: {
    STATUS: 'broadcast/status',
    CREATE: 'broadcast/create',
    DESTROY: 'broadcast/destroy',
  },
  ADMIN: {
    BAN: 'admin/ban',
    KICK: 'admin/kick',
    LOCK: 'admin/lock',
    UNLOCK: 'admin/unlock',
    MUTE: 'admin/mute',
    UNMUTE: 'admin/unmute',
    CONTROL: 'admin/control',
    RELEASE: 'admin/release',
    GIVE: 'admin/give',
  },
  PRIVATE: {
    LOCKED: 'private/locked',
    REQUEST: 'private/request',
    RELEASE: 'private/release',
  },
  TAB: {
    NAVIGATE: 'tab/navigate',
    INFO: 'tab/info',
    BACK: 'tab/back',
    FORWARD: 'tab/forward',
    RELOAD: 'tab/reload',
    FOCUS: 'tab/focus',
    CLOSE: 'tab/close',
  },
  BROWSER: {
    RESET: 'browser/reset',
    FOCUS: 'browser/focus',
    UPDATE: 'browser/tabupdate',
    NEW: 'browser/newtab',
    CLOSED: 'browser/tabclosed',
    FAVICON: 'browser/favicon',
  },
  SUPPORT: {
    FAVICON: 'support/favicon',
  },
  MOUSE: {
    CURSOR: 'mouse/cursor',
  },
} as const;

export type Events = typeof EVENT;

export type WebSocketEvents =
  | SystemEvents
  | ControlEvents
  | MemberEvents
  | SignalEvents
  | ChatEvents
  | ScreenEvents
  | BroadcastEvents
  | AdminEvents
  | PrivateEvents
  | TabEvents
  | BrowserEvents
  | SupportEvents
  | MouseEvents;

export type ControlEvents =
  | typeof EVENT.CONTROL.LOCKED
  | typeof EVENT.CONTROL.RELEASE
  | typeof EVENT.CONTROL.REQUEST
  | typeof EVENT.CONTROL.GIVE
  | typeof EVENT.CONTROL.CLIPBOARD
  | typeof EVENT.CONTROL.KEYBOARD;

export type SystemEvents =
  | typeof EVENT.SYSTEM.INIT
  | typeof EVENT.SYSTEM.DISCONNECT
  | typeof EVENT.SYSTEM.ERROR
  | typeof EVENT.SYSTEM.PING;

export type MemberEvents =
  | typeof EVENT.MEMBER.LIST
  | typeof EVENT.MEMBER.CONNECTED
  | typeof EVENT.MEMBER.DISCONNECTED;

export type SignalEvents =
  | typeof EVENT.SIGNAL.OFFER
  | typeof EVENT.SIGNAL.ANSWER
  | typeof EVENT.SIGNAL.PROVIDE
  | typeof EVENT.SIGNAL.CANDIDATE;

export type ChatEvents = typeof EVENT.CHAT.MESSAGE | typeof EVENT.CHAT.EMOTE;
export type ScreenEvents =
  | typeof EVENT.SCREEN.CONFIGURATIONS
  | typeof EVENT.SCREEN.RESOLUTION
  | typeof EVENT.SCREEN.SET;

export type BroadcastEvents =
  | typeof EVENT.BROADCAST.STATUS
  | typeof EVENT.BROADCAST.CREATE
  | typeof EVENT.BROADCAST.DESTROY;

export type AdminEvents =
  | typeof EVENT.ADMIN.BAN
  | typeof EVENT.ADMIN.KICK
  | typeof EVENT.ADMIN.LOCK
  | typeof EVENT.ADMIN.UNLOCK
  | typeof EVENT.ADMIN.MUTE
  | typeof EVENT.ADMIN.UNMUTE
  | typeof EVENT.ADMIN.CONTROL
  | typeof EVENT.ADMIN.RELEASE
  | typeof EVENT.ADMIN.GIVE;

export type PrivateEvents =
  | typeof EVENT.PRIVATE.LOCKED
  | typeof EVENT.PRIVATE.REQUEST
  | typeof EVENT.PRIVATE.RELEASE;

export type TabEvents =
  | typeof EVENT.TAB.NAVIGATE
  | typeof EVENT.TAB.INFO
  | typeof EVENT.TAB.BACK
  | typeof EVENT.TAB.FORWARD
  | typeof EVENT.TAB.RELOAD
  | typeof EVENT.TAB.FOCUS
  | typeof EVENT.TAB.CLOSE;

export type BrowserEvents =
  | typeof EVENT.BROWSER.RESET
  | typeof EVENT.BROWSER.FOCUS
  | typeof EVENT.BROWSER.UPDATE
  | typeof EVENT.BROWSER.NEW
  | typeof EVENT.BROWSER.CLOSED
  | typeof EVENT.BROWSER.FAVICON;

export type MouseEvents = typeof EVENT.MOUSE.CURSOR;

export type SupportEvents = typeof EVENT.SUPPORT.FAVICON;
