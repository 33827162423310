import { User } from '@liveblocks/client';
import {
  useBroadcastEvent,
  useEventListener,
  useRoom,
  useUpdateMyPresence,
} from '@liveblocks/react';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { EphemeralEvents } from '../components/Ephemeral/CursorEphemeral';
import { EVENT } from '../simba/events';
import { ISimbaStore, useSimbaStore } from '../simba/store';
import { RemoteActionType } from '../simba/store/remote';
import { BrowserInteraction, BrowserInteractions } from '../simba/types';
import GuacamoleKeyboard, {
  GuacamoleKeyboardInterface,
} from '../simba/utils/ts/guacamole-keyboard';
import { Position } from '../types/global';
import { useLocalCursor } from './cursor';
import { useDebug } from './debug';
import log from './log';
import { Presence } from './member';
import { useScreenShare } from './twilio';

const hasMacOSKbd = () => {
  return /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform);
};

export enum SharedEvents {
  MOUSE_CLICK = 'MOUSE_CLICK',
  BROWSER_NAME_CHANGE = 'BROWSER_NAME_CHANGE',
  // This event serves to correct paths if packets are dropped or joined to lower bandwidth
  REFERENCE_POSITIONS = 'REFERENCE_POSITIONS',
  // This is sent when a path ends, with all the points that were drawn
  LAST_REFERENCE_POSITIONS = 'LAST_REFERENCE_POSITIONS',
  // Clear all ephemeral paths
  CLEAR_EPHEMERAL_PATHS = 'CLEAR_EPHEMERAL_PATHS',
}

export type EphemeralLine = {
  id: number;
  color: string; // Color of line
  path?: string; // SVG Path
  ended: boolean; // Whether the line has ended
  lastChanged: number; // Last time the line was changed (e.g. new path, or end)
};

export type SharedEvent =
  | {
      type: SharedEvents.MOUSE_CLICK;
      x: number;
      y: number;
    }
  | {
      type: SharedEvents.BROWSER_NAME_CHANGE;
      name: string;
      userId?: string; // If no ID we don't emit notification
    };

export type SharedClickEvent = {
  x: number;
  y: number;
  color: string;
  when: number;
  connectionId: number;
};

// FROM VUE
const clipboard_read_available = () => {
  return 'clipboard' in navigator && typeof navigator.clipboard.readText === 'function';
};

const KeyTable = {
  XK_ISO_Level3_Shift: 0xfe03, // AltGr
  XK_Mode_switch: 0xff7e, // Character set switch
  XK_Control_L: 0xffe3, // Left control
  XK_Control_R: 0xffe4, // Right control
  XK_Meta_L: 0xffe7, // Left meta
  XK_Meta_R: 0xffe8, // Right meta
  XK_Alt_L: 0xffe9, // Left alt
  XK_Alt_R: 0xffea, // Right alt
  XK_Super_L: 0xffeb, // Left super
  XK_Super_R: 0xffec, // Right super
};

const keyMap = (key: number): number => {
  // Alt behaves more like AltGraph on macOS, so shuffle the
  // keys around a bit to make things more sane for the remote
  // server. This method is used by noVNC, RealVNC and TigerVNC
  // (and possibly others).
  if (hasMacOSKbd()) {
    switch (key) {
      case KeyTable.XK_Meta_L:
        key = KeyTable.XK_Control_L;
        break;
      case KeyTable.XK_Super_L:
        key = KeyTable.XK_Alt_L;
        break;
      case KeyTable.XK_Super_R:
        key = KeyTable.XK_Super_L;
        break;
      case KeyTable.XK_Alt_L:
        key = KeyTable.XK_Mode_switch;
        break;
      case KeyTable.XK_Alt_R:
        key = KeyTable.XK_ISO_Level3_Shift;
        break;
    }
  }

  return key;
};

const useGuacamoleKeyboard = (simbaStore: ISimbaStore) => {
  const simbaClient = simbaStore.state.client;
  const keyboard = useMemo(GuacamoleKeyboard, []);
  return useMemo(() => {
    if (
      simbaStore.state.remote.locked ||
      !simbaStore.getters.remote.hosting ||
      !simbaClient.connected
    )
      return keyboard;

    const sendData = (data: BrowserInteraction) => {
      simbaClient.sendData(data);
      return false;
    };

    keyboard.onkeydown = (key: number) =>
      sendData({ type: BrowserInteractions.KEY_DOWN, key: keyMap(key) });

    keyboard.onkeyup = (key: number) =>
      sendData({ type: BrowserInteractions.KEY_UP, key: keyMap(key) });

    return keyboard;
  }, [simbaClient, simbaStore, keyboard]);
};

const useConvertCoords = () => {
  const simbaStore = useSimbaStore();

  return useMemo(() => {
    const w = simbaStore.state.video.width;
    const h = simbaStore.state.video.height;

    const domToRemote = (x: number, y: number, rect: DOMRect) => ({
      x: Math.round((w / rect.width) * (x - rect.left)),
      y: Math.round((h / rect.height) * (y - rect.top)),
    });

    const remoteToDom = (x: number, y: number) => {
      return {
        x: Math.round((simbaStore.state.video.videoWidth / w) * x),
        y: Math.round((simbaStore.state.video.videoHeight / h) * y),
      };
    };

    return {
      domToRemote,
      remoteToDom,
    };
  }, [
    simbaStore.state.video.videoWidth,
    simbaStore.state.video.videoHeight,
    simbaStore.state.video.width,
    simbaStore.state.video.height,
  ]);
};

export const useHostSendToRemote = () => {
  const simbaStore = useSimbaStore();
  const simbaClient = simbaStore.state.client;

  return useCallback(
    (interaction: BrowserInteraction): boolean => {
      if (
        simbaClient.connected &&
        simbaStore.getters.remote.hosting &&
        !simbaStore.state.remote.locked
      ) {
        simbaClient.sendData(interaction);
        return true;
      } else {
        return false;
      }
    },
    [simbaClient, simbaStore]
  );
};

export const useSendToRemote = () => {
  const simbaStore = useSimbaStore();
  const simbaClient = simbaStore.state.client;

  return useCallback(
    (interaction: BrowserInteraction) => simbaClient.connected && simbaClient.sendData(interaction),
    [simbaClient]
  );
};

const useUpdateMousePosition = (disableBrowserControl: boolean = false) => {
  const { setPosition, setHidden } = useLocalCursor();

  const screenShare = useScreenShare();
  const screenShareActive = screenShare.active !== null;

  const hostSendToRemote = useHostSendToRemote();
  const { domToRemote } = useConvertCoords();

  return (e: React.MouseEvent | React.TouchEvent): boolean => {
    const eventX =
      e.nativeEvent instanceof MouseEvent
        ? e.nativeEvent.clientX
        : e.nativeEvent instanceof TouchEvent
        ? e.nativeEvent.touches[0].clientX
        : 0;
    const eventY =
      e.nativeEvent instanceof MouseEvent
        ? e.nativeEvent.clientY
        : e.nativeEvent instanceof TouchEvent
        ? e.nativeEvent.touches[0].clientY
        : 0;

    const target = e.currentTarget as HTMLElement;
    const { x, y } = domToRemote(eventX, eventY, target.getBoundingClientRect());

    setHidden(false);
    setPosition({
      x,
      y,
    });

    if (screenShareActive || disableBrowserControl) return false;

    // Send mouse move to remote, if we are in control
    return hostSendToRemote({
      type: BrowserInteractions.MOUSE_MOVE,
      x,
      y,
    });
  };
};

export const useSharedEvents = () => {
  const { remoteToDom } = useConvertCoords();
  const broadcast = useBroadcastEvent();
  const { setPosition } = useLocalCursor();

  const room = useRoom();

  const [clickEvents, setClickEvents] = useState<SharedClickEvent[]>([]);

  const broadcastClick = (x: number, y: number) => {
    broadcast({
      type: SharedEvents.MOUSE_CLICK,
      x,
      y,
    });

    setPosition({
      x: x + 0.1, // Offset to avoid the cursor fading out
      y: y + 0.1,
    });
  };

  const addSharedClickEvent = (user: User<Presence>, x: number, y: number) => {
    const position = remoteToDom(x, y);

    setClickEvents((clickEvents) => [
      ...clickEvents.filter((clickEvent) => clickEvent.when > Date.now() - 1000), // Cleanup old click events.
      {
        x: position.x,
        y: position.y,
        color: user.presence?.color || '#000000',
        when: Date.now(),
        connectionId: user.connectionId,
      },
    ]);
  };

  useEventListener(({ event, connectionId }: { event: SharedEvent; connectionId: number }) => {
    if (event.type !== SharedEvents.MOUSE_CLICK) return;

    const others = room
      .getOthers<Presence>()
      .toArray()
      .filter((user) => !!user.presence?.cursor);

    const user = others.find((user) => user.connectionId === connectionId);
    if (!user) return;

    addSharedClickEvent(user, event.x, event.y);
  });

  return {
    broadcastClick,
    addSharedClickEvent,
    clickEvents,
  };
};

const useSyncClipboard = () => {
  const { getters, state, dispatch } = useSimbaStore();
  const clipboard = state.remote.clipboard;
  const hosting = getters.remote.hosting;
  const client = state.client;

  return useCallback(async () => {
    if (!hosting) return;

    if (clipboard_read_available() && document.hasFocus()) {
      try {
        const text = await navigator.clipboard.readText();

        if (clipboard !== text) {
          dispatch({
            type: RemoteActionType.SET_CLIPBOARD,
            clipboard: text,
          });
          client.sendMessage(EVENT.CONTROL.CLIPBOARD, { text });
        }
      } catch (err: any) {
        if (err.name !== 'NotAllowedError') {
          log('error', 'Unknown error copying clipboard: ', err);
        }

        dispatch({
          type: RemoteActionType.SET_CLIPBOARD,
          clipboard: '',
        });
      }
    }
  }, [hosting, dispatch, client, clipboard]);
};

const useMouseInteraction = (
  keyboard: GuacamoleKeyboardInterface,
  disableBrowserControl: boolean = false
) => {
  const {
    clearEphemeral,
    setMode,
    setHidden,
    setDrawing,
    setLastWiggle,
    setPosition,
    startShimmer,
    mode,
    drawing,
  } = useLocalCursor();
  const simbaStore = useSimbaStore();
  const hostSendToRemote = useHostSendToRemote();
  const anyoneSendToRemote = useSendToRemote();
  const updateMousePosition = useUpdateMousePosition(disableBrowserControl);
  const updateMyPresence = useUpdateMyPresence<Presence>();
  const { domToRemote } = useConvertCoords();
  const { clickEvents, broadcastClick, addSharedClickEvent } = useSharedEvents();
  const room = useRoom();
  const debug = useDebug();
  const syncClipboard = useSyncClipboard();
  const broadcast = useBroadcastEvent();

  const screenShare = useScreenShare();
  const screenShareActive = screenShare.active !== null;

  const [showingContextMenu, setShowingContextMenu] = useState(false);
  const [contextMenuPosition, setContextMenuPosition] = useState<Position>();
  const { getters } = useSimbaStore();
  const isSelfPrivate = getters.remote.isSelfPrivate;

  const interactionNoticeDeadline = useRef(0);

  // Mockup for settings
  const WHEEL_LINE_HEIGHT = debug.variables.DESKTOP_WHEEL_LINE_HEIGHT;
  const SCROLL_INVERT = debug.variables.SCROLL_INVERT;

  // State used for detecting mouse wiggling
  // Up to 20 lastPosition values are stored, this are throttled by the wiggleThrottle
  const lastWiggleMousePosition = useRef<Position[]>([]);
  // Throttle lastPosition and lastAngle updates subjective to changes
  const wiggleThrottle = useRef<number>(0);

  const updateKeyboardModifier = (e: React.MouseEvent) =>
    simbaStore.dispatch({
      type: RemoteActionType.SET_KEYBOARD_MODIFIER,
      payload: {
        capsLock: e.getModifierState('CapsLock'),
        numLock: e.getModifierState('NumLock'),
        scrollLock: e.getModifierState('ScrollLock'),
      },
    });

  const chatOpen = simbaStore.state.chat.open;

  useEffect(() => {
    if (!chatOpen) return;

    setHidden(false);
    setMode('normal');
  }, [chatOpen, setMode, updateMyPresence, setHidden]);

  return {
    contextMenu: {
      showing: showingContextMenu,
      position: contextMenuPosition,
      setShowing: setShowingContextMenu,
    },
    clickEvents,
    onMouseEnter: (e: React.MouseEvent) => {
      updateKeyboardModifier(e);

      syncClipboard();

      // If the chat is open we won't focus the window.
      if (!chatOpen && !simbaStore.state.status.changingName) (e.target as HTMLElement).focus();
    },
    onMouseLeave: (e: React.MouseEvent) => {
      updateKeyboardModifier(e);

      keyboard.reset();

      // If we left the overlay we should hide our cursor
      if (chatOpen) return;
      setHidden(true);
    },
    onMouseMove: (e: React.MouseEvent) => {
      let activitySent = updateMousePosition(e); // updates and returns true only if we are in control
      const now = Date.now();
      if (!activitySent && now > interactionNoticeDeadline.current && document.hasFocus()) {
        // notify of activity if we are not in control (otherwise the activity is already recorded)
        activitySent = true;
        anyoneSendToRemote({
          type: BrowserInteractions.ACTIVITY,
          key: 0,
        });
      }
      if (activitySent) {
        interactionNoticeDeadline.current = now + 1000 * 5;
      }

      if (drawing && (e.button !== 0 || e.buttons === 0)) setDrawing(false);

      // Wiggle mechanic
      const x = e.clientX;
      const y = e.clientY;

      const timeDifference = now - wiggleThrottle.current;
      // Only update values every 50ms using wiggleThrottle
      if (timeDifference < 20) return;

      wiggleThrottle.current = now;

      // Get the time difference if it's bigger than 70ms we clear the lastWiggleMousePosition
      if (timeDifference > 70) {
        return (lastWiggleMousePosition.current = []);
      }

      // We will analyze every 20 lastPosition values and then clear the array
      if (lastWiggleMousePosition.current.length < 20) {
        return (lastWiggleMousePosition.current = [...lastWiggleMousePosition.current, { x, y }]);
      }

      // Let's count our turns and reset the array.
      let turns = 0;
      let lastDirectionX = 0;
      for (let i = 1; i < lastWiggleMousePosition.current.length - 1; i++) {
        const a = lastWiggleMousePosition.current[i - 1];
        const b = lastWiggleMousePosition.current[i];

        // Angle in radians
        const angle = Math.atan2(b.y - a.y, b.x - a.x);

        // We only want the angles to be horizontal or slightly vertical (diagonal wiggle)
        // If the sin is outside of a certain threshold we will return 0 as turns
        const sin = Math.sin(angle);
        if (sin > 0.99 || sin < -0.99) {
          turns = 0;
          break;
        }

        // For detecting the direction in X we are going to get the cosine of the angle and map it to a -1 or 1
        const directionX = Math.cos(angle) > 0 ? 1 : -1;
        if (lastDirectionX !== directionX && lastDirectionX !== 0) turns++;
        lastDirectionX = directionX;
      }

      lastWiggleMousePosition.current = [];
      if (turns > 3) {
        setLastWiggle(now);
      }
    },
    onMouseDown: (e: React.MouseEvent<HTMLElement>) => {
      e.preventDefault();
      updateMousePosition(e);

      // Focus the target
      e.currentTarget.focus();

      const { x, y } = domToRemote(e.clientX, e.clientY, e.currentTarget.getBoundingClientRect());

      if (!isSelfPrivate) {
        broadcastClick(x, y);
      }

      setShowingContextMenu(false);

      const self = room.getSelf<Presence>();
      self && addSharedClickEvent(self, x, y);

      if (e.button === 0 && mode === 'ephemeral') {
        setDrawing(true);
        return;
      }
      if (drawing) return;

      setHidden(false);
      setPosition({
        x: x + 0.1, // Add a small offset to prevent the cursor from being hidden
        y: y + 0.1,
      });

      if (!simbaStore.getters.remote.hosting) {
        if (e.button === 2) {
          setShowingContextMenu(true);
          // Set context menu position to actual mouse position on the screen
          setContextMenuPosition({
            x: e.pageX - e.currentTarget.getBoundingClientRect().left,
            y: e.pageY - e.currentTarget.getBoundingClientRect().top, // the max height for the topbar is 50px
          });
        }
        return;
      }

      if (screenShareActive || disableBrowserControl) return;

      hostSendToRemote({
        type: BrowserInteractions.MOUSE_DOWN,
        key: e.button + 1,
      });
    },
    onMouseUp: (e: React.MouseEvent) => {
      e.preventDefault();
      updateMousePosition(e);

      // Always disable drawing on mouse up, even if not on ephemeral pen
      if (e.button === 0 && drawing) setDrawing(false);
      if (drawing) return;

      // Focus the target
      (e.target as HTMLElement).focus();

      if (!simbaStore.getters.remote.hosting) {
        if (e.button === 2) {
          setShowingContextMenu(true);
        }

        return;
      }

      if (screenShareActive || disableBrowserControl) return;

      hostSendToRemote({
        type: BrowserInteractions.MOUSE_UP,
        key: e.button + 1,
      });
    },
    onWheel: (e: React.WheelEvent) => {
      if (!chatOpen && !simbaStore.state.status.changingName) (e.target as HTMLElement).focus();
      if (hasMacOSKbd() ? e.metaKey : e.ctrlKey) return;
      updateMousePosition(e);

      // not in control return
      if (!getters.remote.hosting) {
        startShimmer();
        return;
      }

      let x = e.deltaX;
      let y = e.deltaY;

      // Pixel units unless it's non-zero.
      // Note that if deltamode is line or page won't matter since we aren't
      // sending the mouse wheel delta to the server anyway.
      // The difference between pixel and line can be important however since
      // we have a threshold that can be smaller than the line height.
      if (e.deltaMode !== 0) {
        x *= WHEEL_LINE_HEIGHT;
        y *= WHEEL_LINE_HEIGHT;
      }

      if (SCROLL_INVERT) {
        x = x * -1;
        y = y * -1;
      }

      // x = Math.min(Math.max(x, -DESKTOP_SCROLL_SPEED), DESKTOP_SCROLL_SPEED);
      // y = Math.min(Math.max(y, -DESKTOP_SCROLL_SPEED), DESKTOP_SCROLL_SPEED);

      if (screenShareActive) return;

      hostSendToRemote({
        type: BrowserInteractions.WHEEL,
        x,
        y,
      });

      broadcast({ type: EphemeralEvents.CLEAR_LINES });
      clearEphemeral();
    },
  } as const;
};

const useTouchInteraction = (disableBrowserControl: boolean = false) => {
  const sendToRemote = useHostSendToRemote();
  const updateMousePosition = useUpdateMousePosition(disableBrowserControl);

  const debug = useDebug();
  const MOBILE_SCROLL_SPEED = debug.variables.MOBILE_SCROLL_SPEED;
  const MOBILE_SCROLL_THROTTLE = debug.variables.MOBILE_SCROLL_THROTTLE;
  const SCROLL_INVERT = debug.variables.SCROLL_INVERT;
  const [scrollThrottle, setScrollThrottle] = useState<boolean>(false);

  const [touching, setTouching] = useState<boolean>(false);
  const [lastTouchX, setLastTouchX] = useState<number>(0);
  const [lastTouchY, setLastTouchY] = useState<number>(0);

  const { drawing, mode, setDrawing } = useLocalCursor();

  return {
    onTouchStart: (e: React.TouchEvent) => {
      // If more than 1 finger is touching we don't want to do anything
      if (e.touches.length > 1) {
        return;
      }

      if (mode === 'ephemeral') {
        updateMousePosition(e);
        setDrawing(true);
        return;
      }

      setTouching(true);

      updateMousePosition(e);

      setLastTouchX(e.touches[0].clientX);
      setLastTouchY(e.touches[0].clientY);
    },
    onTouchEnd: () => {
      if (drawing) setDrawing(false);

      setTouching(false);
    },
    onTouchMove: (e: React.TouchEvent) => {
      if (mode === 'ephemeral' && drawing) {
        updateMousePosition(e);
        return;
      }

      if (!touching || scrollThrottle) return;

      let x = e.touches[0].clientX - lastTouchX;
      let y = e.touches[0].clientY - lastTouchY;

      x = Math.min(Math.max(x, -MOBILE_SCROLL_SPEED), MOBILE_SCROLL_SPEED);
      y = Math.min(Math.max(y, -MOBILE_SCROLL_SPEED), MOBILE_SCROLL_SPEED);

      if (!SCROLL_INVERT) {
        x = x * -1;
        y = y * -1;
      }

      // Update last touch position
      setLastTouchX(e.touches[0].clientX);
      setLastTouchY(e.touches[0].clientY);

      // Send scroll to remote
      sendToRemote({
        type: BrowserInteractions.WHEEL,
        x,
        y,
      });

      setScrollThrottle(true);
      setTimeout(() => setScrollThrottle(false), MOBILE_SCROLL_THROTTLE);
    },
  };
};

export {
  useGuacamoleKeyboard,
  useSyncClipboard,
  useMouseInteraction,
  useTouchInteraction,
  useConvertCoords,
  hasMacOSKbd,
};
