import React from 'react';

import { CursorProps } from '../../hooks/cursor';
import { getTextColor } from '../../utils/color';

const CursorNormal: React.FC<CursorProps> = ({ color, ...props }) => {
  const outlineColor = getTextColor(color);

  return (
    <svg
      width="52"
      height="53"
      viewBox="0 0 52 53"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g>
        <path d="M13.935 8.623 20.969 47.2l8.904-14.669 16.965-2.577L13.935 8.623Z" fill={color} />
        <path
          d="M13.935 8.623 20.969 47.2l8.904-14.669 16.965-2.577L13.935 8.623Z"
          stroke={outlineColor}
          strokeWidth="3"
        />
      </g>
    </svg>
  );
};

export default CursorNormal;
