import { ScreenResolution } from '../types';

export enum VideoActionType {
  PLAY = 'VIDEO:PLAY',
  PAUSE = 'VIDEO:PAUSE',
  TOGGLE_PLAY = 'VIDEO:TOGGLE_PLAY',
  SET_MUTED = 'VIDEO:SET_MUTED',
  TOGGLE_MUTE = 'VIDEO:TOGGLE_MUTE',
  SET_PLAYABLE = 'VIDEO:SET_PLAYABLE',
  SET_RESOLUTION = 'VIDEO:SET_RESOLUTION',
  SET_VOLUME = 'VIDEO:SET_VOLUME',
  SET_STREAM = 'VIDEO:SET_STREAM',
  ADD_TRACK = 'VIDEO:ADD_TRACK',
  DEL_TRACK = 'VIDEO:DEL_TRACK',
  SET_VIDEO_SIZE = 'VIDEO:SET_VIDEO_SIZE',
  SET_EDGE_COLOR = 'VIDEO:SET_EDGE_COLOR',
  SET_VIEW_SIZE = 'VIDEO:SET_VIEW_SIZE',
  RESET = 'VIDEO:RESET',
}

const initialState: VideoState = {
  index: -1,
  tracks: [] as MediaStreamTrack[],
  streams: [] as MediaStream[],
  configurations: [] as ScreenResolution[],
  width: 1280,
  height: 720,
  rate: 30,
  horizontal: 16,
  vertical: 9,
  volume: 100,
  muted: false,
  playing: false,
  playable: false,
  videoWidth: 0,
  videoHeight: 0,
  edgeColor: '#000000',
  viewWidth: 0,
  viewHeight: 0,
};

export type VideoState = {
  index: number;
  tracks: MediaStreamTrack[];
  streams: MediaStream[];
  configurations: ScreenResolution[];
  width: number;
  height: number;
  rate: number;
  horizontal: number;
  vertical: number;
  volume: number;
  muted: boolean;
  playing: boolean;
  playable: boolean;
  // Video element width and height
  videoWidth: number;
  videoHeight: number;
  // Video edge color (calculated)
  edgeColor: string;

  // View size
  viewWidth: number;
  viewHeight: number;
};

export type VideoAction =
  | { type: VideoActionType.PLAY }
  | { type: VideoActionType.PAUSE }
  | { type: VideoActionType.TOGGLE_PLAY }
  | { type: VideoActionType.SET_MUTED; payload: boolean }
  | { type: VideoActionType.TOGGLE_MUTE }
  | { type: VideoActionType.SET_PLAYABLE; payload: boolean }
  | {
      type: VideoActionType.SET_RESOLUTION;
      payload: {
        width: number;
        height: number;
        rate: number;
      };
    }
  | { type: VideoActionType.SET_VOLUME; payload: number }
  | { type: VideoActionType.SET_STREAM; payload: number }
  | { type: VideoActionType.ADD_TRACK; payload: [MediaStreamTrack, MediaStream] }
  | { type: VideoActionType.DEL_TRACK; payload: number }
  | { type: VideoActionType.SET_VIDEO_SIZE; payload: { width: number; height: number } }
  | { type: VideoActionType.SET_EDGE_COLOR; color: string }
  | { type: VideoActionType.SET_VIEW_SIZE; payload: { width: number; height: number } }
  | { type: VideoActionType.RESET };

const reducer = (state: VideoState, action: VideoAction): VideoState => {
  switch (action.type) {
    case VideoActionType.PLAY:
      return {
        ...state,
        playing: true,
      };
    case VideoActionType.PAUSE:
      return {
        ...state,
        playing: false,
      };
    case VideoActionType.TOGGLE_PLAY:
      return {
        ...state,
        playing: !state.playing,
      };
    case VideoActionType.SET_MUTED:
      return {
        ...state,
        muted: action.payload,
      };
    case VideoActionType.TOGGLE_MUTE:
      return {
        ...state,
        muted: !state.muted,
      };
    case VideoActionType.SET_PLAYABLE:
      return {
        ...state,
        playable: action.payload,
      };
    case VideoActionType.SET_RESOLUTION:
      return {
        ...state,
        width: action.payload.width,
        height: action.payload.height,
        rate: action.payload.rate,
      };
    case VideoActionType.SET_VOLUME:
      return {
        ...state,
        volume: action.payload,
      };
    case VideoActionType.SET_STREAM:
      return {
        ...state,
        index: action.payload,
      };
    case VideoActionType.ADD_TRACK:
      return {
        ...state,
        tracks: [...state.tracks, action.payload[0]],
        streams: [...state.streams, action.payload[1]],
      };
    case VideoActionType.DEL_TRACK:
      return {
        ...state,
        tracks: state.tracks.filter((_, i) => i !== action.payload),
        streams: state.streams.filter((_, i) => i !== action.payload),
      };
    case VideoActionType.SET_VIDEO_SIZE:
      return {
        ...state,
        videoWidth: action.payload.width,
        videoHeight: action.payload.height,
      };
    case VideoActionType.SET_EDGE_COLOR:
      if (action.color === state.edgeColor) return state;

      return {
        ...state,
        edgeColor: action.color,
      };
    case VideoActionType.SET_VIEW_SIZE:
      return {
        ...state,
        viewWidth: action.payload.width,
        viewHeight: action.payload.height,
      };
    case VideoActionType.RESET:
      return initialState;
    default:
      return state;
  }
};

const getters = (state: VideoState) => {
  return {
    get stream() {
      return state.streams[state.index];
    },
    get track() {
      return state.tracks[state.index];
    },
    get resolution() {
      return {
        w: state.width,
        h: state.height,
      };
    },
  } as const;
};

export default { reducer, getters, initialState } as const;
