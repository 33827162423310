import awsExports from '../aws-exports';

const isDesktopAuth = window.location.href.includes('desktop-auth');

awsExports.oauth.redirectSignIn = `${window.location.origin}/${
  isDesktopAuth ? 'desktop-auth' : ''
}`;
awsExports.oauth.redirectSignOut = `${window.location.origin}/`; // back to login

export const getAmplifyConfig = () => Promise.resolve(awsExports);
