import clsx from 'clsx';
import React from 'react';

import FileIcon from '../FileIcon';
import styles from './FileDisplay.module.scss';

const FileDisplay: React.FC<{
  fileExtension: string | null;
  fileName: string;
  hideFileName?: boolean;
  onClick?: () => void;

  scale?: number;
}> = ({ fileExtension, fileName, hideFileName, onClick, scale = 1 }) => {
  // make sure name contains the extension
  const nameWithExtension = fileName.includes(fileExtension ?? '')
    ? fileName
    : `${fileName}${fileExtension}`;
  return (
    <div
      className={clsx(styles.file, scale < 1 && styles.scaled_down, onClick && styles.clickable)}
      onClick={onClick}
    >
      <div className={styles.file_icon}>
        <FileIcon
          style={{
            width: 202 * scale,
            height: 213 * scale,
          }}
          extension={fileExtension?.replace('.', '') ?? '?'}
        />
      </div>
      {!hideFileName && (
        <div
          className={styles.filename}
          style={{
            padding: scale < 1 ? '6px 8px' : 10,
          }}
        >
          <span>{nameWithExtension}</span>
        </div>
      )}
    </div>
  );
};

export default FileDisplay;
