import clsx from 'clsx';
import dayjs from 'dayjs';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';

import { useAuth } from '../../hooks/auth';
import { HalNotification, HalUserForNotification } from '../../services/woody/types/notifications';
import { useWoody } from '../../services/woody/WoodyProvider';
import { useGetFdocById } from '../../store/fdoc';
import { getUserColorFromId } from '../../utils/color';
import FdocItem from '../FdocItem/FdocItem';
import UserBubble from '../UserBubble/UserBubble';
import styles from './Notification.module.scss';

export const Notification: React.FC<{ notification: HalNotification }> = ({ notification }) => {
  const navigate = useNavigate();
  const { user: loggedInUser } = useAuth();
  const { client } = useWoody();
  const { _embedded, createdAt, type, readAt } = notification;
  const { comment, user, list } = _embedded as any;
  const fdocId = (_embedded as any)?.fdocs?.[0]?.id || (_embedded as any)?.fdoc?.id;
  const { fdoc } = useGetFdocById(fdocId);
  const sub = loggedInUser?.attributes?.sub;

  useEffect(() => {
    if (!notification.readAt) if (sub) client.markNotificationAsRead(sub, notification.id);
  }, [notification, sub, client]);

  return (
    <div className={styles.main_row}>
      <div className={styles.dot} style={{ opacity: readAt ? 0 : 1 }} />
      <div className={styles.notification}>
        <div className={styles.header}>
          <div className={styles.header_left}>
            <UserBubble
              color={getUserColorFromId(user?.name)}
              name={user?.name}
              picture={user?.picture}
            />
            <div>
              <span className={styles.bold}>{user?.name || 'User'} </span>
              <span>added a </span>
              {type === 'NEW_COMMENT_IN_LIST' && <span>comment to a </span>}
              <span
                style={{ cursor: 'pointer' }}
                onClick={() => navigate(`/?expandedFdocId=${fdocId}`)}
                className={clsx(styles.bold, styles.underlined)}
              >
                note
              </span>
              <span> to the </span>
              <span
                style={{ cursor: 'pointer' }}
                onClick={() => navigate(`/collections/${list?.id}`)}
                className={clsx(styles.bold, styles.underlined)}
              >
                {list?.title}
              </span>
              <span> collection</span>
            </div>
          </div>
          <div className={styles.header_right}>
            <div className={styles.date}>
              {dayjs(createdAt).format('DD/MM/YYYY')} • {dayjs(createdAt).format('h A')}
            </div>
          </div>
        </div>
        {fdoc && <FdocItem fdoc={fdoc} showDate={false} viewMode="List" />}
        {user && <Comment text={comment?.content} user={user} />}
      </div>
    </div>
  );
};

const Comment: React.FC<{ text: string; user: HalUserForNotification }> = ({ text, user }) => {
  if (!text) return null;
  return (
    <div className={styles.comment}>
      <UserBubble color={getUserColorFromId(user?.name)} name={user.name} picture={user?.picture} />
      <div>
        <span>{text}</span>
      </div>
    </div>
  );
};
