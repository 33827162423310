import produce from 'immer';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

export interface WebnoteStore {
  // The images for image notes, ID => image_url
  images: Record<string, string>;
  setImages: (images: Record<string, string>) => void;
  setImage: (id: string, image: string) => void;

  // descriptions for page notes, ID => description
  descriptions: Record<string, string>;
  setDescriptions: (descriptions: Record<string, string>) => void;
  setDescription: (id: string, description: string) => void;

  reset: () => void;
}

const useWebnoteStore = create<WebnoteStore>()(
  persist(
    (set, get) => ({
      images: {},
      setImages: (images: Record<string, string>) => {
        set(
          produce(get(), (draft) => {
            draft.images = images;
          })
        );
      },
      setImage: (id: string, image: string) => {
        set(
          produce(get(), (draft) => {
            draft.images[id] = image;
          })
        );
      },
      descriptions: {},
      setDescriptions: (descriptions: Record<string, string>) => {
        set(
          produce(get(), (draft) => {
            draft.descriptions = descriptions;
          })
        );
      },
      setDescription: (id: string, description: string) => {
        set(
          produce(get(), (draft) => {
            draft.descriptions[id] = description;
          })
        );
      },
      reset: () => {
        set({
          images: {},
          descriptions: {},
        });
      },
    }),
    {
      name: 'WebnoteMetadataStore',
      partialize: (state) => {
        // store a max of 100 images and descriptions
        return {
          images: Object.fromEntries(Object.entries(state.images).slice(-100)),
          descriptions: Object.fromEntries(Object.entries(state.descriptions).slice(-100)),
        };
      },
    }
  )
);

export default useWebnoteStore;
