import { HalComment, HalUser } from '@fabric/woody-client';
import produce from 'immer';
import { v4 as uuidv4 } from 'uuid';
import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

import { OptimisticDraft } from '../types/draftable';

export interface CommentStore {
  // note ID => commetn
  comments: Record<string, OptimisticDraft<HalComment>[]>;
  setComments: (comments: OptimisticDraft<HalComment>[], noteId: string) => void;
  addComment: (comment: OptimisticDraft<HalComment>, noteId: string) => void;
  removeComment: (commentId: string, noteId: string) => void;
  updateComment: (comment: OptimisticDraft<HalComment>, noteId: string) => void;

  reset: () => void;
}

const useCommentsStore = create<CommentStore>()(
  devtools(
    persist(
      (set, get) => ({
        comments: {},
        setComments: (comments: OptimisticDraft<HalComment>[], noteId: string) => {
          set(
            produce((draft) => {
              draft.comments[noteId] = comments;
            })
          );
        },
        addComment: (comment: OptimisticDraft<HalComment>, noteId: string) => {
          set(
            produce(get(), (draft) => {
              if (!draft.comments[noteId]) {
                draft.comments[noteId] = [];
              }
              if (draft.comments[noteId].find((c) => c.id === comment.id)) {
                return;
              }

              draft.comments[noteId].push(comment);
            })
          );
        },
        removeComment: (commentId: string, noteId: string) => {
          set(
            produce(get(), (draft) => {
              if (draft.comments[noteId]) {
                draft.comments[noteId] = draft.comments[noteId].filter(
                  (comment) => comment.id !== commentId
                );
              }
            })
          );
        },
        updateComment: (comment: OptimisticDraft<HalComment>, noteId: string) => {
          set(
            produce(get(), (draft) => {
              if (draft.comments[noteId]) {
                draft.comments[noteId] = draft.comments[noteId].map((c) =>
                  c.id === comment.id ? comment : c
                );
              }
            })
          );
        },
        reset: () => set({ comments: {} }),
      }),
      {
        name: 'CommentStore',
        partialize: (state) => {
          // we will only keep 15 comments per note and a max of 100 notes
          return {
            comments: Object.fromEntries(
              Object.entries(state.comments)
                .slice(-100)
                .map(([noteId, comments]) => [noteId, comments.slice(-15)])
            ),
          };
        },
      }
    )
  )
);

export const createOptimisticComment = (
  user: HalUser,
  noteId: string,
  content: string
): OptimisticDraft<HalComment> => {
  const optimisticId = uuidv4();

  return {
    id: optimisticId,
    isDraft: true,
    content,
    draftId: optimisticId,
    createdAt: new Date().toISOString(),
    _links: {
      user: {
        href: `/users/${user.id}`,
      },
      note: {
        href: `/notes/${noteId}`,
      },
    },
    _embedded: {
      user,
    },
  };
};

export default useCommentsStore;
