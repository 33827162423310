import WoodyClient, { WoodyLogLevel } from '@fabric/woody-client';
import React, { ReactNode, useContext, useEffect, useMemo } from 'react';

import { useAuth } from '../../hooks/auth';
import useCommentsStore from '../../store/comment';
import useFdocStore from '../../store/fdoc';
import useListsStore from '../../store/list';
import useUIStore from '../../store/ui';
import WoodyAuthenticator from '../WoodyAuthenticator';

const WOODY_API_URL = process.env.REACT_APP_WOODY_API_URL;
if (!WOODY_API_URL) {
  throw new Error('REACT_APP_WOODY_API_URL ENV variable is undefined');
}

interface IWoodyContext {
  client: WoodyClient;
}

const WoodyContext = React.createContext<IWoodyContext>({} as IWoodyContext);

const woodyLogLevel: WoodyLogLevel =
  process.env.REACT_APP_ENV === 'production' ? WoodyLogLevel.ERROR : WoodyLogLevel.LOG;

export const WoodyProvider = ({ children }: { children: ReactNode }) => {
  const { user: authUser, authProcessing } = useAuth();

  const client = useMemo(
    () => new WoodyClient(WOODY_API_URL, new WoodyAuthenticator(), woodyLogLevel),
    []
  );

  const user = useUIStore((state) => state.user);
  const userLastFetch = useUIStore((state) => state.userLastFetch);
  const setUser = useUIStore((state) => state.setUser);
  const setUserLastFetch = useUIStore((state) => state.setUserLastFetch);

  const resetUI = useUIStore((state) => state.reset);
  const resetFdocs = useFdocStore((state) => state.reset);
  const resetLists = useListsStore((state) => state.reset);
  const resetComments = useCommentsStore((state) => state.reset);
  useEffect(() => {
    // if user not set, or last fetch was more than 5 minutes ago
    if (
      (user &&
        user.id === authUser?.attributes?.sub &&
        userLastFetch > Date.now() - 1000 * 60 * 5) ||
      authProcessing
    ) {
      return;
    }

    if ((!authUser && user) || !authUser?.attributes?.sub) {
      setUser(null);
      return;
    }

    // if the user is not the same as in the store, reset all the stores
    if (user && user.id !== authUser?.attributes?.sub) {
      resetUI();
      resetFdocs();
      resetLists();
      resetComments();
    }

    const fetchUser = async () => {
      if (!authUser?.attributes?.sub) return;

      const response = await client.fetchUserById(authUser?.attributes?.sub);

      setUserLastFetch(Date.now());
      if (response.error) return;

      setUser(response.data);
    };

    fetchUser();
  }, [
    authUser,
    authProcessing,
    client,
    user,
    userLastFetch,
    setUser,
    setUserLastFetch,
    resetUI,
    resetFdocs,
    resetLists,
    resetComments,
  ]);

  return <WoodyContext.Provider value={{ client }}>{children}</WoodyContext.Provider>;
};

export const useWoody = () => useContext(WoodyContext);
