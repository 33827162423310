import { TabData } from '../../types/controls';
import { BrowserTabChangeInfo } from '../messages';

export enum ControlActionType {
  RESET_BROWSER = 'CONTROL:RESET_BROWSER',
  SET_SIDEBAR_OPEN = 'CONTROL:SET_SIDEBAR_OPEN',
  UPDATE_TAB = 'CONTROL:UPDATE_TAB',
  CLOSED_TAB = 'CONTROL:CLOSED_TAB',
  SET_WINDOW_ID = 'CONTROL:SET_WINDOW_ID',
  SET_FAVICON_URL = 'CONTROL:SET_FAVICON_URL',
}

export type ControlState = {
  sidebarOpen: boolean;
  tabs: Map<number, TabData>;
  favicons: Map<string, string>; // favicon url -> favicon data base64
  windowId: number | null; // focused window
};

const initialState: ControlState = {
  sidebarOpen: false,
  tabs: new Map(),
  favicons: new Map(),
  windowId: null,
};

export type ControlAction =
  | {
      type: ControlActionType.SET_SIDEBAR_OPEN;
      value: boolean;
    }
  | {
      type: ControlActionType.UPDATE_TAB;
      windowId: number;
      changeInfo: BrowserTabChangeInfo;
    }
  | {
      type: ControlActionType.SET_WINDOW_ID;
      windowId: number | null;
    }
  | {
      type: ControlActionType.CLOSED_TAB;
      windowId: number;
    }
  | {
      type: ControlActionType.SET_FAVICON_URL;
      url: string;
      data: string;
    }
  | {
      type: ControlActionType.RESET_BROWSER;
    };

const reducer = (state: ControlState, action: ControlAction): ControlState => {
  switch (action.type) {
    case ControlActionType.RESET_BROWSER:
      return {
        ...state,
        tabs: new Map(),
        windowId: null,
      };
    case ControlActionType.SET_SIDEBAR_OPEN:
      return {
        ...state,
        sidebarOpen: action.value,
      };
    case ControlActionType.UPDATE_TAB: {
      const tabs = new Map(state.tabs);
      const tab = tabs.get(action.windowId);

      const newTab: TabData = {
        ...(tab ?? {
          windowId: action.windowId,
          title: 'New Tab',
          groupId: -1,
          status: 'unloaded',
          mutedInfo: {
            muted: false,
          },
          url: '',
        }),
        ...action.changeInfo,
      };

      return {
        ...state,
        tabs: tabs.set(action.windowId, newTab),
        windowId: state.windowId !== null && state.windowId >= 0 ? state.windowId : action.windowId,
      };
    }
    case ControlActionType.SET_WINDOW_ID:
      return {
        ...state,
        windowId: action.windowId,
      };
    case ControlActionType.CLOSED_TAB: {
      const tabs = new Map(state.tabs);
      tabs.delete(action.windowId);
      return {
        ...state,
        tabs,
      };
    }
    case ControlActionType.SET_FAVICON_URL:
      return {
        ...state,
        favicons: state.favicons.set(action.url, action.data),
      };
    default:
      return state;
  }
};

export default { reducer, initialState } as const;
