import { Placement } from '@floating-ui/react-dom-interactions';
import clsx from 'clsx';
import React, { useState } from 'react';

import Tooltip from '../../ui/Tooltip';
import { getTextColor } from '../../utils/color';
import styles from './UserBubble.module.scss';

const UserBubble: React.FC<{
  name: string;
  picture?: string | null;
  color: string;

  size?: number;
  className?: string;
  tooltipPlacement?: Placement;
  tooltip?: boolean;
}> = ({
  name,
  picture,
  color,
  size = 17,
  className,
  tooltipPlacement = 'bottom',
  tooltip = true,
}) => {
  const [pictureLoadFailed, setPictureFailed] = useState<boolean>(false);

  return (
    <Tooltip label={tooltip ? name : undefined} delay={0} placement={tooltipPlacement}>
      <div
        className={clsx(styles.member_bubble, className)}
        style={{
          background: color,
          color: getTextColor(color),
          width: size,
          height: size,
          overflow: 'hidden',
          fontSize: size * 0.54 + 'px',
        }}
      >
        <div
          style={{
            maxWidth: '100%',
          }}
        >
          {picture && !pictureLoadFailed ? (
            <img
              alt={name}
              src={picture}
              className={styles.avatar}
              onError={() => setPictureFailed(true)}
            />
          ) : (
            <span>{name.charAt(0)}</span>
          )}
        </div>
      </div>
    </Tooltip>
  );
};

export default UserBubble;
