import clsx from 'clsx';
import React from 'react';

import styles from './DashboardButton.module.scss';

interface Props extends React.HTMLAttributes<HTMLButtonElement> {
  // some names where chosen using https://colornamer.robertcooper.me/
  color?:
    | 'primary'
    | 'light-primary'
    | 'red'
    | 'light-red'
    | 'violet'
    | 'transparent'
    | 'transparent-white'
    | 'eletric-ultramarine'
    | 'black';
  size?: 'small' | 'medium' | 'medium-large' | 'large' | 'xl';
  disabled?: boolean;
}

const DashboardButton: React.FC<Props> = ({
  color = 'primary',
  size = 'medium',
  children,
  className,
  ...props
}) => {
  return (
    <button
      className={clsx(
        styles.button,
        styles[`button_${size}`],
        styles[`button_${color}`],
        className
      )}
      {...props}
      disabled={props.disabled}
    >
      {children}
    </button>
  );
};

export default DashboardButton;
