/**
 * Sanitizes the given text for the note by removing excessive spaces and new lines.
 * @param text The text to be sanitized.
 * @returns The sanitized text.
 */
export const noteTextFormat = (text: string) => {
  // Matches one or more consecutive whitespace characters at the beginning of a line that are followed by a newline character
  // and replaces them with an empty string.
  // This essentially removes any empty lines in a row.
  return (
    text
      .replace(/^(?:[\t ]*(?:\r?\n|\r))+/gm, '')
      // then we trim each line (w/ regex) and trim the whole string
      .replace(/^\s+|\s+$/gm, '')
      .trim()
  );
};

const protocolRegex = new RegExp(/^.+?:\/\//);

/**
 * Given a URL, returns the URL withouth the protocol.
 * @param url The URL to be sanitized.
 * @returns The sanitized URL.
 * @example
 * cleanUrl('https://www.google.com') // 'www.google.com'
 * cleanUrl('www.google.com') // 'www.google.com'
 * cleanUrl('google.com') // 'google.com'
 */
export const cleanUrl = (url: string) => {
  return url.replace(protocolRegex, '').replace(/\/$/, '');
};

/**
 * Given a filename and extension makes sure the filename has the extension.
 * @param filename The filename to check.
 * @param extension The extension to check for.
 * @returns The filename with the extension.
 * @example
 * ensureFilenameExtension('test', 'txt') // 'test.txt'
 * ensureFilenameExtension('test.txt', 'txt') // 'test.txt'
 * ensureFilenameExtension('test.txt', 'md') // 'test.txt.md'
 */
export const ensureFilenameExtension = (filename: string, extension: string): string => {
  const cleanedExtension = extension.replace(/^\./, '');
  return filename.endsWith(`.${cleanedExtension}`) ? filename : `${filename}.${cleanedExtension}`;
};
