import { SafePresence, UserCache } from '../../hooks/member';
import { Member } from '../types';

export enum UserActionType {
  SET_IGNORED = 'USER:SET_IGNORED',
  SET_MUTED = 'USER:SET_MUTED',
  SET_MEMBERS = 'USER:SET_MEMBERS',
  SET_MEMBER = 'USER:SET_MEMBER',
  ADD_MEMBER = 'USER:ADD_MEMBER',
  DEL_MEMBER = 'USER:DEL_MEMBER',

  SET_MEMBER_PRESENCE = 'USER:SET_MEMBER_PRESENCE',
  SET_MEMBER_CONNECTION_ID = 'USER:SET_MEMBER_CONNECTION_ID',
  SET_MEMBER_CACHE = 'USER:SET_MEMBER_CACHE',
  MEMBER_REFRESH = 'USER:MEMBER_REFRESH',

  RESET = 'USER:RESET',
}

const initialState: UserState = {
  id: '',
  members: [] as Member[],
  memberRefresh: 0,
};

export type UserState = {
  id: string;
  members: Member[];
  memberRefresh: number;
};

export type UserAction =
  | {
      type: UserActionType.ADD_MEMBER;
      payload: Member;
    }
  | {
      type: UserActionType.SET_MEMBERS;
      payload: Member[];
    }
  | {
      type: UserActionType.DEL_MEMBER | UserActionType.SET_MEMBER;
      payload: string;
    }
  | {
      type: UserActionType.SET_IGNORED | UserActionType.SET_MUTED;
      payload: {
        id: string;
        value: boolean;
      };
    }
  | {
      type: UserActionType.SET_MEMBER_PRESENCE;
      userId: string;
      presence: SafePresence | null;
    }
  | {
      type: UserActionType.SET_MEMBER_CONNECTION_ID;
      userId: string;
      connectionId: number;
    }
  | {
      type: UserActionType.SET_MEMBER_CACHE;
      userId: string;
      cache: UserCache | null;
    }
  | {
      type: UserActionType.RESET;
    };

const reducer = (state: UserState, action: UserAction): UserState => {
  switch (action.type) {
    case UserActionType.ADD_MEMBER:
      // Add member if it does not exist (by id), or set it if it does.
      return {
        ...state,
        members: [
          ...state.members.filter((member) => member.id !== action.payload.id),
          action.payload,
        ],
        memberRefresh: state.memberRefresh + 1,
      };
    case UserActionType.DEL_MEMBER:
      return {
        ...state,
        members: state.members.filter((m) => m.id !== action.payload),
      };
    case UserActionType.SET_MEMBER:
      return {
        ...state,
        id: action.payload,
      };
    case UserActionType.SET_MEMBERS:
      return {
        ...state,
        members: action.payload,
        memberRefresh: state.memberRefresh + 1,
      };
    case UserActionType.SET_IGNORED:
      return {
        ...state,
        members: state.members.map((m) => {
          if (m.id === action.payload.id) {
            return {
              ...m,
              ignored: action.payload.value,
            };
          }
          return m;
        }),
      };
    case UserActionType.SET_MUTED:
      return {
        ...state,
        members: state.members.map((m) => {
          if (m.id === action.payload.id) {
            return {
              ...m,
              muted: action.payload.value,
            };
          }
          return m;
        }),
      };
    case UserActionType.SET_MEMBER_PRESENCE:
      return {
        ...state,
        members: state.members.map((m) => {
          if (m.displayname === action.userId) {
            return {
              ...m,
              presence: action.presence ?? undefined,
            };
          }
          return m;
        }),
      };
    case UserActionType.SET_MEMBER_CONNECTION_ID:
      return {
        ...state,
        members: state.members.map((m) => {
          if (m.displayname === action.userId) {
            return {
              ...m,
              connectionId: action.connectionId,
            };
          }
          return m;
        }),
      };
    case UserActionType.SET_MEMBER_CACHE:
      return {
        ...state,
        members: state.members.map((m) => {
          if (m.displayname === action.userId) {
            return {
              ...m,
              cache: action.cache ?? undefined,
            };
          }
          return m;
        }),
      };
    case UserActionType.RESET:
      return {
        ...state,
        members: [],
      };
    default:
      return state;
  }
};

const getters = (state: UserState) => {
  const getMember = (id: string) => {
    return state.members.find((m) => m.id === id);
  };

  const getMemberByUserId = (userId: string) => {
    // User id is stored in the displayname
    return state.members.find((m) => m.displayname === userId);
  };

  return {
    get me() {
      return getMember(state.id);
    },
    getMember,
    getMemberByUserId,
  } as const;
};

export default { reducer, getters, initialState } as const;
