import { WoodyError } from '@fabric/woody-client';
import produce from 'immer';
import { useEffect, useState } from 'react';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

import { useAuth } from '../hooks/auth';
import { useWoody } from '../services/woody/WoodyProvider';
import { OptimisticDraft } from '../types/draftable';
import { HalNotification } from './../services/woody/types/notifications';

export interface NotificationStore {
  notifications: OptimisticDraft<HalNotification>[];
  setNotifications: (notifications: OptimisticDraft<HalNotification>[]) => void;

  setNotification: (notification: OptimisticDraft<HalNotification>) => void;
  removeNotification: (notification: OptimisticDraft<HalNotification>) => void;

  reset: () => void;
}

const useNotificationsStore = create<NotificationStore>()(
  persist(
    (set, get) => ({
      notifications: [],
      setNotifications: (notifications: OptimisticDraft<HalNotification>[]) =>
        set({ notifications }),
      setNotification: (notification: OptimisticDraft<HalNotification>) => {
        set(
          produce(get(), (state) => {
            const index = state.notifications.findIndex((l) => l.id === notification.id);
            if (index === -1) {
              state.notifications.push(notification);
            } else {
              state.notifications[index] = notification;
            }
          })
        );
      },
      removeNotification: (notification: OptimisticDraft<HalNotification>) =>
        set(
          produce(get(), (draft) => {
            draft.notifications = draft.notifications.filter((l) => l.id !== notification.id);
          })
        ),
      reset: () => set({ notifications: [] }),
    }),
    {
      name: 'NotificationStore',
      partialize: (state) => {
        // we will only keep 30 notifications
        return {
          notifications: state.notifications.slice(-30),
        };
      },
    }
  )
);

export const useGetNotifications = () => {
  const { client } = useWoody();
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [fetched, setFetched] = useState(false);
  const [error, setError] = useState<WoodyError | null>(null);

  const sub = user?.attributes?.sub ?? '';

  const { notifications, setNotifications } = useNotificationsStore((s) => ({
    notifications: s.notifications,
    setNotifications: s.setNotifications,
  }));

  useEffect(() => {
    if (!sub || loading || fetched) return;

    const fetchNotifications = async () => {
      setLoading(true);
      const response = await client.getNotifications(sub);

      setLoading(false);
      setFetched(true);

      if (response.error) {
        setError(response.error);
        return;
      }
      if (response?.data?._embedded?.notifications)
        setNotifications(response.data._embedded.notifications);
    };

    fetchNotifications();
  }, [sub, loading, fetched, client, setNotifications]);

  // re-fetch notes every 15 seconds
  useEffect(() => {
    if (!sub || !fetched) return;

    const interval = setInterval(() => {
      setFetched(false);
    }, 15000);

    return () => clearInterval(interval);
  }, [sub, fetched]);

  return { notifications, setNotifications, loading, error };
};

export default useNotificationsStore;
