import React from 'react';

import DialogueComponent from '../ui/Dialogue';

export type DialogueOptions = {
  showCancel?: boolean;
  cancelText?: string | React.ReactNode;
  confirmText?: string | React.ReactNode;
  confirmColor?: string;
  showConfirm?: boolean;
  onConfirm?: () => void;
  onCancel?: () => void;
};

export type Dialogue = {
  id: number;
  content: string | React.ReactNode;
  options: DialogueOptions;
  created: Date;
};

interface DialogueContextData {
  dialogues: Dialogue[];
  addDialogue: (content: string | React.ReactNode, options?: DialogueOptions) => void;
  removeDialogue: (id: number) => void;
  clearDialogues: () => void;
}

const DialogueContext = React.createContext<DialogueContextData>({} as DialogueContextData);
export const useDialogue = () => React.useContext(DialogueContext);

export const DialogueProvider = ({ children }: { children: React.ReactNode }) => {
  const [dialogues, setDialogues] = React.useState<Dialogue[]>([]);
  const [dialogueId, setDialogueId] = React.useState(0);

  const addDialogue = (content: string | React.ReactNode, options?: DialogueOptions) => {
    const dialogue = {
      id: dialogueId,
      content,
      options: {
        showCancel: true,
        cancelText: 'Cancel',
        confirmText: 'Confirm',
        confirmColor: '#0029FF',
        showConfirm: true,
        ...options,
      },
      created: new Date(),
    };
    setDialogueId(dialogueId + 1);
    setDialogues([...dialogues, dialogue]);
  };

  const removeDialogue = (id: number) => {
    setDialogues(dialogues.filter((dialogue) => dialogue.id !== id));
  };

  const clearDialogues = () => {
    setDialogues([]);
  };

  return (
    <DialogueContext.Provider value={{ dialogues, addDialogue, removeDialogue, clearDialogues }}>
      {dialogues.length > 0 && <div className="fixed inset-0 z-40 bg-black opacity-50" />}
      {dialogues.map((dialogue) => (
        <DialogueComponent key={dialogue.id} {...dialogue} />
      ))}

      {children}
    </DialogueContext.Provider>
  );
};
