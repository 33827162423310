import { HalFdoc } from '@fabric/woody-client';
import clsx from 'clsx';
import React, { memo, useState } from 'react';
import Highlighter from 'react-highlight-words';

import { ReactComponent as Image } from '../../../assets/images/icons/BuzzNoteImage.svg';
import { ReactComponent as Page } from '../../../assets/images/icons/BuzzNotePage.svg';
import { ReactComponent as Text } from '../../../assets/images/icons/BuzzNoteText.svg';
import { ReactComponent as File } from '../../../assets/images/icons/File.svg';
import { ReactComponent as LinkExternal } from '../../../assets/images/icons/LinkExternal.svg';
import useUIStore from '../../../store/ui';
import stringToRegExp from '../../../utils/stringToRegExp';
import { cleanUrl } from '../../../utils/text';
import Favicon from '../../Favicon';
import HoverItem from '../../HoverItem/HoverItem';
import { OfViewMode } from '../../ListModeSwitcher/ListModeSwitcher';
import styles from './FdocItemHeader.module.scss';

/**
 * FdocItemHeader is a component that is used to render the header of a fdoc in different view modes (Grid or List).
 * The header consists of the fdoc title and a link to the original page (if available), and highlights any matching query text.
 * When hovering over the header, a HoverItem component is displayed with the full title and url of the fdoc.
 *
 * @param fdoc the fdoc object that contains the data to render the header
 * @param viewMode the view mode (Grid or List) that determines how the header is rendered
 * @param query the search query that is used to highlight matching text in the header
 * @param openFdocInExternalPage a callback that is called when the header is clicked, to open the fdoc in an external page
 */
const FdocItemHeader: React.FC<{
  fdoc: HalFdoc;
  viewMode: OfViewMode;
  openFdocInExternalPage: (fdoc: HalFdoc) => void;
  disableHover?: boolean;
}> = ({ fdoc, viewMode, openFdocInExternalPage, disableHover = false }) => {
  const [hoverPosition, setHoverPosition] = useState({ x: 0, y: 0 });
  const [isHoverVisible, setIsHoverVisible] = useState(false);
  const [hoverFadeVisible, setHoverFadeVisible] = useState(false);
  const query = useUIStore((s) => s.searchQuery);

  const isWebnote = 'pageUrl' in fdoc;

  // clean the url from protocol and other unnecessary parts
  const cleanedUrl = isWebnote ? cleanUrl(fdoc.pageUrl) : '';

  /**
   * hoverHandlers is an object that contains event handlers that allows
   * allows the HoverItem component to be shown and hidden, and its position to be updated.
   */
  const hoverHandlers = {
    onClick: (e: React.MouseEvent) => {
      e.stopPropagation();
      openFdocInExternalPage(fdoc);
      setIsHoverVisible(false);
    },
    onMouseEnter: () => {
      if (disableHover) return;
      setIsHoverVisible(true);
    },
    onMouseLeave: () => {
      if (disableHover) return;
      setIsHoverVisible(false);
    },
    onMouseMove: (e: React.MouseEvent) => setHoverPosition({ x: e.clientX, y: e.clientY }),
  };

  const hoverFadeHandlers = {
    onMouseEnter: () => {
      if (disableHover) return;
      setHoverFadeVisible(true);
      setIsHoverVisible(true);
    },
    onMouseLeave: () => {
      if (disableHover) return;
      setHoverFadeVisible(false);
      setIsHoverVisible(false);
    },
  };

  return !isWebnote ? (
    viewMode === 'List' ? (
      <div data-testid="item-header-section" className={styles.item__header} {...hoverHandlers}>
        {viewMode === 'List' && (
          <>
            {fdoc.type === 'notepad' && <Text />}
            {fdoc.type === 'stored_file' && fdoc.contentType?.startsWith('image') && <Image />}
            {fdoc.type === 'stored_file' && !fdoc.contentType?.startsWith('image') && <File />}
          </>
        )}
        <div className={styles.item__hover__header__title}>
          <h2>
            <Highlighter
              highlightTag="span"
              highlightClassName="highlight"
              textToHighlight={fdoc.title ? fdoc.title : 'Text note'}
              searchWords={[stringToRegExp(query)]}
            />
          </h2>
        </div>
      </div>
    ) : null
  ) : (
    <>
      <HoverItem
        title={fdoc.pageTitle}
        url={fdoc.pageUrl}
        show={isHoverVisible}
        {...hoverPosition}
      />

      <div
        className={clsx(
          styles.item__hover__header__fade,
          hoverFadeVisible && styles.item__hover__header__fade__visible
        )}
      />

      {viewMode === 'Grid' && (
        <div
          data-testid="item-header-section"
          className={styles.item__hover__header}
          {...hoverHandlers}
          {...hoverFadeHandlers}
        >
          <div className={styles.item__hover__header__title}>
            <Favicon url={fdoc.pageUrl} size={19} className={styles.item__hover__header__favicon} />
            <h2>{fdoc.pageTitle}</h2>
          </div>

          <LinkExternal />
        </div>
      )}

      {(viewMode === 'List' || fdoc.type === 'page') && (
        <div data-testid="item-header-section" className={styles.item__header} {...hoverHandlers}>
          {viewMode === 'List' && (
            <>
              {fdoc.type === 'image' && <Image />}
              {fdoc.type === 'page' && <Page />}
              {fdoc.type === 'text' && <Text />}
            </>
          )}

          <div
            className={clsx(
              styles.item__hover__header__webnote_title,
              styles.item__hover__header__title
            )}
          >
            <Favicon url={fdoc.pageUrl} size={19} className={styles.item__hover__header__favicon} />
            <h2>
              <Highlighter
                highlightTag="span"
                highlightClassName="highlight"
                textToHighlight={fdoc.pageTitle}
                searchWords={[stringToRegExp(query)]}
              />
            </h2>

            <LinkExternal />
          </div>
        </div>
      )}

      {fdoc.type === 'page' && (
        <div data-testid="item-header-section" className={styles.item__header_url}>
          <div className={styles.item__header_url__text} {...hoverHandlers}>
            <span>{cleanedUrl}</span>
          </div>
        </div>
      )}
    </>
  );
};

export default memo(FdocItemHeader);
