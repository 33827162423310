// There is no reason for this to be an actual hook, so it is not.
// This will simply console log with a bit more information.
const log = (type: 'info' | 'debug' | 'error' | 'warn', ...message: any[]) => {
  const prefix = `%c [${new Date().toISOString()}] ${type.toLocaleUpperCase()}:`;
  // Info -> auto, Debug -> gray, Error -> red, Warn -> orange
  const styles = `color: ${
    type === 'info' ? 'auto' : type === 'debug' ? 'gray' : type === 'error' ? 'red' : 'orange'
  }; font-weight: bold;`;
  const args = [prefix, styles, ...message];

  console[type](...args);
};

export default Object.assign(log, {
  info: log.bind(null, 'info'),
  debug: log.bind(null, 'debug'),
  error: log.bind(null, 'error'),
  warn: log.bind(null, 'warn'),
});
