import { useMemo } from 'react';

type FaviconSize = 16 | 32 | 64 | 128;

const useFavicon = (url: string, size: number = 32): string | null => {
  // get nearest favicon size
  const faviconSize = useMemo(() => {
    const sizes: FaviconSize[] = [16, 32, 64, 128];
    const nearestSize = sizes.reduce((prev, curr) =>
      Math.abs(curr - size) < Math.abs(prev - size) ? curr : prev
    );
    return nearestSize;
  }, [size]);

  const faviconUrl = useMemo(() => {
    if (!url || (!url.startsWith('http') && !url.startsWith('https'))) {
      return null;
    }

    const base = `https://grumpy-plum-snail.faviconkit.com`;
    try {
      const u = new URL(url);
      return `${base}/${u.hostname}/${faviconSize}`;
    } catch (e) {
      return null;
    }
  }, [url, faviconSize]);

  return faviconUrl;
};

export default useFavicon;
