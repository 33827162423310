import { HalFdoc } from '@fabric/woody-client';
import classNames from 'classnames';
import React, { memo, useCallback, useRef, useState } from 'react';

import { ReactComponent as Check } from '../../assets/images/icons/Check.svg';
import { useSharedHover } from '../../hooks/sharedHover';
import { OptimisticDraft } from '../../types/draftable';
import Tooltip from '../../ui/Tooltip';
import { OfViewMode } from '../ListModeSwitcher/ListModeSwitcher';
import styles from './FdocItem.module.scss';
import FdocItemComments from './FdocItemComments/FdocItemComments';
import FdocItemContent from './FdocItemContent/FdocItemContent';
import FdocItemContextMenu from './FdocItemContextMenu/FdocItemContextMenu';
import FdocItemHeader from './FdocItemHeader/FdocItemHeader';
import FdocItemSkeleton from './FdocItemSkeleton/FdocItemSkeleton';
// import { useSharedHover } from "../../../hooks/sharedHover";

type FdocItemProps = {
  fdoc?: OptimisticDraft<HalFdoc>;

  handleOnRemove?: (fdoc?: HalFdoc) => void;
  handleOnClick?: (fdoc?: HalFdoc) => void;

  viewMode?: OfViewMode;
  selectionMode?: boolean;
  canMultiSelect?: boolean;
  selected?: boolean;
  showOwner?: boolean;
  disableHover?: boolean;
  canDelete?: boolean; // represents if the user can delete or remove the note
  canRemove?: boolean; // represents if the user can remove the note from a list
  showDate?: boolean;
};

const FdocItem: React.FC<FdocItemProps> = ({
  fdoc,
  handleOnRemove = () => {},
  handleOnClick = () => {},
  viewMode = 'List',
  selectionMode,
  canMultiSelect = false,
  selected,
  disableHover = false,
  canDelete = false,
  canRemove = false,
  showDate = true,
}) => {
  const { setHover, getHover } = useSharedHover();
  const ref = useRef<HTMLDivElement | null>(null);

  const [showingContextMenu, setShowingContextMenu] = useState(false);
  const [contextMenuPosition, setContextMenuPosition] = useState<{
    x: number;
    y: number;
  }>({ x: 0, y: 0 });

  const handleDeleteClick = useCallback(() => {
    handleOnRemove(fdoc);
  }, [fdoc, handleOnRemove]);

  // In useCallback to avoid unecessary rerenders on children
  const openFdocInExternalPage = useCallback((fdoc: HalFdoc) => {
    if (!('pageUrl' in fdoc)) return;
    window.open(fdoc.pageUrl + `#${fdoc.id}`);
  }, []);

  const onContextMenu = (e: React.MouseEvent) => {
    e.preventDefault();
    setShowingContextMenu(true);
    setContextMenuPosition({ x: e.clientX, y: e.clientY });
  };

  return (
    <>
      {fdoc && showingContextMenu && (
        <FdocItemContextMenu
          onGoToSourceClick={() => openFdocInExternalPage(fdoc)}
          onDeleteClick={handleDeleteClick}
          dismissContextMenu={() => setShowingContextMenu(false)}
          clientX={contextMenuPosition.x}
          clientY={contextMenuPosition.y}
          selectionMode={selectionMode}
          selected={selected}
          hideDelete={fdoc?.isDraft || (!canDelete && !canRemove)}
          canDelete={canDelete}
          sourceUrl={'pageUrl' in fdoc ? fdoc.pageUrl : undefined}
        />
      )}

      <div
        className={classNames(
          styles.item,
          viewMode === 'Grid' ? styles.item__view_mode__grid : styles.item__view_mode__list,
          (selectionMode || canMultiSelect) && selected && styles.selected,
          selectionMode && styles.selectionMode,
          selected && styles.in_collection,
          disableHover && styles.disableHover,
          !fdoc && styles.item__loading,
          fdoc && getHover(fdoc.id) && styles['item--hover'],
          fdoc?.type === 'notepad' && styles.item__type__notepad
        )}
        onClick={() => handleOnClick(fdoc)}
        onContextMenu={onContextMenu}
        ref={ref}
        onMouseEnter={() => fdoc && setHover(fdoc.id, true)}
        onMouseLeave={() => fdoc && setHover(fdoc.id, false)}
        data-testid={fdoc?.type + '-note-item'}
        data-selectable-id={fdoc?.id}
        data-category={fdoc?.type}
      >
        {fdoc ? (
          <div className={styles.item__content_wrapper}>
            <FdocItemHeader
              fdoc={fdoc}
              viewMode={viewMode}
              openFdocInExternalPage={openFdocInExternalPage}
              disableHover={disableHover}
            />
            <FdocItemContent viewMode={viewMode} fdoc={fdoc} />
            <FdocItemComments viewMode={viewMode} fdoc={fdoc} />

            {viewMode === 'List' && showDate && (
              <div className={styles.item__created_at}>
                {new Date(fdoc.createdAt).toLocaleDateString('en-GB', {
                  day: 'numeric',
                  month: 'numeric',
                  year: 'numeric',
                })}
              </div>
            )}
          </div>
        ) : (
          <FdocItemSkeleton viewMode={viewMode} />
        )}
        {selectionMode && selected && (
          <Tooltip label="In collection">
            <div className={styles.item__collection}>
              <Check />
            </div>
          </Tooltip>
        )}
      </div>
    </>
  );
};

export default memo(FdocItem);
