import LogRocket from 'logrocket';
import { useMemo } from 'react';
import { useEffect, useState } from 'react';

const isAppIdValid = (appId: string | undefined): boolean => {
  const isSet = !!appId;
  // 'not-specified' is used in lieu of undefined because Amplify does not
  // let env variables be empty (not even on a per-branch basis)
  const isDummyAppId = appId === 'not-specified';
  return isSet && !isDummyAppId;
};

export const getLogRocketAppId = (): string | null => {
  const appId = process.env.REACT_APP_LOGROCKET_APP_ID;
  const isValid = isAppIdValid(appId);
  return isValid ? (appId as string) : null;
};

const useLogRocket = (userIdentifier: string) => {
  const [isIdentified, setIsIdentified] = useState<boolean>(false);

  const appId = useMemo(() => getLogRocketAppId(), []);

  useEffect(() => {
    if (!appId || !userIdentifier || isIdentified) {
      return;
    }
    LogRocket.identify(userIdentifier);
    setIsIdentified(true);
  }, [isIdentified, userIdentifier, appId]);
};

export default useLogRocket;
