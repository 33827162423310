import { useMap, useUpdateMyPresence } from '@liveblocks/react';
import { useCallback, useEffect } from 'react';

import analytics, { EventType } from '../services/analytics';
import { EVENT } from '../simba/events';
import { useSimbaStore } from '../simba/store';
import { ChatActionType } from '../simba/store/chat';
import { VideoActionType } from '../simba/store/video';
import { Member } from '../simba/types';
import { useLocalCursor } from './cursor';
import { useSyncClipboard } from './interaction';
import { Presence, useMembers, UserCache } from './member';
import { useNotifyWithMember } from './realtimeNotifications';

export const parseInput = (userInput: string): URL | string => {
  try {
    const urlObj = new URL(userInput.startsWith('http') ? userInput : `https://${userInput}`);
    // If the urlObj host does not contain a . it is missing the top-level domain or there is not more than one letter between the host and the TLD
    // it is not a valid url
    if (!urlObj.host.includes('.')) throw new Error('Missing top-level domain');
    if (urlObj.host.indexOf('.') === 0 || urlObj.host.indexOf('.') === urlObj.host.length - 1)
      throw new Error('Missing top-level domain');
    return urlObj;
  } catch (e) {
    return userInput;
  }
};

const useLogEvent = () => {
  const { state } = useSimbaStore();

  const browserId = state.remote.serviceId;
  return useCallback(
    (event: EventType) => {
      if (!browserId) return;
      analytics.logEvent(browserId, event);
    },
    [browserId]
  );
};

const useToggleControl = () => {
  const { state, getters } = useSimbaStore();
  const { setMode, setType, setDrawing } = useLocalCursor();
  const syncClipboard = useSyncClipboard();

  return useCallback(
    (value?: boolean) => {
      if ((!getters.remote.hosting && value === undefined) || value) {
        setMode('normal');
        setDrawing(false);
        state.client.sendMessage(EVENT.CONTROL.REQUEST);
        syncClipboard();
      } else {
        // Back to default cursor type
        setType('default');
        state.client.sendMessage(EVENT.CONTROL.RELEASE);
      }
    },
    [getters.remote.hosting, state.client, syncClipboard, setType, setMode, setDrawing]
  );
};

const useUpdateResolutionToRef = (ref: HTMLElement | null, condition: boolean = true) => {
  const { dispatch } = useSimbaStore();

  useEffect(() => {
    const element = ref;
    if (!element || !condition) return;

    const updateVideoSize = () => {
      dispatch({
        type: VideoActionType.SET_VIDEO_SIZE,
        payload: {
          width: element.offsetWidth,
          height: element.offsetHeight,
        },
      });
    };

    updateVideoSize();

    window.addEventListener('resize', updateVideoSize);
    element.addEventListener('resize', updateVideoSize);
    return () => {
      window.removeEventListener('resize', updateVideoSize);
      element.removeEventListener('resize', updateVideoSize);
    };
  }, [condition, dispatch, ref]);
};

const useAutoSizeVideo = (ref: HTMLVideoElement | null, useStreamSize: boolean) => {
  const { state, getters, dispatch } = useSimbaStore();
  const { videoWidth, videoHeight, width: streamWidth, height: streamHeight } = state.video;

  const otherPrivate = state.remote.private && !getters.remote.hosting;

  useEffect(() => {
    const videoElement = ref;
    const parent = videoElement?.parentElement;

    if (!videoElement || !parent) return;

    const updateOverlaySize = () => {
      // We want to make sure the videoTag is always occupying as much space as possible.
      // We do this by calculating the ratio of the videoTag to the parent.
      const parentWidth = parent.offsetWidth;
      const parentHeight = parent.offsetHeight;
      const width = useStreamSize ? streamWidth : videoElement.videoWidth;
      const height = useStreamSize ? streamHeight : videoElement.videoHeight;

      const ratio = Math.min(parentWidth / width, parentHeight / height);
      const newWidth = width * ratio;
      const newHeight = height * ratio;

      // If width or height are NaN ignore the update.
      if (isNaN(newWidth) || isNaN(newHeight)) return;

      // Only dispatch if the size has changed.
      if (newWidth !== videoWidth || newHeight !== videoHeight) {
        dispatch({
          type: VideoActionType.SET_VIDEO_SIZE,
          payload: {
            width: newWidth,
            height: newHeight,
          },
        });
      }
    };

    // Events that could also change the video size
    videoElement.addEventListener('loadedmetadata', updateOverlaySize);
    window.addEventListener('resize', updateOverlaySize);
    updateOverlaySize();

    return () => {
      videoElement.removeEventListener('loadedmetadata', updateOverlaySize);
      window.removeEventListener('resize', updateOverlaySize);
    };
    // otherPrivate is a dependecy, so it recalculates when the private user stops being private.
  }, [
    dispatch,
    videoHeight,
    videoWidth,
    streamWidth,
    streamHeight,
    otherPrivate,
    ref,
    useStreamSize,
  ]);
};

const useToggleChat = () => {
  const { state, dispatch } = useSimbaStore();
  const { setHidden } = useLocalCursor();
  const updateMyPresence = useUpdateMyPresence();

  return useCallback(() => {
    dispatch({
      type: ChatActionType.SET_OPEN,
      payload: !state.chat.open,
    });

    setHidden(false);
    updateMyPresence({
      message: '',
    });
  }, [dispatch, state.chat.open, setHidden, updateMyPresence]);
};

const useCopySessionLink = () => {
  const notify = useNotifyWithMember();

  return useCallback(() => {
    const url = window.location.href;
    navigator.clipboard.writeText(url).then(() => {
      // Notification
      notify('link', 'invite link copied to clipboard');
    });
  }, [notify]);
};

/**
 * Returns a function that can be use to navigate the virtual browser.
 * @param urlOrText the url to go to
 * @param tab if undefined, open in current tab. If _blank, open in new tab. If number open in tab with that index.
 */
const useBrowserGoto = () => {
  const { state } = useSimbaStore();
  const { client, control } = state;

  return (urlOrText: string, tab?: undefined | '_blank' | number) => {
    const sendUrl = parseInput(urlOrText);

    client.sendMessage(EVENT.TAB.NAVIGATE, {
      url:
        typeof sendUrl === 'string' ? `https://www.google.com/search?q=${urlOrText}` : sendUrl.href,
      windowId:
        typeof tab === 'number'
          ? tab
          : typeof tab === 'string'
          ? undefined
          : control.windowId ?? undefined,
    });
  };
};

const useGetUserNameAndColor = () => {
  const members = useMembers<Presence>((param) =>
    ['name', 'color', 'userId', 'joined'].includes(param)
  );
  const storageCache = useMap<string, UserCache>('user-cache');

  return useCallback(
    (userId?: string, simbaMember?: Member | string) => {
      const member = members.find((m) => m.presence?.userId === userId);
      const userCache = storageCache?.get(userId || '');
      const displayName = typeof simbaMember == 'object' ? simbaMember?.displayname : null;

      const name =
        member?.presence?.name || displayName || userCache?.name || userCache?.originalName || '';
      const color = member?.presence?.color || userCache?.color || '#ffffff';

      return {
        liveblocksMember: member,
        name,
        color,
      };
    },
    [members, storageCache]
  );
};

export {
  useToggleControl,
  useLogEvent,
  useUpdateResolutionToRef,
  useToggleChat,
  useAutoSizeVideo,
  useCopySessionLink,
  useGetUserNameAndColor,
  useBrowserGoto,
};
