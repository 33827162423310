import React from 'react';

import { CursorProps } from '../../hooks/cursor';
import { getTextColor } from '../../utils/color';

const CursorPointer: React.FC<CursorProps> = ({ color, ...props }) => {
  const outlineColor = getTextColor(color);

  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.63 30.618c-.568-.718-1.258-2.186-2.486-3.968-.696-1.008-2.422-2.906-2.936-3.87-.446-.852-.398-1.234-.292-1.94.188-1.256 1.476-2.234 2.85-2.102 1.038.098 1.918.784 2.71 1.432.478.39 1.066 1.148 1.42 1.576.326.392.406.554.754 1.018.46.614.604.918.428.242-.142-.992-.374-2.686-.71-4.184-.256-1.136-.318-1.314-.562-2.186-.258-.928-.39-1.578-.632-2.562-.168-.696-.47-2.118-.552-2.918-.114-1.094-.174-2.878.528-3.698.55-.642 1.812-.836 2.594-.44 1.024.518 1.606 2.006 1.872 2.6.478 1.068.774 2.302 1.032 3.922.328 2.062.932 4.924.952 5.526.048-.738-.136-2.292-.008-3 .116-.642.656-1.388 1.332-1.59.572-.17 1.242-.232 1.832-.11.626.128 1.286.576 1.532.998.724 1.248.738 3.798.768 3.662.172-.752.142-2.458.568-3.168.28-.468.994-.89 1.374-.958.588-.104 1.31-.136 1.928-.016.498.098 1.172.69 1.354.974.436.688.684 2.634.758 3.316.03.282.148-.784.586-1.472.812-1.278 3.686-1.526 3.796 1.278.05 1.308.04 1.248.04 2.128 0 1.034-.024 1.656-.08 2.404-.062.8-.234 2.608-.484 3.484-.172.602-.742 1.956-1.304 2.768 0 0-2.148 2.5-2.382 3.626-.236 1.124-.158 1.132-.204 1.93-.046.796.242 1.844.242 1.844s-1.604.208-2.468.07c-.782-.126-1.75-1.682-2-2.158-.344-.656-1.078-.53-1.364-.046-.45.766-1.418 2.14-2.102 2.226-1.336.168-4.108.062-6.278.04 0 0 .37-2.022-.454-2.716-.61-.518-1.66-1.568-2.288-2.12l-1.664-1.842Z"
          fill={outlineColor}
        />
        <path
          clipRule="evenodd"
          d="M13.63 30.618c-.568-.718-1.258-2.186-2.486-3.968-.696-1.008-2.422-2.906-2.936-3.87-.446-.852-.398-1.234-.292-1.94.188-1.256 1.476-2.234 2.85-2.102 1.038.098 1.918.784 2.71 1.432.478.39 1.066 1.148 1.42 1.576.326.392.406.554.754 1.018.46.614.604.918.428.242-.142-.992-.374-2.686-.71-4.184-.256-1.136-.318-1.314-.562-2.186-.258-.928-.39-1.578-.632-2.562-.168-.696-.47-2.118-.552-2.918-.114-1.094-.174-2.878.528-3.698.55-.642 1.812-.836 2.594-.44 1.024.518 1.606 2.006 1.872 2.6.478 1.068.774 2.302 1.032 3.922.328 2.062.932 4.924.952 5.526.048-.738-.136-2.292-.008-3 .116-.642.656-1.388 1.332-1.59.572-.17 1.242-.232 1.832-.11.626.128 1.286.576 1.532.998.724 1.248.738 3.798.768 3.662.172-.752.142-2.458.568-3.168.28-.468.994-.89 1.374-.958.588-.104 1.31-.136 1.928-.016.498.098 1.172.69 1.354.974.436.688.684 2.634.758 3.316.03.282.148-.784.586-1.472.812-1.278 3.686-1.526 3.796 1.278.05 1.308.04 1.248.04 2.128 0 1.034-.024 1.656-.08 2.404-.062.8-.234 2.608-.484 3.484-.172.602-.742 1.956-1.304 2.768 0 0-2.148 2.5-2.382 3.626-.236 1.124-.158 1.132-.204 1.93-.046.796.242 1.844.242 1.844s-1.604.208-2.468.07c-.782-.126-1.75-1.682-2-2.158-.344-.656-1.078-.53-1.364-.046-.45.766-1.418 2.14-2.102 2.226-1.336.168-4.108.062-6.278.04 0 0 .37-2.022-.454-2.716-.61-.518-1.66-1.568-2.288-2.12l-1.664-1.842Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M30.1 31.327V24.41m-4.031 6.94-.032-6.946m-3.961.064.042 6.852"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
        />
      </g>
    </svg>
  );
};

export default CursorPointer;
