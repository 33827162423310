import {
  HalBrowser,
  HalHistory,
  HalSession,
  HalUser,
  HalUserMembershipsCollection,
  HalUserSessions,
  PumbaaRequest,
  Root,
  SessionTab,
} from './types';

class PumbaaClient {
  private baseUrl: string;

  private jwt?: string;

  private root?: Root;

  private user?: HalUser;

  constructor(baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  private log(data: any) {
    console.log('PumbaaClient', data);
  }

  /**
   * Needs to be called by the credentials manager whenever the
   * JWT for the user changes (due to refresh and whatnot).
   *
   * @param jwt The new JWT value to use for authenticated request.
   */
  public setAuthJWT(jwt: string) {
    this.jwt = jwt;
  }

  public async join(sessionKey: string, role: string = 'guest'): Promise<void> {
    await this.getUser();
    const request: PumbaaRequest = {
      authenticate: true,
      method: 'POST',
      path: `/sessions/${sessionKey}/members`,
      contentType: 'application/json',
      body: {
        _links: {
          user: {
            href: this.user?._links.self.href,
          },
        },
        role,
      },
    };

    await this.execute<null>(request);
  }

  public async createSession(title?: string): Promise<HalSession> {
    const request: PumbaaRequest = {
      authenticate: true,
      method: 'POST',
      path: '/sessions',
      contentType: 'application/json',
      body: {
        title,
      },
    };

    const session = await this.execute<HalSession>(request);
    return session;
  }

  public async getSession(id: string): Promise<HalSession> {
    const request: PumbaaRequest = {
      authenticate: false,
      method: 'GET',
      path: `/sessions/${id}`,
      contentType: 'application/json',
    };

    const session = await this.execute<HalSession>(request);
    return session;
  }

  public async updateSession(session: HalSession): Promise<HalSession> {
    const request: PumbaaRequest = {
      authenticate: true,
      method: 'PATCH',
      path: session._links.self.href,
      contentType: 'application/json',
      body: session,
    };

    const updatedSession = await this.execute<HalSession>(request);
    return updatedSession;
  }

  public async deleteSession(session: HalSession): Promise<void> {
    const request: PumbaaRequest = {
      authenticate: true,
      method: 'DELETE',
      path: session._links.self.href,
      contentType: 'application/json',
    };

    await this.execute<HalSession>(request);
  }

  async getBrowser(session: HalSession): Promise<HalBrowser> {
    const request: PumbaaRequest = {
      authenticate: false,
      method: 'GET',
      path: `${session._links.self.href}/browser`,
      contentType: 'application/json',
    };

    const browser = await this.execute<HalBrowser>(request);
    return browser;
  }

  public async getUserSessions(
    pageSize?: number,
    cursor?: { href: string }
  ): Promise<HalUserSessions> {
    const user = await this.getUser();

    const path = cursor?.href ?? `${user._links.self.href}/sessions`;
    this.log(path);
    const request: PumbaaRequest = {
      authenticate: true,
      method: 'GET',
      path,
      contentType: 'application/json',
      query: {
        pageSize,
      },
    };

    const userSessions = await this.execute<HalUserSessions>(request);
    return userSessions;
  }

  public async getUserSessionMemberships(): Promise<HalUserMembershipsCollection> {
    await this.getUser();
    const path = `${this.user?._links.self.href}/session-memberships`;
    const request: PumbaaRequest = {
      authenticate: true,
      method: 'GET',
      path,
      contentType: 'application/json',
      query: {
        pageSize: 50,
      },
    };

    const sessionMemberships = await this.execute<HalUserMembershipsCollection>(request);
    return sessionMemberships;
  }

  public async getSessionSnapshot(sessionKey: string): Promise<SessionTab[]> {
    const request: PumbaaRequest = {
      authenticate: true,
      method: 'GET',
      path: `/sessions/${sessionKey}/snapshot`,
      contentType: 'application/json',
    };

    const res = (await this.execute<any>(request)) as { tabs: SessionTab[] };
    return res.tabs;
  }

  public async getSessionHistory(session: HalSession): Promise<HalHistory> {
    const request: PumbaaRequest = {
      authenticate: true,
      method: 'GET',
      path: `${session._links.self.href}/history`,
      contentType: 'application/json',
    };

    const history = (await this.execute<any>(request)) as HalHistory;
    return history;
  }

  public async getUser(forceRefresh: boolean = false): Promise<HalUser> {
    if (this.user && !forceRefresh) {
      return this.user;
    }

    const root = await this.getRoot(forceRefresh);
    const request: PumbaaRequest = {
      authenticate: true,
      method: 'GET',
      path: root._links.me.href,
      contentType: 'application/json',
    };
    const user = await this.execute<HalUser>(request);
    this.user = user;
    return user;
  }

  public async getRoot(forceRefresh: boolean = false): Promise<Root> {
    if (this.root && !forceRefresh) {
      return this.root;
    }

    const request: PumbaaRequest = {
      authenticate: true,
      method: 'GET',
      path: '/',
      contentType: 'application/json',
    };

    const root = await this.execute<Root>(request);
    return root;
  }

  private async execute<T>(request: PumbaaRequest): Promise<T> {
    const method = request.method;
    const url = new URL(request.path, this.baseUrl);
    if (request.query) {
      for (const [k, v] of Object.entries(request.query)) {
        if (v !== undefined) {
          url.searchParams.append(k, v);
        }
      }
    }

    const headers = request.headers ?? ({} as { [k: string]: string });
    headers['Content-Type'] = request.contentType ?? 'application/json';

    if (request.authenticate && !this.jwt) {
      throw new Error('PumbaaClient JWT token is not set');
    }

    if (this.jwt) {
      headers.Authorization = `Bearer ${this.jwt}`;
    }

    const body = request.body ? JSON.stringify(request.body) : undefined;

    const res = await fetch(url, {
      method,
      headers,
      body,
    });

    if (res.status === 204) {
      return null as T;
    }

    const resBody = await res.json();
    return resBody as T;
  }
}

export default PumbaaClient;
