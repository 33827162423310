import React, { ReactNode, useContext, useEffect, useMemo, useState } from 'react';

import LoadingOverlay from '../../components/LoadingOverlay';
import { useAuth } from '../../hooks/auth';
import PumbaaClient from './PumbaaClient';

const PUMBAA_API_URL = process.env.REACT_APP_PUMBAA_URL;
if (!PUMBAA_API_URL) {
  throw new Error('REACT_APP_PUMBAA_API_URL ENV variable is undefined');
}

interface IPumbaaContext {
  client: PumbaaClient;
}

const PumbaaContext = React.createContext<IPumbaaContext>({} as IPumbaaContext);

export const PumbaaProvider = ({ children }: { children: ReactNode }) => {
  const { jwtToken, authProcessing } = useAuth();
  const client = useMemo(() => new PumbaaClient(PUMBAA_API_URL), []);
  const [isReady, setIsReady] = useState<boolean>(false);

  useEffect(() => {
    if (authProcessing) {
      return;
    }

    client.setAuthJWT(jwtToken ?? '');
    setIsReady(true);
    client.getUser(true);
  }, [client, authProcessing, jwtToken]);

  if (!isReady) {
    return <LoadingOverlay></LoadingOverlay>;
  }

  return <PumbaaContext.Provider value={{ client }}>{children}</PumbaaContext.Provider>;
};

export const usePumbaa = () => useContext(PumbaaContext);
