import { HalFdoc } from '@fabric/woody-client';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import Highlighter from 'react-highlight-words';

import { useGetWebnoteImage } from '../../../store/helpers';
import useUIStore from '../../../store/ui';
import stringToRegExp from '../../../utils/stringToRegExp';
import FileDisplay from '../../FileDisplay/FileDisplay';
import { OfViewMode } from '../../ListModeSwitcher/ListModeSwitcher';
import Skeleton from '../../Skeleton/Skeleton';
import styles from './FdocItemContent.module.scss';

const FdocItemContent: React.FC<{
  fdoc: HalFdoc;
  viewMode: OfViewMode;
}> = ({ fdoc, viewMode }) => {
  const query = useUIStore((s) => s.searchQuery);
  const [ref, setRef] = useState<HTMLDivElement | null>(null);

  const { image, loading: imageLoading } = useGetWebnoteImage(fdoc);

  // make it so the textRef maxHeight is the same as the width of the textRef
  // unless viewMode is "List"
  useEffect(() => {
    if (viewMode === 'List' || !ref) return;

    const updateWidth = () => {
      const { width } = ref.getBoundingClientRect();
      ref.style.maxHeight = `${width}px`;
    };

    updateWidth();
    // observe the ref for changes in width
    const observer = new ResizeObserver(updateWidth);
    observer.observe(ref);

    return () => {
      observer.unobserve(ref);
      // remove maxHeight style and let css handle it
      ref.style.maxHeight = '';
    };
  }, [viewMode, ref]);

  return (
    <>
      {fdoc.type !== 'page' && (
        <div className={styles.item__content} data-testid="item-content-section">
          {fdoc.type === 'text' && (
            <div className={clsx(styles.item__content__text, 'dashboard_scrollbar')} ref={setRef}>
              <Highlighter
                highlightTag="span"
                highlightClassName="highlight"
                textToHighlight={fdoc.context.text}
                searchWords={[stringToRegExp(query)]}
              />
            </div>
          )}
          {fdoc.type === 'notepad' && (
            <div
              className={clsx(styles.item__content__notepad, 'dashboard_scrollbar')}
              ref={setRef}
            >
              <Highlighter
                highlightTag="span"
                highlightClassName="highlight"
                textToHighlight={fdoc.content}
                searchWords={[stringToRegExp(query)]}
              />
            </div>
          )}
          {fdoc.type === 'image' && (
            <div
              className={styles.item__content__image}
              ref={setRef}
              data-testid="item-content-section"
            >
              {image ? (
                <img src={image} alt={fdoc.pageTitle} />
              ) : (
                imageLoading && <Skeleton width={335} height={335} />
              )}
            </div>
          )}
          {fdoc.type === 'stored_file' &&
            (fdoc.contentType?.includes('image') ? (
              <div
                className={styles.item__content__image}
                ref={setRef}
                data-testid="item-content-section"
              >
                {fdoc.url === 'load' ? (
                  <Skeleton width={335} height={335} />
                ) : (
                  <img src={fdoc.url} alt={fdoc.title} />
                )}
              </div>
            ) : (
              <div className={styles.stored_file}>
                <FileDisplay
                  fileExtension={fdoc.extension}
                  fileName={fdoc.title}
                  scale={viewMode === 'List' ? 0.2 : 0.4}
                  hideFileName={viewMode === 'List'}
                />
              </div>
            ))}
        </div>
      )}
      {fdoc.type === 'page' &&
        (image ? (
          <div className={styles.item__content__page} ref={setRef}>
            <img
              style={{
                objectFit: 'contain',
              }}
              src={image}
              alt={fdoc.pageTitle}
            />
          </div>
        ) : (
          imageLoading && (
            <div className={styles.item__content__page} ref={setRef}>
              <Skeleton width={335} height={335} />
            </div>
          )
        ))}
    </>
  );
};

export default FdocItemContent;
