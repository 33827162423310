import './Tooltip.scss';

import {
  arrow,
  autoUpdate,
  flip,
  offset,
  Placement,
  shift,
  useDismiss,
  useFloating,
  useFocus,
  useHover,
  useInteractions,
  useRole,
} from '@floating-ui/react-dom-interactions';
import clsx from 'clsx';
import { cloneElement, CSSProperties, ReactNode, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { mergeRefs } from 'react-merge-refs';

interface Props {
  label?: ReactNode;
  children: JSX.Element;
  placement?: Placement;
  portal?: boolean;
  maxSize?: {
    width: string;
    height: string;
  };
  forceShow?: boolean;
  error?: boolean;
  delay?: number;
  labelStyles?: CSSProperties;
}

const placementRotationMap: {
  [key in string]: string;
} = {
  top: '45deg',
  right: '135deg',
  bottom: '-135deg',
  left: '-45deg',
};

const Tooltip = ({
  children,
  placement = 'bottom',
  label,
  portal = true,
  maxSize = { width: '50vw', height: '50vh' },
  forceShow = false,
  error = false,
  delay = 1000,
  labelStyles = {},
}: Props) => {
  const [open, setOpen] = useState(false);
  const arrowRef = useRef<HTMLElement | null>(null);

  const {
    x,
    y,
    reference,
    floating,
    strategy,
    context,
    middlewareData,
    placement: realPlacement,
  } = useFloating({
    placement,
    open,
    onOpenChange: setOpen,
    middleware: [
      offset(20),
      flip(),
      shift({ padding: 8 }),
      arrow({
        element: arrowRef,
      }),
    ],
    whileElementsMounted: autoUpdate,
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useHover(context, {
      delay: { open: delay, close: 0 },
    }),
    useFocus(context),
    useRole(context, { role: 'tooltip' }),
    useDismiss(context),
  ]);

  const ref = useMemo(() => mergeRefs([reference, (children as any).ref]), [reference, children]);

  const staticSide = {
    top: 'bottom',
    right: 'left',
    bottom: 'top',
    left: 'right',
  }[realPlacement.split('-')[0]];

  const arrowDeg = placementRotationMap[staticSide ?? 'top'];

  const tooltip = (
    <>
      {(open || forceShow) && label && (
        <div
          {...getFloatingProps({
            ref: floating,
            className: clsx('tooltip', error && 'error'),
            style: {
              position: strategy,
              top: y ?? 0,
              left: x ?? 0,
              maxWidth: maxSize.width,
              maxHeight: maxSize.height,
              ...labelStyles,
            },
          })}
        >
          {label}

          <div
            ref={(ref) => (arrowRef.current = ref)}
            className="tooltip__arrow"
            style={{
              left: middlewareData.arrow?.x ?? 0,
              top: middlewareData.arrow?.y ?? 0,
              [staticSide ?? 'top']: '-5px',
              transform: `rotate(${arrowDeg})`,
            }}
          />
        </div>
      )}
    </>
  );

  const portalTarget = document.getElementById('tooltip-portal');

  return (
    <>
      {cloneElement(children, getReferenceProps({ ref, ...children.props }))}
      {portal && portalTarget ? createPortal(tooltip, portalTarget) : tooltip}
    </>
  );
};

export default Tooltip;
