import React from 'react';

const Privacy: React.FC = () => {
  return (
    <div className="onboarding__content">
      <h3
        style={{
          maxWidth: '607px',
        }}
      >
        Putting in your password / payment details?
        <br />
        Use <span style={{ color: '#B56BFF' }}>privacy mode</span>.
      </h3>
      <p
        style={{
          maxWidth: '357px',
        }}
      >
        Activate <span style={{ color: '#B56BFF' }}>[privacy mode]</span> any time to securely input
        sensitive data without anyone else seeing.
      </p>
    </div>
  );
};

export default Privacy;
