import clsx from 'clsx';
import React, { useEffect, useRef, useState } from 'react';

import { ReactComponent as Link } from '../assets/images/icons/Link.svg';
import { ReactComponent as PrivateEye } from '../assets/images/icons/PrivateEye.svg';
import { ReactComponent as ShockIcon } from '../assets/images/icons/Shock.svg';
import { useNotifications } from '../hooks/realtimeNotifications';

interface Props extends React.HTMLProps<HTMLDivElement> {
  notificationId: number;
  icon?: 'shock' | 'link' | 'private';
  bgColor?: string;
  message: React.ReactNode;
}

const RealtimeNotification: React.FC<Props> = ({
  notificationId,
  icon,
  message,
  className,
  style,
  bgColor,
  ...props
}) => {
  const { removeNotification } = useNotifications();

  // Timeout for fully deleting the notification
  // After 3s, they will fade out at ~2.2s
  const timeout = useRef<number>();
  useEffect(() => {
    timeout.current = window.setTimeout(() => {
      removeNotification(notificationId);
    }, 3000);

    return () => {
      window.clearTimeout(timeout.current);
    };
  }, [notificationId, removeNotification]);

  // Transition timeouts
  // It will toggle the opacity of the notification depending on the time passed
  const [openingTimeout, setOpeningTimeout] = useState<number | undefined>();
  const [closingTimeout, setClosingTimeout] = useState<number | undefined>();
  useEffect(() => {
    setOpeningTimeout(
      window.setTimeout(() => {
        setOpeningTimeout(undefined);

        setClosingTimeout(
          window.setTimeout(() => {
            setClosingTimeout(undefined);
          }, 2200)
        );
      }, 60)
    );
  }, []);

  return (
    <div
      className={clsx(
        'flex items-center px-6 py-3 mb-4 text-white backdrop-blur-[8px] bg-[rgba(0,0,0,0.7)] rounded-2xl h-[61px] whitespace-nowrap',
        className
      )}
      style={{
        opacity: openingTimeout ? 0 : closingTimeout ? 1 : 0,
        transition: 'opacity 0.1s ease-in-out',
        backgroundColor: bgColor ?? 'rgb(0,0,0,0.7)',
        ...style,
      }}
      {...props}
    >
      {icon === 'link' && <Link className="w-6 mr-4" />}
      {icon === 'shock' && <ShockIcon className="w-4 mr-4" />}
      {icon === 'private' && <PrivateEye className="w-8 h-8 mr-4" />}
      {message}
    </div>
  );
};

export default RealtimeNotification;
