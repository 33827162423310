import React from 'react';

const Invite: React.FC = () => {
  return (
    <div className="onboarding__content">
      <h1>It's time...</h1>
      <h3
        style={{
          maxWidth: '584px',
        }}
      >
        Invite some friends/coworkers to try it out!
      </h3>
      <p
        style={{
          maxWidth: '440px',
        }}
      >
        Click the <span style={{ color: '#44A5FF' }}>[invite]</span> button to copy the link. Share
        with anyone you want to browse together.
      </p>
      <p>Anyone can take part... they don’t need an account.</p>
    </div>
  );
};

export default Invite;
