import clsx from 'clsx';
import dayjs from 'dayjs';
import React from 'react';

import { ReactComponent as EmptyCactus } from '../assets/images/icons/EmptyCactus.svg';
import { Notification } from '../components/Notification/Notification';
import { HalNotification } from '../services/woody/types/notifications';
import { useGetNotifications } from '../store/notifications';
import styles from './Notifications.module.scss';

const Notifications: React.FC = () => {
  const { notifications, loading } = useGetNotifications();
  return (
    <div className={clsx(styles.notifications, 'dashboard_scrollbar')}>
      {notifications
        .sort((a, b) => dayjs(b.createdAt).diff(a.createdAt))
        .reduce((acc: HalNotification[], cur) => {
          if (!cur.readAt || acc.filter((c) => c.readAt).length < 4) acc.push(cur);
          return acc;
        }, [])
        .map((notification, index) => {
          return <Notification key={index} notification={notification} />;
        })}
      {!loading && notifications.length === 0 && (
        <div className={styles.empty_state}>
          <EmptyCactus />
          <h1>No notifications yet!</h1>
        </div>
      )}
    </div>
  );
};

export default Notifications;
