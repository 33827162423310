import './Skeleton.scss';

import classNames from 'classnames';
import React from 'react';

interface Props extends React.HTMLAttributes<HTMLSpanElement> {
  width?: number;
  height?: number;
  circle?: boolean;
  borderRadius?: number;
  style?: React.CSSProperties;
}

const Skeleton: React.FC<Props> = ({
  circle,
  width,
  height,
  borderRadius,
  style,
  className,
  ...props
}) => {
  return (
    <span
      {...props} // pass all other props to the root element
      className={classNames('skeleton', className)}
      style={{
        backgroundColor: '#DFDFDF',
        borderRadius: circle ? '50%' : borderRadius,
        // width and height are undefined because CSS setups a default
        // these are helper props so you don't always have to pass in a style object
        width: width ? `${width}px` : undefined,
        height: height ? `${height}px` : undefined,
        ...style,
      }}
    >
      ‌&zwnj;
    </span>
  );
};

export default Skeleton;
