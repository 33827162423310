import React from 'react';

/**
 * This hook will get an amount of time in milliseconds and a list of dependencies.
 * It will return true for the time period specified if any dependency has changed.
 * Otherwise, it will return false.
 * @param time The amount of time in milliseconds to wait for dependencies to change.
 * @param dependencies The dependencies to check for changes.
 * @param condition The condition to check for changes if false it will always return false. (optional)
 * @returns A boolean indicating whether the dependencies have changed.
 */
export const useChangedTimeout = (
  time: number,
  dependencies: any[],
  condition: boolean = true
): boolean => {
  const [hasChanged, setHasChanged] = React.useState(false);

  // Based on https://github.com/facebook/react/issues/14476#issuecomment-471199055 to avoid exhaustive dep linting errors
  const dependency = JSON.stringify(dependencies);

  React.useEffect(() => {
    setHasChanged(true);
    const timer = setTimeout(() => setHasChanged(false), time);

    return () => {
      clearTimeout(timer);
    };
  }, [time, dependency]);

  return condition && hasChanged;
};
