import React from 'react';
import { useRouteError } from 'react-router';

import InternalError from './InternalError';

const RouterErrorBoundary: React.FC = () => {
  const error = useRouteError() as Error;

  return <InternalError errorDescription={error?.message} errorStack={error?.stack} />;
};

export default RouterErrorBoundary;
