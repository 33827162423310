import '@aws-amplify/ui-react/styles.css';
import './Authenticator.scss';

import { platform } from '@todesktop/client-core';
import { I18n } from 'aws-amplify';
import React, { lazy } from 'react';
import { Outlet } from 'react-router-dom';

import { useAuth } from './hooks/auth';
import useHandleExtensionAuth from './hooks/extensionAuth';
import useLogRocket from './hooks/logrocket';
import { useHandleBroadcast, useHandleDeeplinks } from './hooks/todesktop';

const DesktopBar = lazy(() => import('./components/DesktopBar/DesktopBar'));

I18n.putVocabulariesForLanguage('en', {
  'Sign in': 'Log in',
  'Forgot your password?': 'Forgot password?',
  'Create Account': 'Sign up',
  'Back to Sign In': 'Back to log in',
  'Resend Code': "Didn't get a code? Click to resend.",
  'Sign In with Google': 'Log in with Google',
});

const App: React.FC = () => {
  const { identityId } = useAuth();
  useLogRocket(identityId);
  useHandleBroadcast();
  useHandleDeeplinks();
  useHandleExtensionAuth();

  return (
    <>
      {platform.todesktop.isDesktopApp() && platform.os.getOSType() === 'Darwin' && <DesktopBar />}
      <Outlet />
    </>
  );
};

export default App;
