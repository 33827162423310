import { HalList, WoodyError } from '@fabric/woody-client';
import produce from 'immer';
import { useEffect, useState } from 'react';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

import { useAuth } from '../hooks/auth';
import { useWoody } from '../services/woody/WoodyProvider';
import { OptimisticDraft } from '../types/draftable';

export interface ListStore {
  lists: OptimisticDraft<HalList>[];
  setLists: (lists: OptimisticDraft<HalList>[]) => void;

  setList: (list: OptimisticDraft<HalList>) => void;
  removeList: (list: OptimisticDraft<HalList>) => void;

  reset: () => void;
}

const useListsStore = create<ListStore>()(
  persist(
    (set, get) => ({
      lists: [],
      setLists: (lists: OptimisticDraft<HalList>[]) => set({ lists }),
      setList: (list: OptimisticDraft<HalList>) => {
        set(
          produce(get(), (state) => {
            const index = state.lists.findIndex((l) => l.id === list.id);
            if (index === -1) {
              state.lists.push(list);
            } else {
              state.lists[index] = list;
            }
          })
        );
      },
      removeList: (list: OptimisticDraft<HalList>) =>
        set(
          produce(get(), (draft) => {
            draft.lists = draft.lists.filter((l) => l.id !== list.id);
          })
        ),
      reset: () => set({ lists: [] }),
    }),
    {
      name: 'ListStore',
      partialize: (state) => {
        // we will only keep 30 lists
        return {
          lists: state.lists.slice(-30),
        };
      },
    }
  )
);

export const useGetLists = () => {
  const { client } = useWoody();
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [fetched, setFetched] = useState(false);
  const [error, setError] = useState<WoodyError | null>(null);

  const sub = user?.attributes?.sub ?? '';

  const { lists, setLists } = useListsStore((s) => ({
    lists: s.lists,
    setLists: s.setLists,
  }));

  useEffect(() => {
    if (!sub || loading || fetched) return;

    const fetchLists = async () => {
      setLoading(true);
      const response = await client.fetchMembershipsByUserId(sub);

      setLoading(false);
      setFetched(true);

      if (response.error) {
        setError(response.error);
        return;
      }

      setLists(response.data._embedded.item.map((membership) => membership._embedded.list));
    };

    fetchLists();
  }, [sub, loading, fetched, client, setLists]);

  // re-fetch notes every 15 seconds
  useEffect(() => {
    if (!sub || !fetched) return;

    const interval = setInterval(() => {
      setFetched(false);
    }, 15000);

    return () => clearInterval(interval);
  }, [sub, fetched]);

  return { lists, setLists, loading, error };
};

export const useGetList = (id?: string) => {
  const { client } = useWoody();
  const [fetched, setFetched] = useState(false);
  const [error, setError] = useState<WoodyError | null>(null);
  const { lists, setList } = useListsStore((s) => ({
    lists: s.lists,
    setList: s.setList,
  }));
  const [loading, setLoading] = useState(!id || !lists.find((list) => list.id === id));

  useEffect(() => {
    if (!id || fetched) return;

    const fetchList = async () => {
      setLoading(true);
      const response = await client.fetchListById(id);
      setFetched(true);

      if (response.error) {
        setError(response.error);
        return;
      }

      setList(response.data);
    };

    fetchList();
  }, [id, fetched, client, setList]);

  useEffect(() => {
    if (!fetched) return;

    setLoading(false);
  }, [fetched]);

  return {
    list: lists.find((list) => list.id === id),
    loading,
    error,
  };
};

export default useListsStore;
