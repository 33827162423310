export const webStoreExtensionId = 'ghedimbcbfcfkbhlojkjfbclokckkldj';
export const buzzWebStoreExtensionId = 'dbbaiokglpdmdlgjakcfcmfalfhbjhjn';

const extensionIds = [
  webStoreExtensionId,
  'mniipjegaeboefegpepjpeojehoelpgd', // dev, packed
  'ligbkjdianoeapkhmlcjjgeedikklfgb', // loaded unpacked
  'chdiigmcmmhopennkoogdnmloehcjmep', // unpacked Ivo
  'cbgiobjnpnjkioiielfolcedkondahlg', // unpacked Johnny
];

export const getEnabledExtensionId = async (): Promise<string | null> => {
  for (const extensionId of extensionIds) {
    if (await detectExtension(extensionId)) {
      return extensionId;
    }
  }
  return null;
};

export const getEnabledBuzzExtensionId = async (): Promise<string | null> => {
  return (await detectBuzzExtension(buzzWebStoreExtensionId)) ? buzzWebStoreExtensionId : null;
};

async function detectExtension(extensionId: string) {
  return new Promise((resolve) => {
    const img = new Image();
    img.onload = () => resolve(true);
    img.onerror = () => resolve(false);
    img.src = `chrome-extension://${extensionId}/sbx-ext-icon-16.png`;
  });
}

async function detectBuzzExtension(extensionId: string) {
  return new Promise((resolve) => {
    const img = new Image();
    img.onload = () => resolve(true);
    img.onerror = () => resolve(false);
    img.src = `chrome-extension://${extensionId}/icons/async-icon.png`;
  });
}
