import React from 'react';

import Skeleton from '../../components/Skeleton/Skeleton';
import SkeletonStructure from '../../components/SkeletonStructure/SkeletonStructure';

const ExpandedListSkeleton: React.FC = () => {
  return (
    <SkeletonStructure expand>
      <SkeletonStructure cols expand padding="22px 20px" gap={10}>
        <SkeletonStructure spaceBetween>
          <Skeleton width={430} height={24} borderRadius={6} />
          <Skeleton width={130} height={34} borderRadius={6} />
        </SkeletonStructure>
        <SkeletonStructure spaceBetween>
          <Skeleton width={330} height={48} borderRadius={24} />
          <Skeleton width={171} height={34} borderRadius={6} />
        </SkeletonStructure>
        <SkeletonStructure
          spaceBetween
          style={{
            marginTop: '10px',
            borderTop: '1px solid #e3e3e4',
            paddingTop: '20px',
          }}
        >
          <SkeletonStructure gap={8}>
            <Skeleton width={110} height={36} borderRadius={1000} />
            <Skeleton width={110} height={36} borderRadius={1000} />
            <Skeleton width={110} height={36} borderRadius={1000} />
            <Skeleton width={110} height={36} borderRadius={1000} />
          </SkeletonStructure>
        </SkeletonStructure>
      </SkeletonStructure>
    </SkeletonStructure>
  );
};

export default ExpandedListSkeleton;
