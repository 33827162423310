import React from 'react';

const Control: React.FC = () => {
  return (
    <div className="onboarding__content">
      <h3
        style={{
          maxWidth: '328px',
        }}
      >
        Grab control at any time
      </h3>
      <p
        style={{
          maxWidth: '440px',
        }}
      >
        Hit the <span style={{ color: '#FFE600' }}>[control]</span> button to grab or release
        control. Whoever has control is steering the current Fabric browser tab.
      </p>
    </div>
  );
};

export default Control;
