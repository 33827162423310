import React, { CSSProperties } from 'react';

interface SkeletonStructureProps {
  width?: number | string;
  height?: number | string;
  cols?: boolean;
  gap?: string | number;
  padding?: string | number;
  spaceBetween?: boolean;
  expand?: boolean;
  bgColor?: string;

  style?: CSSProperties;
}

const SkeletonStructure: React.FC<SkeletonStructureProps> = ({
  cols,
  gap,
  padding,
  expand,
  width,
  height,
  spaceBetween,
  children,
  bgColor,
  style,
}) => (
  <div
    style={{
      display: 'flex',
      flexDirection: cols ? 'column' : 'row',
      gap,
      padding,
      flex: expand ? '1' : '0',
      width: width ?? '100%',
      minWidth: width ?? undefined,
      height: height ?? '100%',
      minHeight: height ?? undefined,
      alignItems: 'center',
      justifyContent: spaceBetween ? 'space-between' : 'flex-start',
      backgroundColor: bgColor,
      ...style,
    }}
  >
    {children}
  </div>
);

export default SkeletonStructure;
