import React from 'react';

export const DEFAULT_ZOOM_FACTOR = 0.2;
export const ZOOM_FACTOR_MIN = 1;
export const ZOOM_FACTOR_MAX = 10;

export type Coordinates = { x: number; y: number };

export type ZoomPanState = {
  width: number;
  height: number;
  translateX: number;
  translateY: number;
  prevMouseX: number;
  prevMouseY: number;
  scale: number;
  contentWidth: number;
  contentHeight: number;
  isActive: boolean;
};

export const initialState: ZoomPanState = {
  width: 0,
  height: 0,
  translateX: 0,
  translateY: 0,
  prevMouseX: 0,
  prevMouseY: 0,
  scale: 1,
  contentWidth: 0,
  contentHeight: 0,
  isActive: false,
};

export enum ZoomPanActionType {
  PAN = 'ZOOMPAN:PAN',
  PAN_START = 'ZOOMPAN:PAN_START',
  ZOOM = 'ZOOMPAN:ZOOM',
  ZOOM_DEFAULT = 'ZOOMPAN:ZOOM_DEFAULT',
  SET_ZOOMPAN_IS_ACTIVE = 'ZOOMPAN:SET_ZOOMPAN_IS_ACTIVE',
  SET_ZOOMPAN_SIZE = 'ZOOMPAN:SET_ZOOMPAN_SIZE',
  RESET = 'ZOOMPAN:RESET',
}

export type ZoomPanAction =
  | {
      type: ZoomPanActionType.SET_ZOOMPAN_IS_ACTIVE;
      isActive: boolean;
    }
  | {
      type: ZoomPanActionType.PAN;
      clientX: number;
      clientY: number;
      deltaX?: number;
      deltaY?: number;
      containerRect: DOMRect;
      contentWidth: number;
      contentHeight: number;
    }
  | {
      type: ZoomPanActionType.PAN_START;
      clientX: number;
      clientY: number;
    }
  | {
      type: ZoomPanActionType.ZOOM;
      clientX: number;
      clientY: number;
      zoomFactor: number;
      containerRect: DOMRect;
      contentWidth: number;
      contentHeight: number;
    }
  | {
      type: ZoomPanActionType.ZOOM_DEFAULT;
      zoomFactor: number;
      containerRect: DOMRect;
    }
  | {
      type: ZoomPanActionType.SET_ZOOMPAN_SIZE;
      width: number;
      height: number;
    }
  | {
      type: ZoomPanActionType.RESET;
    };

export const startPan = (event: React.MouseEvent): ZoomPanAction => ({
  type: ZoomPanActionType.PAN_START,
  clientX: event.clientX,
  clientY: event.clientY,
});

export const pan = (
  event: MouseEvent,
  containerRect: DOMRect,
  contentWidth: number,
  contentHeight: number
): ZoomPanAction => ({
  type: ZoomPanActionType.PAN,
  clientX: event.clientX,
  clientY: event.clientY,
  containerRect,
  contentWidth,
  contentHeight,
});

export const trackpadPan = (
  event: React.WheelEvent,
  containerRect: DOMRect,
  contentWidth: number,
  contentHeight: number
): ZoomPanAction => ({
  type: ZoomPanActionType.PAN,
  clientX: event.clientX,
  clientY: event.clientY,
  deltaX: -event.deltaX,
  deltaY: -event.deltaY,
  containerRect,
  contentWidth,
  contentHeight,
});

export const mobilePan = (
  containerRect: DOMRect,
  contentWidth: number,
  contentHeight: number,
  deltaX: number,
  deltaY: number,
  clientX: number,
  clientY: number
): ZoomPanAction => ({
  type: ZoomPanActionType.PAN,
  deltaX,
  deltaY,
  clientX,
  clientY,
  containerRect,
  contentWidth,
  contentHeight,
});

export const zoom = (
  event: React.WheelEvent,
  containerRect: DOMRect,
  contentWidth: number,
  contentHeight: number,
  zoomFactor: number = DEFAULT_ZOOM_FACTOR
): ZoomPanAction => {
  const zoomFactorIn = 1 + zoomFactor;
  const zoomFactorOut = 1 - zoomFactor;
  return {
    type: ZoomPanActionType.ZOOM,
    zoomFactor: event.deltaY < 0 ? zoomFactorIn : zoomFactorOut,
    clientX: event.clientX,
    clientY: event.clientY,
    containerRect,
    contentWidth,
    contentHeight,
  };
};

export const smartZoom = (
  clientX: number,
  clientY: number,
  containerRect: DOMRect,
  contentWidth: number,
  contentHeight: number,
  zoomFactor: number = DEFAULT_ZOOM_FACTOR
): ZoomPanAction => ({
  type: ZoomPanActionType.ZOOM,
  zoomFactor,
  clientX,
  clientY,
  containerRect,
  contentWidth,
  contentHeight,
});

export const mobileZoom = (
  containerRect: DOMRect,
  contentWidth: number,
  contentHeight: number,
  zoomFactor: number = DEFAULT_ZOOM_FACTOR,
  clientX: number,
  clientY: number
): ZoomPanAction => {
  return {
    type: ZoomPanActionType.ZOOM,
    zoomFactor,
    clientX,
    clientY,
    containerRect,
    contentWidth,
    contentHeight,
  };
};

// zoom triggered with something else than wheel event
export const zoomDefault = (
  deltaY: number,
  containerRect: DOMRect,
  zoomFactor: number = DEFAULT_ZOOM_FACTOR
): ZoomPanAction => {
  const zoomFactorIn = 1 + zoomFactor;
  const zoomFactorOut = 1 - zoomFactor;
  return {
    type: ZoomPanActionType.ZOOM_DEFAULT,
    zoomFactor: deltaY < 0 ? zoomFactorIn : zoomFactorOut,
    containerRect,
  };
};

export const setIsActive = (isActive: boolean): ZoomPanAction => ({
  type: ZoomPanActionType.SET_ZOOMPAN_IS_ACTIVE,
  isActive,
});

const reducer = (state: ZoomPanState, action: ZoomPanAction) => {
  switch (action.type) {
    case ZoomPanActionType.SET_ZOOMPAN_IS_ACTIVE: {
      return {
        ...state,
        isActive: action.isActive,
      };
    }

    case ZoomPanActionType.PAN_START: {
      return {
        ...state,
        prevMouseX: action.clientX,
        prevMouseY: action.clientY,
      };
    }

    case ZoomPanActionType.PAN: {
      const deltaMouseX =
        typeof action.deltaX !== 'undefined' ? action.deltaX : action.clientX - state.prevMouseX;
      const deltaMouseY =
        typeof action.deltaY !== 'undefined' ? action.deltaY : action.clientY - state.prevMouseY;

      let translateX = state.translateX + deltaMouseX;
      let translateY = state.translateY + deltaMouseY;

      // the widthGutter is the horizontal translation necessary
      // to prevent panning outside the bounding box of the contained block
      const widthGutter = (action.contentWidth * (state.scale - 1)) / 2;
      if (translateX > 0) {
        translateX = Math.min(translateX, widthGutter);
      } else if (translateX < 0) {
        translateX = Math.max(translateX, -widthGutter);
      }

      // same as widthGutter but vertical
      const heightGutter = (action.contentHeight * (state.scale - 1)) / 2;
      if (translateY > 0) {
        translateY = Math.min(translateY, heightGutter);
      } else if (translateY < 0) {
        translateY = Math.max(translateY, -heightGutter);
      }

      return {
        ...state,
        translateX,
        translateY,
        prevMouseX: action.clientX,
        prevMouseY: action.clientY,
      };
    }

    case ZoomPanActionType.ZOOM: {
      if (state.scale * action.zoomFactor < ZOOM_FACTOR_MIN) {
        return {
          ...state,
          scale: ZOOM_FACTOR_MIN,
          translateX: 0,
          translateY: 0,
        };
      } else if (state.scale * action.zoomFactor > ZOOM_FACTOR_MAX) {
        const zoomFactor = ZOOM_FACTOR_MAX / state.scale;
        const scaledTranslate = getScaledTranslate(state, zoomFactor);
        const mousePositionOnScreen = { x: action.clientX, y: action.clientY };
        const zoomOffset = getZoomOffset(action.containerRect, mousePositionOnScreen, zoomFactor);
        return {
          ...state,
          scale: ZOOM_FACTOR_MAX,
          translateX: scaledTranslate.x - zoomOffset.x,
          translateY: scaledTranslate.y - zoomOffset.y,
        };
      }

      const scaledTranslate = getScaledTranslate(state, action.zoomFactor);
      const mousePositionOnScreen = { x: action.clientX, y: action.clientY };
      const zoomOffset = getZoomOffset(
        action.containerRect,
        mousePositionOnScreen,
        action.zoomFactor
      );

      let translateX = scaledTranslate.x - zoomOffset.x;
      let translateY = scaledTranslate.y - zoomOffset.y;

      const widthGutter = (action.contentWidth * (action.zoomFactor * state.scale - 1)) / 2;
      const heightGutter = (action.contentHeight * (action.zoomFactor * state.scale - 1)) / 2;

      if (translateX > 0) {
        translateX = Math.min(translateX, widthGutter);
      } else if (translateX < 0) {
        translateX = Math.max(translateX, -widthGutter);
      }

      if (translateY > 0) {
        translateY = Math.min(translateY, heightGutter);
      } else if (translateY < 0) {
        translateY = Math.max(translateY, -heightGutter);
      }

      return {
        ...state,
        scale: state.scale * action.zoomFactor,
        translateX,
        translateY,
      };
    }

    case ZoomPanActionType.ZOOM_DEFAULT: {
      let zoomFactor = action.zoomFactor;
      if (state.scale * zoomFactor < ZOOM_FACTOR_MIN) {
        zoomFactor = ZOOM_FACTOR_MIN / state.scale;
      } else if (state.scale * zoomFactor > ZOOM_FACTOR_MAX) {
        zoomFactor = ZOOM_FACTOR_MAX / state.scale;
      }

      return {
        ...state,
        scale: state.scale * zoomFactor,
        translateX: state.translateX * zoomFactor,
        translateY: state.translateY * zoomFactor,
      };
    }

    case ZoomPanActionType.SET_ZOOMPAN_SIZE:
      return {
        ...state,
        width: action.width,
        height: action.height,
      };

    case ZoomPanActionType.RESET: {
      return {
        ...state,
        scale: 1,
        translateX: 0,
        translateY: 0,
      };
    }

    default: {
      return state;
    }
  }
};

const getZoomOffset = (
  containerRect: DOMRect,
  mousePositionOnScreen: Coordinates,
  zoomFactor: number
): Coordinates => {
  const zoomOrigin = {
    x: mousePositionOnScreen.x - containerRect.x,
    y: mousePositionOnScreen.y - containerRect.y,
  };

  const currentDistanceToCenter = {
    x: containerRect.width / 2 - zoomOrigin.x,
    y: containerRect.height / 2 - zoomOrigin.y,
  };

  const scaledDistanceToCenter = {
    x: currentDistanceToCenter.x * zoomFactor,
    y: currentDistanceToCenter.y * zoomFactor,
  };

  const zoomOffset = {
    x: currentDistanceToCenter.x - scaledDistanceToCenter.x,
    y: currentDistanceToCenter.y - scaledDistanceToCenter.y,
  };

  return zoomOffset;
};

const getScaledTranslate = (state: ZoomPanState, zoomFactor: number) => ({
  x: state.translateX * zoomFactor,
  y: state.translateY * zoomFactor,
});

export default { reducer, initialState } as const;
