import { useUpdateMyPresence } from '@liveblocks/react';
import { EventEmitter } from 'eventemitter3';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Twilio, {
  AudioTrack,
  createLocalAudioTrack,
  createLocalVideoTrack,
  LocalDataTrack,
  LocalVideoTrack,
  RemoteAudioTrack,
  RemoteParticipant,
  RemoteTrack,
  RemoteTrackPublication,
  RemoteVideoTrack,
  Room,
} from 'twilio-video';

import {
  DataHandlerEvents,
  DataMessage,
  DataMessages,
  DataState,
  IDataHandler,
  RemoteVideoTrackWithUser,
  ScreenShareActive,
  TwilioContextData,
} from '../types/twilio';
import { useLogEvent } from './actions';
import { useDebug } from './debug';
import { ErrorStatusType, useErrorStatus } from './error';
import log from './log';
import { Presence, useMembers, useSelf } from './member';

// Change this constant to change the microphone threshold. This is should be a temporary constant.
// const MICROPHONE_THRESHOLD = 0.004;

const supportsKrisp = () => {
  // if iOS or Safari return false, else return true
  return !(
    navigator.userAgent.match(/(iPod|iPhone|iPad)/) ||
    /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
  );
};

const TwilioContext = React.createContext<TwilioContextData>({} as TwilioContextData);
const useTwilio = () => React.useContext(TwilioContext);

const useTwilioSelf = (): Twilio.LocalParticipant | null => {
  const { room } = useTwilio();
  return room?.localParticipant || null;
};

const useTwilioOthers = (): Twilio.RemoteParticipant[] => {
  const { participants } = useTwilio();
  return participants || [];
};

const useMicrophone = (): { muted: boolean; setMuted: (muted: boolean) => void } => {
  const { microphone } = useTwilio();
  return microphone;
};

const useCamera = (): { active: boolean; setActive: (active: boolean) => void } => {
  const { camera } = useTwilio();
  return camera;
};

class DataHandler extends EventEmitter<DataHandlerEvents> implements IDataHandler {
  private state: DataState = 'idle';
  private track: LocalDataTrack | null = null;
  private room: Room | null = null;
  private userSid: string | null = null;
  private timer: number = 0;

  private activeUserSid: string | null = null;
  private activeTrackSid: string | null = null;
  private localTrack: LocalVideoTrack | null = null;
  private localStream: MediaStream | null = null;

  public get ActiveUserSid(): string | null {
    return this.activeUserSid;
  }

  public get ActiveTrackSid(): string | null {
    return this.activeTrackSid;
  }

  public get LocalVideoTrack(): LocalVideoTrack | null {
    return this.localTrack;
  }

  // No dependencies so we can simply memoize this object.
  constructor() {
    super();
    this.state = 'idle';

    // If we leave the window, we need to stop screensharing.
    window.addEventListener('beforeunload', () => {
      if (this.state === 'self-screenshare') this.stopScreenShare();
    });
  }

  private sendMessage = (message: DataMessage) => {
    const stringified = JSON.stringify(message);
    this.track?.send(stringified);
  };

  public setTrack(track: LocalDataTrack | null) {
    this.track = track;

    // send a scout message instantly if possible
    const scoutMessage: DataMessage = {
      sentBy: this.userSid ?? 'loading',
      type: DataMessages.SCOUT,
    };
    this.sendMessage(scoutMessage);

    // Set to scout_start if non-screensharing state
    if (this.state === 'idle' || this.state === 'scout') this.setState('scout_start');
  }

  public setRoom(room: Room | null) {
    this.room = room;
    this.userSid = room?.localParticipant?.sid || null;

    // Set to scout_start if non-screensharing state
    if (this.state === 'idle' || this.state === 'scout') this.setState('scout_start');
  }

  private clearTracks() {
    this.localStream?.getTracks().forEach((track) => track.stop());

    if (!this.room) return;

    this.room?.localParticipant.tracks.forEach((publication) => {
      if (publication?.track.name.startsWith('screen') && publication?.track.kind === 'video') {
        publication.track.stop();
        this.room?.localParticipant.unpublishTrack(publication.track);
      }
    });
  }

  public setState(state: DataState, senderSid?: string) {
    if (this.state === 'f2f' && state !== 'f2f') {
      this.emit('toggleF2F', false, senderSid);
    } else if (state === 'f2f' && this.state !== 'f2f') {
      this.emit('toggleF2F', true, senderSid);
    }

    this.state = state;
    this.emit(
      'active',
      state === 'other-screenshare' ? 'remote' : state === 'self-screenshare' ? 'local' : null
    );

    if (state !== 'scout_start' && state !== 'idle') {
      clearTimeout(this.timer);
      this.timer = 0;
    }

    switch (state) {
      case 'scout_start': {
        if (!this.userSid) {
          this.setState('idle');
          return;
        }

        const message: DataMessage = {
          sentBy: this.userSid,
          type: DataMessages.SCOUT,
        };

        this.sendMessage(message);
        this.setState('scout');

        // Timeout
        this.timer = window.setTimeout(() => {
          if (this.state !== 'scout') return;
          this.setState('idle');
        }, 6000);
        return;
      }
      case 'idle': {
        this.timer = window.setTimeout(() => {
          if (this.state !== 'idle') return;
          this.setState('scout_start');
        }, 30000);
        this.clearTracks();
      }
    }
  }

  public handleMessage(message: DataMessage) {
    if (message.sentBy === this.userSid) return;
    if (message.type !== DataMessages.SCOUT_RESPONSE && !this.userSid) return;

    switch (message.type) {
      case DataMessages.TOGGLE_F2F:
        if (!message.value && this.state === 'f2f') {
          this.setState('idle', message.sentBy);
          return;
        }

        this.stopScreenShare();
        this.setState('f2f', message.sentBy);
        return;
      case DataMessages.SCOUT: {
        if (
          this.state === 'scout' ||
          this.state === 'scout_start' ||
          this.state === 'idle' ||
          !this.userSid
        )
          return;

        // Only answer that screenshare is active or not if we are the ones who started the screenshare.
        // otherwise, we say no screenshare is active.
        // This is because the scout's response is absolute, and if the user that started the screenshare
        // leaves but no one updates this will cause everyone to think that the screenshare is active.

        const message: DataMessage = {
          sentBy: this.userSid,
          type: DataMessages.SCOUT_RESPONSE,
          active: this.state === 'self-screenshare',
          trackSid: this.state === 'self-screenshare' ? this.activeTrackSid : null,
          userSid: this.state === 'self-screenshare' ? this.activeUserSid : null,
          f2f: this.state === 'f2f',
        };

        // This if statement is to avoid race conditions.
        if (this.state === 'self-screenshare' || this.state === 'f2f') {
          this.sendMessage(message);
        }
        return;
      }
      case DataMessages.SCOUT_RESPONSE: {
        if (this.state !== 'scout' && this.state !== 'error') return;

        const { active, trackSid, userSid, f2f, sentBy } = message;

        if (f2f) {
          this.setState('f2f', sentBy);
          return;
        }

        if (!active || sentBy !== userSid) {
          this.setState('idle', sentBy);
          this.emit('trackChanged', null);
          return;
        }

        this.activeTrackSid = trackSid;
        this.activeUserSid = userSid;
        this.localStream = null;
        this.localTrack = null;
        this.setState('other-screenshare', sentBy);
        return;
      }
      case DataMessages.START_SCREENSHARE: {
        if (this.activeTrackSid === message.trackSid && this.activeUserSid === message.userSid)
          return;

        if (this.state === 'other-screenshare' || this.state === 'self-screenshare') {
          this.emit('takeOver', message.userSid);
        }

        this.clearTracks();
        this.localStream = null;
        this.localTrack = null;

        this.activeTrackSid = message.trackSid;
        this.activeUserSid = message.userSid;
        this.setState('other-screenshare', message.sentBy);
        return;
      }
      case DataMessages.STOP_SCREENSHARE:
        if (this.state === 'other-screenshare' || this.state === 'self-screenshare') {
          this.emit('forceStop', message.sentBy);
        }

        this.activeTrackSid = null;
        this.activeUserSid = null;
        if (this.state !== 'f2f') this.setState('idle', message.sentBy);
        this.clearTracks();
        return;
    }
  }

  public async startScreenShare() {
    if (!this.room || !this.userSid) return;

    try {
      const captureStream = await navigator.mediaDevices.getDisplayMedia();

      const screenTrack = new LocalVideoTrack(captureStream.getTracks()[0], {
        name: 'screen-' + Math.random().toString(36).substr(2, 9),
        logLevel: 'warn',
      });

      // When the user stops the screen share, we need to stop the track
      captureStream.getVideoTracks()[0].onended = () => {
        this.stopScreenShare();
      };

      this.clearTracks();

      // Add to local participant
      const publication = await this.room?.localParticipant.publishTrack(screenTrack);

      if (!publication) throw new Error('Failed to publish track');

      this.localStream = captureStream;
      this.localTrack = publication.track as LocalVideoTrack;

      this.activeTrackSid = publication.trackSid;
      this.activeUserSid = this.userSid;

      const message: DataMessage = {
        sentBy: this.userSid,
        type: DataMessages.START_SCREENSHARE,
        trackSid: publication.trackSid,
        userSid: this.userSid,
      };
      this.sendMessage(message);
      this.setState('self-screenshare', this.userSid);
    } catch (e) {
      log('error', e);
      // Loop through localParticipant.tracks and unpublish them if contains name 'screen'
      this.clearTracks();

      if (this.state === 'self-screenshare') {
        this.sendMessage({
          sentBy: this.userSid,
          type: DataMessages.STOP_SCREENSHARE,
        });
      }
    }
  }

  public stopScreenShare() {
    this.clearTracks();

    this.activeTrackSid = null;
    this.activeUserSid = null;
    this.localStream = null;
    this.localTrack = null;
    this.setState('idle', this.userSid ?? undefined);

    if (!this.userSid) return;
    const message: DataMessage = {
      sentBy: this.userSid,
      type: DataMessages.STOP_SCREENSHARE,
    };
    this.sendMessage(message);
  }

  public stopF2F() {
    if (!this.userSid) return;
    if (this.state === 'f2f') this.setState('idle', this.userSid);
    this.emit('toggleF2F', false);

    this.sendMessage({
      sentBy: this.userSid,
      type: DataMessages.TOGGLE_F2F,
      value: false,
    });
  }

  public startF2F() {
    if (!this.userSid) return;
    this.setState('f2f', this.userSid);
    this.emit('toggleF2F', true);

    this.sendMessage({
      sentBy: this.userSid,
      type: DataMessages.TOGGLE_F2F,
      value: true,
    });
  }

  public scout() {
    if (this.state !== 'idle') return;
    this.setState('scout_start', this.userSid ?? undefined);
  }
}

const useDataHandler = () => {
  const [active, setActive] = useState<ScreenShareActive>(null);
  const [f2f, setF2F] = useState<boolean>(false);
  const dataHandler = useMemo(() => new DataHandler(), []);

  useEffect(() => {
    dataHandler.on('active', (active: ScreenShareActive) => {
      setActive(active);
    });
    dataHandler.on('toggleF2F', setF2F);

    return () => {
      dataHandler.off('active');
      dataHandler.off('toggleF2F', setF2F);
    };
  }, [dataHandler]);

  const toggleFaceToFace = useCallback(() => {
    if (f2f) {
      dataHandler.stopF2F();
      return;
    }

    dataHandler.startF2F();
  }, [f2f, dataHandler]);

  return {
    active,
    dataHandler,
    faceToFace: f2f,
    toggleFaceToFace,
  } as const;
};

export interface TwilioProviderProps {
  roomName: string;
  children: React.ReactNode;
}

const TwilioProvider: React.FC<TwilioProviderProps> = ({ roomName, children }) => {
  const [token, setToken] = useState<string | null>(null);
  const [room, setRoom] = React.useState<Twilio.Room | null>(null);
  const [participants, setParticipants] = React.useState<Twilio.RemoteParticipant[]>([]);
  // Microphone muted, by default yes
  const [muted, setMuted] = React.useState(true);
  const [cameraActive, setCameraActive] = React.useState(false);
  const [cameraRequested, setCameraRequested] = React.useState(false);
  const [microphoneRequested, setMicrophoneRequested] = React.useState(false);
  const { addTrigger, removeTrigger, setStatus: setDebugStatus, debugPanelOpen } = useDebug();
  const [reconnections, setReconnections] = React.useState(0);

  useEffect(() => {
    setReconnections((prev) => prev + 1);
  }, [room]);

  const logEvent = useLogEvent();

  const [, setStatus] = useErrorStatus();

  const dataHandlerHolder = useDataHandler();
  const dataHandler = dataHandlerHolder.dataHandler;

  // TODO: This is a temporary solution to setup the twilio SID on liveblocks. This should definitely be separate from twilio code.
  const updateMyPresence = useUpdateMyPresence<Presence>();

  const faceToFace = dataHandlerHolder.faceToFace;
  const toggleFaceToFace = dataHandlerHolder.toggleFaceToFace;

  useEffect(() => {
    dataHandler.setRoom(room);
    return () => {
      dataHandler.setRoom(null);
    };
  }, [room, dataHandler]);

  const [userMedia, setUserMedia] = React.useState<MediaStream | null>(null);
  const [videoTrack, setVideoTrack] = useState<LocalVideoTrack | null>(null);

  const handleSetMuted = useCallback(
    async (value: boolean) => {
      if (!room) return;

      if (value) {
        room.localParticipant.audioTracks.forEach((publication) => publication.track.disable());
        setMuted(true);

        updateMyPresence({
          microphoneMuted: true,
        });

        logEvent('USER_HAS_MUTED');
      } else {
        if (!microphoneRequested) {
          setMicrophoneRequested(true);
          // Change muted instantly for user feedback
          // If it fails we change back to muted
          setMuted(false);

          try {
            const localAudioTrack = await createLocalAudioTrack({
              noiseCancellationOptions: {
                // This folder is hosted in the /public folder as it needs to be hosted together with the app
                sdkAssetsPath: '/krisp-1.0.0',
                vendor: 'krisp',
              },

              // disable noiseSuppression only if the browser is not Safari or iOS
              noiseSuppression: !supportsKrisp(),
            });

            const audioStream = new MediaStream([localAudioTrack.mediaStreamTrack]);
            room.localParticipant.publishTrack(localAudioTrack);
            setUserMedia(audioStream);
            setStatus('microphone', ErrorStatusType.SUCCESS);
          } catch (error) {
            log('error', 'Unable to get microphone access:', error);
            setMuted(true);
            setStatus('microphone', ErrorStatusType.FAILED);
            return;
          }
        }

        setMuted(false);
        room.localParticipant.audioTracks.forEach((publication) => publication.track.enable());

        updateMyPresence({
          microphoneMuted: false,
        });

        logEvent('USER_HAS_UNMUTED');
      }
    },
    [room, updateMyPresence, logEvent, microphoneRequested, setStatus]
  );

  const handleSetCameraActive = useCallback(
    async (value: boolean) => {
      if (!room) return;

      if (!value) {
        // Completely disable the camera
        room.localParticipant.videoTracks.forEach((publication) => {
          publication.track.stop();
          room.localParticipant.unpublishTrack(publication.track);
        });

        setStatus('camera', ErrorStatusType.LOADING);
        setCameraActive(false);
        setCameraRequested(false);
        logEvent('USER_HAS_DISABLED_WEBCAM');
        return;
      }

      if (!cameraRequested) {
        try {
          const videoTrack = await createLocalVideoTrack({
            facingMode: 'user',
            width: faceToFace ? 1280 : 200,
            height: faceToFace ? 720 : 200,
            frameRate: 24,
          });

          room.localParticipant.publishTrack(videoTrack);

          setVideoTrack(videoTrack);

          setCameraRequested(true);
          setStatus('camera', ErrorStatusType.SUCCESS);
        } catch (error) {
          log('error', 'Unable to get camera access:', error);
          setStatus('camera', ErrorStatusType.FAILED);
          return;
        }
      }

      room.localParticipant.videoTracks.forEach((publication) => publication.track.enable());
      setCameraActive(true);
      logEvent('USER_HAS_ENABLED_WEBCAM');
    },
    [room, cameraRequested, logEvent, setStatus, faceToFace]
  );

  useEffect(() => {
    if (!room) return;
    const reapplyConstraints = () => {
      const videos = Array.from(room.localParticipant.videoTracks.values()).filter(
        (t) => !t.trackName.startsWith('screen')
      );

      const pub = videos?.[0] as any; // have to cast as any so we can use _signaling property
      if (!pub) {
        // can still be null if no video published yet
        // Listen to track events to reapply constraints, since the user might enable the camera at the same time
        // as the face to face mode and cause it to miss the correct constraints
        room.localParticipant.once('trackPublished', reapplyConstraints);
        return;
      }

      // Disable the face to face video track
      // Dynamically changes the constraints without having to switch streams.
      // available in 96% of browsers.

      const track = pub._signaling?._trackTransceiver?.track as MediaStreamTrack | null;
      if (!track) return;

      if (faceToFace) {
        track.applyConstraints({
          width: 1280,
          height: 720,
        });
      } else {
        pub._signaling._trackTransceiver.track.applyConstraints({
          width: 200,
          height: 200,
        });
      }
    };

    reapplyConstraints();

    return () => {
      room.localParticipant.off('trackPublished', reapplyConstraints);
    };
  }, [faceToFace, room]);

  // Clear out userMedia if we lost our microphone request.
  useEffect(() => {
    if (!microphoneRequested) {
      setUserMedia((userMedia) => {
        userMedia?.getAudioTracks().forEach((track) => track.stop());
        return null;
      });

      setMuted(true);
    }

    if (!cameraRequested) {
      setVideoTrack((videoTrack) => {
        if (videoTrack) videoTrack.stop();
        return null;
      });

      setCameraActive(false);
    }
  }, [microphoneRequested, cameraRequested]);

  const cleanup = useCallback(
    (room: Room | null) => {
      if (room) {
        room.removeAllListeners();

        room.localParticipant.videoTracks.forEach((publication) => {
          publication.track.stop();
          room.localParticipant.unpublishTrack(publication.track);
        });
        room.localParticipant.audioTracks.forEach((publication) => {
          publication.track.stop();
          room.localParticipant.unpublishTrack(publication.track);
        });
        room.disconnect();
      }

      setMuted(true);
      setCameraActive(false);
      setMicrophoneRequested(false);
      setCameraRequested(false);
      setStatus('microphone', ErrorStatusType.LOADING);
      setStatus('camera', ErrorStatusType.LOADING);
      setStatus('twilio', ErrorStatusType.LOADING);
      setParticipants([]);
      setRoom(null);
      dataHandler.stopScreenShare();
      dataHandler.setTrack(null);
      // Clear microphone, camera and screenshare tracks/streams/permissions.
      setUserMedia((userMedia: MediaStream | null) => {
        userMedia?.getTracks().forEach((track) => track.stop());
        return null;
      });

      setVideoTrack((track) => {
        track?.stop();
        return null;
      });
    },
    [setStatus, dataHandler]
  );

  const [forceReconnect, setForceReconnect] = useState(false);
  useEffect(() => {
    if (!token) {
      setRoom(null);
      setParticipants([]);
      return;
    }

    // This allows to disconnect without adding room as a dependency to the useEffect. Essentially avoiding it looping.
    let _room: Twilio.Room | null = null;
    const data = new LocalDataTrack();
    setForceReconnect(false);

    Twilio.connect(token, {
      name: roomName,
      audio: false,
      video: false,

      // Recommended settings by Twilio.
      // ref: https://www.twilio.com/docs/video/tutorials/developing-high-quality-video-applications#grid-mode
      bandwidthProfile: {
        video: {
          mode: 'grid',
        },
      },
      preferredVideoCodecs: [{ codec: 'VP8', simulcast: true }],

      tracks: [data],
    }).then(
      (room) => {
        setStatus('twilio', ErrorStatusType.SUCCESS);
        room.removeAllListeners();
        setRoom(room);
        _room = room;

        _room.localParticipant?.on('trackPublished', (publication) => {
          if (publication.track !== data) return;
          dataHandler.setTrack(publication.track);
        });
      },
      (error) => {
        log('error', 'Error connecting to Twilio:', error);
        setStatus('twilio', ErrorStatusType.FAILED);
        dataHandler.setTrack(null);
      }
    );

    return () => {
      cleanup(_room);
    };
  }, [forceReconnect, token, roomName, setStatus, dataHandler, cleanup]);

  // Separate events from room setting to avoid looping new connections.
  useEffect(() => {
    if (!room) return;

    const addAudioTrack = (track: RemoteAudioTrack) => {
      setAudioTracks((audioTracks) => [...audioTracks, track]);
    };
    const removeAudioTrack = (track: RemoteAudioTrack) => {
      setAudioTracks((audioTracks) => audioTracks.filter((t) => t.sid !== track.sid));
    };

    const addVideoTrack = (track: RemoteVideoTrack, userSid: string) => {
      const newTrack = track as RemoteVideoTrackWithUser;
      newTrack.userSid = userSid;
      setVideoTracks((videoTracks) => [...videoTracks, newTrack]);
    };

    const removeVideoTrack = (track: RemoteVideoTrack) => {
      setVideoTracks((videoTracks) => videoTracks.filter((t) => t.sid !== track.sid));
    };

    const onMessage = (rawMessage: string | ArrayBuffer) => {
      if (typeof rawMessage !== 'string') return;
      const message = JSON.parse(rawMessage);
      if (!('type' in message)) return;

      dataHandler.handleMessage(message as DataMessage);
    };
    const subscribed = (
      track: RemoteTrack,
      _: RemoteTrackPublication,
      participant: RemoteParticipant
    ) => {
      if (track.kind === 'video') addVideoTrack(track, participant.sid);
      else if (track.kind === 'audio') addAudioTrack(track as RemoteAudioTrack);
      else track.on('message', onMessage);
    };

    const unsubscribed = (track: RemoteTrack) => {
      if (track.kind === 'video') removeVideoTrack(track as RemoteVideoTrack);
      else if (track.kind === 'audio') removeAudioTrack(track as RemoteAudioTrack);
      else track.off('message', onMessage);
    };

    const onParticipantConnected = (participant: Twilio.RemoteParticipant) => {
      setParticipants((prevParticipants) => [...prevParticipants, participant]);
    };

    const onParticipantDisconnected = (participant: Twilio.RemoteParticipant) => {
      setParticipants((prevParticipants) =>
        prevParticipants.filter((p) => p.sid !== participant.sid)
      );
    };

    updateMyPresence({
      twilioSid: room.localParticipant.sid,
    });

    const onDisconnected = (_: any, error: any) => log('error', 'Twilio disconnected:', error);

    room.participants.forEach(onParticipantConnected);
    room.addListener('participantConnected', onParticipantConnected);
    room.addListener('participantDisconnected', onParticipantDisconnected);
    room.addListener('disconnected', onDisconnected);
    room.addListener('trackSubscribed', subscribed);
    room.addListener('trackUnsubscribed', unsubscribed);

    return () => {
      if (!room) return;

      updateMyPresence({
        twilioSid: null,
      });

      room.removeListener('participantConnected', onParticipantConnected);
      room.removeListener('participantDisconnected', onParticipantDisconnected);
      room.removeListener('disconnected', onDisconnected);
      room.removeListener('trackSubscribed', subscribed);
      room.removeListener('trackUnsubscribed', unsubscribed);
    };
  }, [dataHandler, room, updateMyPresence]);

  const debug = useDebug();
  const MICROPHONE_THRESHOLD = debug.variables.MICROPHONE_THRESHOLD;

  const [audioLevel, setAudioLevel] = useState(0);

  // Get our current audio output based on:
  // https://jameshfisher.com/2021/01/18/measuring-audio-volume-in-javascript/
  useEffect(() => {
    if (!room || !userMedia) return;

    // Let's hook into the local audio track to get the audio level
    const audioContext = new AudioContext();
    const source = audioContext.createMediaStreamSource(userMedia);
    const analyser = audioContext.createAnalyser();
    source.connect(analyser);

    let stop = false;
    let start: number;
    let wasTalking = false;

    const pcmData = new Float32Array(analyser.fftSize);
    const onFrame = (timestamp: number) => {
      // Throttle the update rate to 1 time per second because it sends a websocket message every frame.
      if (start === undefined) start = timestamp;
      if (timestamp - start < 300) {
        // This can be changed to a lower value if we want to animate based on the audio level.
        window.requestAnimationFrame(onFrame);
        return;
      } else start = timestamp;

      analyser.getFloatTimeDomainData(pcmData);
      let sumSquares = 0.0;
      for (const amplitude of Array.from(pcmData)) {
        sumSquares += amplitude * amplitude;
      }

      const audioLevel = Math.sqrt(sumSquares / pcmData.length);
      setAudioLevel(audioLevel);
      const talking = audioLevel > MICROPHONE_THRESHOLD;

      if (talking !== wasTalking) {
        // Avoid sending the same message multiple times.
        updateMyPresence({
          talking,
        });
        wasTalking = talking;
      }

      if (!stop) window.requestAnimationFrame(onFrame);
    };
    window.requestAnimationFrame(onFrame);

    return () => {
      stop = true;
      audioContext.close();
    };
  }, [room, microphoneRequested, userMedia, updateMyPresence, MICROPHONE_THRESHOLD]);

  useEffect(() => {
    addTrigger('twilio_microphone_fail', () => {
      setStatus('microphone', ErrorStatusType.FAILED);
    });
    addTrigger('twilio_microphone_success', () => {
      setStatus('microphone', ErrorStatusType.SUCCESS);
    });
    addTrigger('twilio_fail', () => {
      setStatus('twilio', ErrorStatusType.FAILED);
    });
    addTrigger('twilio_success', () => {
      setStatus('twilio', ErrorStatusType.SUCCESS);
    });
    addTrigger('twilio_audio_fail', () => {
      setStatus('audio', ErrorStatusType.FAILED);
    });
    addTrigger('twilio_audio_success', () => {
      setStatus('audio', ErrorStatusType.SUCCESS);
    });
    addTrigger('twilio_camera_fail', () => {
      setStatus('camera', ErrorStatusType.FAILED);
    });
    addTrigger('twilio_camera_success', () => {
      setStatus('camera', ErrorStatusType.SUCCESS);
    });
    addTrigger('twilio_force_reconnect', () => {
      setForceReconnect(true);
    });
    addTrigger('twilio_unrequest_microphone', () => {
      setMicrophoneRequested(false);
    });

    return () => {
      removeTrigger('twilio_microphone_fail');
      removeTrigger('twilio_microphone_success');
      removeTrigger('twilio_fail');
      removeTrigger('twilio_success');
      removeTrigger('twilio_audio_fail');
      removeTrigger('twilio_audio_success');
      removeTrigger('twilio_camera_fail');
      removeTrigger('twilio_camera_success');
      removeTrigger('twilio_force_reconnect');
      removeTrigger('twilio_unrequest_microphone');
    };
  }, [addTrigger, removeTrigger, setStatus]);

  const [audioTracks, setAudioTracks] = useState<Twilio.RemoteAudioTrack[]>([]);
  const [videoTracks, setVideoTracks] = useState<RemoteVideoTrackWithUser[]>([]);

  const value = {
    room,
    participants,
    audioTracks,
    videoTracks,
    microphone: { muted, setMuted: handleSetMuted },
    camera: { active: cameraActive, setActive: handleSetCameraActive, track: videoTrack },
    connect: setToken,
    screenshare: {
      active: dataHandlerHolder.active,
      dataHandler: dataHandlerHolder.dataHandler,
    },
    faceToFace,
    toggleFaceToFace,
  } as TwilioContextData;

  const [localAudioTracks, setLocalAudioTracks] = useState<Twilio.LocalAudioTrack[]>([]);
  useEffect(() => {
    if (!room) return;

    const updateTracks = () => {
      const audioTracks = Array.from(room.localParticipant.audioTracks.values()).map(
        (publication) => publication.track
      );
      setLocalAudioTracks(audioTracks);
    };

    updateTracks();

    room.localParticipant.on('trackPublished', updateTracks);
    room.localParticipant.on('trackUnpublished', updateTracks);

    return () => {
      room.localParticipant.off('trackPublished', updateTracks);
      room.localParticipant.off('trackUnpublished', updateTracks);
    };
  }, [room]);

  useEffect(() => {
    if (!debugPanelOpen) return;

    setDebugStatus('Twilio', {
      Reconnections: reconnections,
      Room: room?.sid,
      'Self SID': room?.localParticipant.sid,
      'Participants Size': participants.length,
      Participants: participants.map((p) => p.sid),
      'Audio Tracks': audioTracks.length,
      'Video Tracks': videoTracks.length,
      Muted: muted,
      'Camera Active': cameraActive,
      'Camera Requested': cameraRequested,
      'Microphone Requested': microphoneRequested,
      'Screenshare Active': dataHandlerHolder.active,
      'Face to Face': faceToFace,
      'Local Audio Tracks Size': localAudioTracks.length,
      'Local Audio Tracks': localAudioTracks.map((t) => t.id),
    });
  }, [
    reconnections,
    cameraActive,
    cameraRequested,
    microphoneRequested,
    muted,
    participants,
    room?.sid,
    room?.localParticipant.sid,
    setDebugStatus,
    audioTracks.length,
    videoTracks.length,
    dataHandlerHolder.active,
    faceToFace,
    debugPanelOpen,
    localAudioTracks.length,
    localAudioTracks,
  ]);

  useEffect(() => {
    if (!debugPanelOpen) return;

    // audioLevel separate as it will trigger a lot
    setDebugStatus('Audio Level', audioLevel);
  }, [audioLevel, debugPanelOpen, setDebugStatus]);

  return <TwilioContext.Provider value={value}>{children}</TwilioContext.Provider>;
};

// Using a separate hook for audioTracks to force always having up-to-date data.
// As sometimes some audioTracks where left out and a participant would be unheard.
const useAudioTracks = (): AudioTrack[] => {
  const { audioTracks } = useTwilio();
  return audioTracks;
};

// Using a separate hook for videoTracks to force always having up-to-date data.
const useVideoTracks = (): RemoteVideoTrackWithUser[] => {
  const { videoTracks } = useTwilio();
  return videoTracks;
};

const useScreenShare = () => {
  const { screenshare, videoTracks } = useTwilio();
  const members = useMembers<Presence>(
    (param) => param === 'twilioSid' || param === 'userId' || param === 'name' || param === 'joined'
  );
  const self = useSelf<Presence>((param) => param === 'twilioSid' || param === 'userId');

  const track = useMemo(
    () =>
      screenshare.active === 'remote'
        ? videoTracks.find((track) => {
            return track.sid === screenshare.dataHandler.ActiveTrackSid;
          }) ?? null
        : screenshare.active === 'local'
        ? screenshare.dataHandler.LocalVideoTrack
        : null,
    [
      screenshare.active,
      screenshare.dataHandler.ActiveTrackSid,
      screenshare.dataHandler.LocalVideoTrack,
      videoTracks,
    ]
  );

  const user =
    screenshare.active === 'remote'
      ? members.find(
          (member) => member.presence?.twilioSid === screenshare.dataHandler.ActiveUserSid
        ) ?? null
      : screenshare.active === 'local'
      ? self ?? null
      : null;

  return {
    active: screenshare.active,
    track,
    user,
    dataHandler: screenshare.dataHandler,
  };
};

export {
  TwilioProvider,
  useTwilio,
  useTwilioOthers,
  useTwilioSelf,
  useMicrophone,
  useCamera,
  useAudioTracks,
  useVideoTracks,
  useScreenShare,
};
