import './CollectionMissing.scss';

import React from 'react';
import { Link } from 'react-router-dom';

import { ReactComponent as Fabric } from '../assets/images/icons/SimpleFabric.svg';
import { useAuth } from '../hooks/auth';
import DashboardButton from '../ui/DashboardButton/DashboardButton';

const CollectionMissing: React.FC = () => {
  const { isLoggedIn } = useAuth();

  return (
    <div className="collection_missing">
      <div className="collection_missing__header">
        <Fabric />
      </div>

      <div className="collection_missing__content">
        <h1>There’s no collection here :(</h1>
        <p>Maybe that’s your sign to make one?</p>
        <div>
          <Link
            to={isLoggedIn ? '/collections/new' : '/signin'}
            target="_self"
            rel="noopener noreferrer"
          >
            <DashboardButton size="large" color="primary">
              {isLoggedIn ? 'Create a collection on Fabric →' : 'Get started on Fabric →'}
            </DashboardButton>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default CollectionMissing;
