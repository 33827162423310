import { SimbaAction } from '.';

export enum QueueActionType {
  ADD = 'QUEUE:ADD',
  REMOVE = 'QUEUE:REMOVE',
  RESET = 'QUEUE:RESET',
}

export type QueueItem = {
  id: number;
  action: SimbaAction;
};

export type QueueState = {
  lastId: number;
  items: QueueItem[];
};

const initialState: QueueState = {
  lastId: 0,
  items: [],
};

export type QueueAction =
  | {
      type: QueueActionType.ADD;
      action: SimbaAction;
    }
  | {
      type: QueueActionType.REMOVE;
      id: number;
    }
  | {
      type: QueueActionType.RESET;
    };

const reducer = (state: QueueState, action: QueueAction): QueueState => {
  switch (action.type) {
    case QueueActionType.ADD:
      return {
        ...state,
        lastId: state.lastId + 1,
        items: [
          ...state.items,
          {
            id: state.lastId + 1,
            action: action.action,
          },
        ],
      };
    case QueueActionType.REMOVE:
      return {
        ...state,
        items: state.items.filter((item) => item.id !== action.id),
      };
    case QueueActionType.RESET:
      return initialState;
    default:
      return state;
  }
};

export default { reducer, initialState } as const;
