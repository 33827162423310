import EventEmitter from 'eventemitter3';

import log from '../hooks/log';
import { getPresenceCache } from '../hooks/member';
import analytics, { EventType } from '../services/analytics';
import { BaseClient, BaseEvents } from './base';
import { EVENT } from './events';
import {
  AdminLockMessage,
  AdminLockResource,
  AdminTargetPayload,
  BrowserFaviconMessage,
  BrowserPayload,
  BrowserTabNewPayload,
  BrowserTabUpdatePayload,
  ChatPayload,
  ControlClipboardPayload,
  ControlPayload,
  ControlTargetPayload,
  EmotePayload,
  MemberDisconnectPayload,
  MemberListPayload,
  MemberPayload,
  MouseCursorPayload,
  ScreenResolutionPayload,
  SystemInitPayload,
  SystemMessagePayload,
} from './messages';
import { ISimbaStore } from './store';
import { ChatActionType } from './store/chat';
import { ControlActionType } from './store/control';
import { MouseActionType } from './store/mouse';
import { RemoteActionType } from './store/remote';
import { StatusActionType } from './store/status';
import { UserActionType } from './store/user';
import { VideoActionType } from './store/video';
import { Member } from './types';

interface SimbaEvents extends BaseEvents {}

const clipboard_write_available = () => {
  // This will simply check if the clipboard window object is available.
  return 'clipboard' in navigator && typeof navigator.clipboard.writeText === 'function';
};

/**
 * This class will interact with a Simba server.
 * This will link all the needed events to the server so we can interact with the virtual browser.
 *
 * TODO: Keyboard layout.
 * TODO: Lock state.
 */
export class SimbaClient extends BaseClient implements EventEmitter<SimbaEvents> {
  private browser_url!: URL;
  private _connecting: boolean = false;
  private simbaStore: ISimbaStore = {} as ISimbaStore;
  private serviceId: string = '';
  private browserId: string = '';

  // Temporary FIX, a function to update the simbaStore.
  public setSimbaStore(simbaStore: ISimbaStore) {
    this.simbaStore = simbaStore;
  }

  init(browser_url: URL, browserId: string) {
    this.browser_url = browser_url;
    this.browserId = browserId;
  }

  /**
   * Log events to the analytics.
   * This is in the simba client to avoid duplicate requests, this way we hook to the events instead of value changes.
   */
  private logEvent(event: EventType) {
    if (!this.serviceId) return;

    analytics.logEvent(this.serviceId, event);
  }

  private cleanup() {
    this.setIsConnecting(false);

    // Reset all states.
    this.simbaStore.dispatch({ type: VideoActionType.RESET });
    this.simbaStore.dispatch({ type: UserActionType.RESET });
    this.simbaStore.dispatch({ type: RemoteActionType.RESET });
    this.simbaStore.dispatch({ type: ChatActionType.RESET });
    // Includes connection status.
    this.simbaStore.dispatch({ type: StatusActionType.RESET });
  }

  login(displayname: string) {
    this.connect(this.browser_url, displayname);
  }

  logout() {
    this.disconnect();
    this.cleanup();
    this.emit('info', 'connection.logged_out');
  }

  public get connecting(): boolean {
    return this._connecting;
  }

  /////////////////////////////
  // Connecting Management
  /////////////////////////////
  private setIsConnecting(connecting: boolean) {
    this.simbaStore.dispatch({
      type: StatusActionType.SET_CONNECTING,
      payload: connecting,
    });

    this._connecting = connecting;
  }

  protected [EVENT.MOUSE.CURSOR]({ value }: MouseCursorPayload) {
    if (!this.simbaStore.getters.remote.hosting) return;
    this.simbaStore.dispatch({
      type: MouseActionType.MOUSE_CURSOR,
      payload: value,
    });
  }

  /////////////////////////////
  // Internal Events
  /////////////////////////////
  protected [EVENT.RECONNECTING]() {
    this.emit('warn', 'connection.reconnecting');
  }

  protected [EVENT.CONNECTING_LONGER_THAN_EXPECTED]() {
    this.emit('warn', 'connection.connecting_longer_than_expected');
    this.simbaStore.dispatch({
      type: StatusActionType.SET_CONNECTING_LONGER_THAN_EXPECTED,
      payload: true,
    });
  }

  protected [EVENT.CONNECTING]() {
    this.setIsConnecting(true);
  }

  protected [EVENT.CONNECTED]() {
    this.simbaStore.dispatch({
      type: UserActionType.SET_MEMBER,
      payload: this.id,
    });

    this.simbaStore.dispatch({
      type: StatusActionType.SET_CONNECTED,
      payload: true,
    });

    this.setIsConnecting(false);
    // this.setIsConnectingLongerThanExpected(false);
    this.emit('info', 'connection.connected');
  }

  protected [EVENT.DISCONNECTED](reason?: Error) {
    this.cleanup();

    // Send error first so we can parse it before the info.
    if (reason) {
      this.emit('error', reason);
    }

    this.emit('info', 'connection.disconnected');
  }

  protected [EVENT.TRACK](event: RTCTrackEvent) {
    const { track, streams } = event;
    if (track.kind === 'audio') {
      return;
    }

    this.simbaStore.dispatch({
      type: VideoActionType.ADD_TRACK,
      payload: [track, streams[0]],
    });

    this.simbaStore.dispatch({
      type: VideoActionType.SET_STREAM,
      payload: 0,
    });
  }

  protected [EVENT.DATA]() {}

  /////////////////////////////
  // System Events
  /////////////////////////////
  protected [EVENT.SYSTEM.INIT]({
    service_id,
    implicit_hosting,
    locks,
    twilio_token,
  }: SystemInitPayload) {
    this.serviceId = service_id;

    // Update state
    this.simbaStore.dispatch({
      type: RemoteActionType.SET_SERVICE_ID,
      payload: service_id,
    });
    this.simbaStore.dispatch({
      type: RemoteActionType.SET_IMPLICIT_HOSTING,
      payload: implicit_hosting,
    });
    this.simbaStore.dispatch({
      type: RemoteActionType.SET_TWILIO_TOKEN,
      payload: twilio_token,
    });

    for (const resource in locks) {
      this[EVENT.ADMIN.LOCK]({
        event: EVENT.ADMIN.LOCK,
        resource: resource as AdminLockResource,
        id: locks[resource],
      });
    }
  }

  protected [EVENT.SYSTEM.DISCONNECT]({ message }: SystemMessagePayload) {
    if (message === 'kicked') {
      this.logout();
      message = 'connection.kicked';
    }
    if (message === 'Inactivity timeout') {
      message = 'connection.inactivity';
    }

    this.onDisconnected(new Error(message));
    this.emit('error', new Error(message));
  }

  protected [EVENT.SYSTEM.ERROR]({ message }: SystemMessagePayload) {
    this.emit('error', new Error(message));
  }

  protected [EVENT.SYSTEM.INACTIVITY]({ message }: SystemMessagePayload) {
    this.emit('info', 'connection.inactivity');

    this.simbaStore.dispatch({
      type: RemoteActionType.SET_INACTIVITY_TIMEOUT,
      timestamp: message ? Number(message) : null,
    });
  }

  /////////////////////////////
  // Browser Events
  /////////////////////////////
  protected [EVENT.BROWSER.RESET]() {
    this.simbaStore.dispatch({
      type: ControlActionType.RESET_BROWSER,
    });
  }

  protected [EVENT.BROWSER.UPDATE]({ windowId, changeInfo }: BrowserTabUpdatePayload) {
    this.simbaStore.dispatch({
      type: ControlActionType.UPDATE_TAB,
      changeInfo,
      windowId,
    });
  }

  protected [EVENT.BROWSER.NEW]({ windowId, url }: BrowserTabNewPayload) {
    this.simbaStore.dispatch({
      type: ControlActionType.UPDATE_TAB,
      changeInfo: {
        url,
      },
      windowId,
    });
  }

  protected [EVENT.BROWSER.FOCUS]({ windowId }: BrowserPayload) {
    this.simbaStore.dispatch({
      type: ControlActionType.SET_WINDOW_ID,
      windowId,
    });
  }

  protected [EVENT.BROWSER.CLOSED]({ windowId }: BrowserPayload) {
    this.simbaStore.dispatch({
      type: ControlActionType.CLOSED_TAB,
      windowId,
    });
  }

  protected [EVENT.BROWSER.FAVICON]({ favIcon, favIconUrl, pageUrl }: BrowserFaviconMessage) {
    this.simbaStore.dispatch({
      type: ControlActionType.SET_FAVICON_URL,
      data: favIcon,
      url: favIconUrl,
    });

    if (pageUrl) {
      this.simbaStore.dispatch({
        type: ControlActionType.SET_FAVICON_URL,
        data: favIcon,
        // Strip protocol and trim / from end.
        url: pageUrl.replace(/^https?:\/\//, '').replace(/\/$/, ''),
      });
    }
  }

  /////////////////////////////
  // Member Events
  /////////////////////////////
  protected [EVENT.MEMBER.LIST]({ members }: MemberListPayload) {
    const cache = getPresenceCache(this.browserId);

    const fullMembers = members.map((member) => {
      const { cache: userCache, presence } = cache
        ? cache[member.displayname]
        : { cache: undefined, presence: undefined };
      return {
        ...member,
        cache: userCache,
        presence,
      };
    });

    this.simbaStore.dispatch({
      type: UserActionType.SET_MEMBERS,
      payload: fullMembers,
    });

    this.sendMessage(EVENT.TAB.INFO);

    // TODO: translation, old: this.$vue.$t('notifications.connected', { name: '' }) as string
    this.simbaStore.dispatch({
      type: ChatActionType.ADD_MESSAGE,
      payload: {
        id: this.id,
        content: 'notifications.connected',
        type: 'event',
        created: new Date(),
      },
    });
  }

  protected [EVENT.MEMBER.CONNECTED](member: MemberPayload) {
    if (member.id !== this.id) {
      // If the displayname is equal to ours we disconnect (displayname contains the userId)
      if (member.displayname === this.simbaStore.getters.user.me?.displayname) {
        this.cleanup();
        this.onDisconnected(new Error('connection.double_login'));
      }

      this.simbaStore.dispatch({
        type: ChatActionType.ADD_MESSAGE,
        payload: {
          id: member.id,
          content: 'notifications.connected',
          type: 'event',
          created: new Date(),
        },
      });

      this.emit('info', 'notifications.connected');
    }

    const cache = getPresenceCache(this.browserId);
    log.debug('cache::updating_member_presence_new_member');
    this.simbaStore.dispatch({
      type: UserActionType.ADD_MEMBER,
      payload: {
        ...member,
        cache: cache ? cache[member.displayname]?.cache : undefined,
        presence: cache ? cache[member.displayname]?.presence : undefined,
      },
    });
  }

  protected [EVENT.MEMBER.DISCONNECTED]({ id }: MemberDisconnectPayload) {
    const member = this.member(id);

    if (!member) {
      return;
    }

    this.simbaStore.dispatch({
      type: ChatActionType.ADD_MESSAGE,
      payload: {
        id: member.id,
        content: 'notifications.disconnected',
        type: 'event',
        created: new Date(),
      },
    });

    this.emit('info', 'notifications.disconnected');

    this.simbaStore.dispatch({
      type: UserActionType.DEL_MEMBER,
      payload: id,
    });
  }

  /////////////////////////////
  // Control Events
  /////////////////////////////
  protected [EVENT.CONTROL.LOCKED]({ id }: ControlPayload) {
    this.simbaStore.dispatch({
      type: RemoteActionType.SET_HOST,
      payload: id,
    });

    //this.remote.changeKeyboard(); TODO:: Implement this

    const member = this.member(id);
    if (!member) {
      return;
    }

    if (this.id === id) {
      this.emit('info', 'notifications.controls_taken');
      this.logEvent('USER_HAS_TAKEN_CONTROL');
    } else {
      this.emit('notification', 'shock', 'has taken control.', member.displayname);
    }

    this.simbaStore.dispatch({
      type: ChatActionType.ADD_MESSAGE,
      payload: {
        id: member.id,
        content: 'notifications.controls_taken',
        type: 'event',
        created: new Date(),
      },
    });
  }
  protected [EVENT.CONTROL.RELEASE]({ id }: ControlPayload) {
    this.simbaStore.dispatch({
      type: RemoteActionType.RESET,
    });
    const member = this.member(id);
    if (!member) {
      return;
    }

    if (this.id === id) {
      this.emit('info', 'notifications.controls_released');
      this.logEvent('USER_HAS_RELEASED_CONTROL');
    } else {
      this.emit('notification', 'shock', 'has released control.', member.displayname);
    }

    this.simbaStore.dispatch({
      type: ChatActionType.ADD_MESSAGE,
      payload: {
        id: member.id,
        content: 'notifications.controls_released',
        type: 'event',
        created: new Date(),
      },
    });
  }

  protected [EVENT.CONTROL.REQUEST]({ id }: ControlPayload) {
    const member = this.member(id);
    if (!member) {
      return;
    }

    this.emit('info', 'notifications.controls_has');
  }

  protected [EVENT.CONTROL.REQUESTING]({ id }: ControlPayload) {
    const member = this.member(id);
    if (!member || member.ignored) {
      return;
    }

    this.emit('info', 'notifications.controls_requesting');
  }

  protected [EVENT.PRIVATE.LOCKED]({ id }: ControlPayload) {
    this.simbaStore.dispatch({
      type: RemoteActionType.SET_HOST,
      payload: id,
    });
    const member = this.member(id);
    if (!member) {
      return;
    }

    this.simbaStore.dispatch({
      type: RemoteActionType.SET_PRIVATE,
      id,
      userId: member.displayname,
    });

    if (this.id === id) {
      this.emit('info', 'notifications.private_taken');
      this.logEvent('USER_HAS_ENABLED_PRIVATE_MODE');
    } else {
      this.emit(
        'notification',
        'shock',
        'activated privacy mode',
        member.displayname,
        'rgba(54, 8, 99, 0.76)'
      );
    }

    this.simbaStore.dispatch({
      type: ChatActionType.ADD_MESSAGE,
      payload: {
        id: member.id,
        content: 'notifications.private_taken',
        type: 'event',
        created: new Date(),
      },
    });
  }

  protected [EVENT.PRIVATE.RELEASE]({ id }: ControlPayload) {
    this.simbaStore.dispatch({
      type: RemoteActionType.PRIVATE_RESET,
    });

    const member = this.member(id);
    if (!member) {
      return;
    }

    if (this.id === id) {
      this.emit('info', 'notifications.private_released');
      this.logEvent('USER_HAS_DISABLED_PRIVATE_MODE');
    }

    this.simbaStore.dispatch({
      type: ChatActionType.ADD_MESSAGE,
      payload: {
        id: member.id,
        content: 'notifications.private_released',
        type: 'event',
        created: new Date(),
      },
    });
  }

  protected [EVENT.CONTROL.GIVE]({ id, target }: ControlTargetPayload) {
    const member = this.member(target);
    if (!member) {
      return;
    }

    this.simbaStore.dispatch({
      type: RemoteActionType.SET_HOST,
      payload: member,
    });

    this.simbaStore.dispatch({
      type: ChatActionType.ADD_MESSAGE,
      payload: {
        id,
        content: 'notifications.controls_given',
        type: 'event',
        created: new Date(),
      },
    });
  }

  protected [EVENT.CONTROL.CLIPBOARD]({ text }: ControlClipboardPayload) {
    this.simbaStore.dispatch({
      type: RemoteActionType.SET_CLIPBOARD,
      clipboard: text,
    });

    if (clipboard_write_available()) {
      // Write text into clipboard
      try {
        navigator.clipboard.writeText(text);
      } catch (err) {
        this.emit('error', new Error(`Unable to write to clipboard: ${err}`));
      }
    }
  }

  /////////////////////////////
  // Chat Events
  /////////////////////////////
  protected [EVENT.CHAT.MESSAGE]({ id, content }: ChatPayload) {
    const member = this.member(id);
    if (!member || member.ignored) {
      return;
    }

    this.simbaStore.dispatch({
      type: ChatActionType.ADD_MESSAGE,
      payload: {
        id,
        content,
        type: 'text',
        created: new Date(),
      },
    });
  }

  protected [EVENT.CHAT.EMOTE]({ id }: EmotePayload) {
    const member = this.member(id);
    if (!member || member.ignored) {
      return;
    }
  }

  /////////////////////////////
  // Screen Events
  /////////////////////////////
  protected [EVENT.SCREEN.RESOLUTION]({
    id,
    width,
    height,
    rate,
    initial,
  }: ScreenResolutionPayload) {
    this.simbaStore.dispatch({
      type: VideoActionType.SET_RESOLUTION,
      payload: { width, height, rate },
    });

    if (!id || initial) {
      return;
    }

    if (this.id === id) return;

    const member = this.member(id);
    if (!member || member.ignored) {
      return;
    }

    this.emit('notification', undefined, 'changed the browser size', member.displayname);

    this.simbaStore.dispatch({
      type: ChatActionType.ADD_MESSAGE,
      payload: {
        id,
        content: 'notifications.resolution: ' + width + 'x' + height + '@' + rate,
        type: 'event',
        created: new Date(),
      },
    });
  }

  /////////////////////////////
  // Broadcast Events
  /////////////////////////////
  protected [EVENT.BROADCAST.STATUS]() {}

  /////////////////////////////
  // Admin Events
  /////////////////////////////
  protected [EVENT.ADMIN.BAN]({ id, target }: AdminTargetPayload) {
    if (!target) {
      return;
    }

    const member = this.member(target);
    if (!member) {
      return;
    }

    this.simbaStore.dispatch({
      type: ChatActionType.ADD_MESSAGE,
      payload: {
        id,
        content: 'notifications.banned: ' + member.displayname,
        type: 'event',
        created: new Date(),
      },
    });
  }

  protected [EVENT.ADMIN.KICK]({ id, target }: AdminTargetPayload) {
    if (!target) {
      return;
    }

    const member = this.member(target);
    if (!member) {
      return;
    }

    this.simbaStore.dispatch({
      type: ChatActionType.ADD_MESSAGE,
      payload: {
        id,
        content: 'notifications.kicked: ' + member.displayname,
        type: 'event',
        created: new Date(),
      },
    });
  }

  protected [EVENT.ADMIN.MUTE]({ id, target }: AdminTargetPayload) {
    if (!target) {
      return;
    }

    this.simbaStore.dispatch({
      type: UserActionType.SET_MUTED,
      payload: {
        id: target,
        value: true,
      },
    });

    const member = this.member(target);
    if (!member) {
      return;
    }

    this.simbaStore.dispatch({
      type: ChatActionType.ADD_MESSAGE,
      payload: {
        id,
        content: 'notifications.muted: ' + member.displayname,
        type: 'event',
        created: new Date(),
      },
    });
  }

  protected [EVENT.ADMIN.UNMUTE]({ id, target }: AdminTargetPayload) {
    if (!target) {
      return;
    }

    this.simbaStore.dispatch({
      type: UserActionType.SET_MUTED,
      payload: {
        id: target,
        value: false,
      },
    });

    const member = this.member(target);
    if (!member) {
      return;
    }

    this.simbaStore.dispatch({
      type: ChatActionType.ADD_MESSAGE,
      payload: {
        id,
        content: 'notifications.unmuted: ' + member.displayname,
        type: 'event',
        created: new Date(),
      },
    });
  }

  protected [EVENT.ADMIN.LOCK]({ id, resource }: AdminLockMessage) {
    this.simbaStore.dispatch({
      type: ChatActionType.ADD_MESSAGE,
      payload: {
        id,
        content: 'locks.' + resource + '.notif_locked',
        type: 'event',
        created: new Date(),
      },
    });
  }

  protected [EVENT.ADMIN.UNLOCK]({ id, resource }: AdminLockMessage) {
    this.simbaStore.dispatch({
      type: ChatActionType.ADD_MESSAGE,
      payload: {
        id,
        content: 'locks.' + resource + '.notif_unlocked',
        type: 'event',
        created: new Date(),
      },
    });
  }

  protected [EVENT.ADMIN.CONTROL]({ id, target }: AdminTargetPayload) {
    this.simbaStore.dispatch({
      type: RemoteActionType.SET_HOST,
      payload: id,
    });

    if (!target) {
      this.simbaStore.dispatch({
        type: ChatActionType.ADD_MESSAGE,
        payload: {
          id,
          content: 'notifications.controls_taken_force',
          type: 'event',
          created: new Date(),
        },
      });
      return;
    }

    const member = this.member(target);
    if (!member) {
      return;
    }

    this.simbaStore.dispatch({
      type: ChatActionType.ADD_MESSAGE,
      payload: {
        id,
        content: 'notifications.controls_taken_steal: ' + member.displayname,
        type: 'event',
        created: new Date(),
      },
    });
  }

  protected [EVENT.ADMIN.RELEASE]({ id, target }: AdminTargetPayload) {
    this.simbaStore.dispatch({
      type: RemoteActionType.RESET,
    });

    if (!target) {
      this.simbaStore.dispatch({
        type: ChatActionType.ADD_MESSAGE,
        payload: {
          id,
          content: 'notifications.controls_released_force',
          type: 'event',
          created: new Date(),
        },
      });
      return;
    }

    const member = this.member(target);
    if (!member) {
      return;
    }

    this.simbaStore.dispatch({
      type: ChatActionType.ADD_MESSAGE,
      payload: {
        id,
        content: 'notifications.controls_released_steal: ' + member.displayname,
        type: 'event',
        created: new Date(),
      },
    });
  }

  protected [EVENT.ADMIN.GIVE]({ id, target }: AdminTargetPayload) {
    if (!target) {
      return;
    }

    const member = this.member(target);
    if (!member) {
      return;
    }

    this.simbaStore.dispatch({
      type: RemoteActionType.SET_HOST,
      payload: member,
    });

    this.simbaStore.dispatch({
      type: ChatActionType.ADD_MESSAGE,
      payload: {
        id,
        content: 'notifications.controls_given: ' + member.displayname,
        created: new Date(),
        type: 'event',
      },
    });
  }

  // Utilities
  protected member(id: string): Member | undefined {
    return this.simbaStore.getters.user.getMember(id);
  }
}
