import { useUpdateMyPresence } from '@liveblocks/react';
import React, { useEffect } from 'react';

import CursorEphemeral from '../components/Cursors/CursorEphemeral';
import CursorNormal from '../components/Cursors/CursorNormal';
import CursorPointer from '../components/Cursors/CursorPointer';
import CursorText from '../components/Cursors/CursorText';
import { useSimbaStore } from '../simba/store';
import { Position } from '../types/global';
import { Presence } from './member';

export interface PressuredPosition extends Position {
  pressure?: number;
}

// Cursor CSS types
export const cursorType = ['default', 'pointer', 'text'] as const;
export type CursorType = typeof cursorType[number];
// Cursor mode
export const cursorMode = ['normal', 'ephemeral'] as const;
export type CursorMode = typeof cursorMode[number];

export interface CursorData {
  type: CursorType;
  mode: CursorMode;
  position: PressuredPosition;
  hidden: boolean;
  drawing: boolean;
  lastWiggle: number;
  hasPressure: boolean;
}

export interface CursorProps extends React.SVGProps<SVGSVGElement> {
  color: string;
}

type CursorShapeDictionary = {
  [key in CursorMode]: (cursorData?: CursorData) => React.FunctionComponent<CursorProps>;
};

export const CursorShapes: CursorShapeDictionary = {
  normal: (cursorData?: CursorData) => {
    switch (cursorData?.type) {
      case 'pointer':
        return CursorPointer;
      case 'text':
        return CursorText;
      default:
        return CursorNormal;
    }
  },
  ephemeral: () => CursorEphemeral,
};

export const getCursorShape = (cursorData?: CursorData) => {
  const mode = cursorData?.mode || 'normal';
  return CursorShapes[mode](cursorData);
};

export interface LocalCursorData extends CursorData {
  ephemeralClear: number;
  shimmer: boolean;
}

export interface ILocalCursorContext extends LocalCursorData {
  setPosition: (position: Position) => void;
  setType: (cursorType: CursorType) => void;
  setMode: (cursorMode: CursorMode) => void;
  setHidden: (cursorHidden: boolean) => void;
  setDrawing: (drawing: boolean) => void;
  setLastWiggle: (lastWiggle: number) => void;
  startShimmer: () => void;
  setHasPressure: (hasPressure: boolean) => void;
  clearEphemeral: () => void;
}

const LocalCursorContext = React.createContext<ILocalCursorContext>({} as ILocalCursorContext);
const useLocalCursor = () => React.useContext(LocalCursorContext);

const LocalCursorProvider = ({ children }: { children: React.ReactNode }) => {
  const { getters } = useSimbaStore();
  const isSelfPrivate = getters.remote.isSelfPrivate;

  const updateMyPresence = useUpdateMyPresence<Presence>();

  const [hidden, setHidden] = React.useState(false);
  const [type, setType] = React.useState<CursorType>('default');
  const [mode, setMode] = React.useState<CursorMode>('normal');
  const [position, setPosition] = React.useState({ x: 0, y: 0 });
  const [drawing, setDrawing] = React.useState(false);
  const [lastWiggle, setLastWiggle] = React.useState(0);
  const [ephemeralClear, setEphemeralClear] = React.useState(0);
  const [shimmer, setShimmer] = React.useState(false);

  const shimmerTimer = React.useRef<number>();
  const [hasPressure, setHasPressure] = React.useState(false);

  const clearEphemeral = () => {
    setEphemeralClear((n) => n + 1);
  };

  useEffect(() => {
    if (isSelfPrivate) return;

    if (mode !== 'ephemeral' && drawing) setDrawing(false);

    updateMyPresence({
      cursor: {
        hasPressure,
        position,
        type,
        mode,
        hidden,
        drawing,
        lastWiggle,
      },
    });
  }, [
    isSelfPrivate,
    updateMyPresence,
    position,
    type,
    mode,
    hidden,
    drawing,
    lastWiggle,
    hasPressure,
  ]);

  const startShimmer = () => {
    if (shimmerTimer.current) return;
    shimmerTimer.current = window.setTimeout(() => {
      setShimmer(false);
      shimmerTimer.current = undefined;
    }, 1000);
    setShimmer(true);
  };

  return (
    <LocalCursorContext.Provider
      value={{
        position,
        type,
        mode,
        hidden,
        drawing,
        lastWiggle,
        shimmer,
        ephemeralClear,
        hasPressure,
        setPosition,
        setType,
        setMode,
        setHidden,
        setDrawing,
        setLastWiggle,
        startShimmer,
        setHasPressure,
        clearEphemeral,
      }}
    >
      {children}
    </LocalCursorContext.Provider>
  );
};

export { LocalCursorProvider, useLocalCursor };
