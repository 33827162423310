import { HalUser } from '@fabric/woody-client';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

import { OfCategory } from '../components/CategoryButton/CategoryButton';
import { OfViewMode } from '../components/ListModeSwitcher/ListModeSwitcher';

export interface UIStore {
  user: HalUser | null;
  userLastFetch: number;

  onboarding: boolean;
  searchQuery: string;
  filter: OfCategory;
  viewMode: OfViewMode;

  setUser: (user: HalUser | null) => void;
  setUserLastFetch: (timestamp: number) => void;
  setOnboarding: (onboarding: boolean) => void;
  setSearchQuery: (query: string) => void;
  setFilter: (filter: OfCategory) => void;
  setViewMode: (mode: OfViewMode) => void;

  reset: () => void;
}

const useUIStore = create<UIStore>()(
  persist(
    (set) => ({
      user: null as HalUser | null,
      userLastFetch: 0,
      onboarding: true,
      searchQuery: '',
      filter: 'All' as OfCategory,
      viewMode: 'Grid' as OfViewMode,

      setUser: (user) => set({ user }),
      setUserLastFetch: (timestamp) => set({ userLastFetch: timestamp }),
      setOnboarding: (onboarding) => set({ onboarding }),
      setSearchQuery: (query: string) => set({ searchQuery: query }),
      setFilter: (filter: OfCategory) => set({ filter }),
      setViewMode: (mode: OfViewMode) => set({ viewMode: mode }),
      reset: () => set({ searchQuery: '', filter: 'All', viewMode: 'Grid', user: null }),
    }),
    {
      name: 'UIStore',
      partialize: (state) => {
        // dont persist searchQuery
        const { searchQuery, ...rest } = state;

        return rest;
      },
    }
  )
);

export default useUIStore;
