import React from 'react';

import Skeleton from '../../components/Skeleton/Skeleton';
import SkeletonStructure from '../../components/SkeletonStructure/SkeletonStructure';

const ListManagerSkeleton: React.FC = () => {
  return (
    <SkeletonStructure
      style={{
        alignItems: 'flex-start',
      }}
    >
      <SkeletonStructure spaceBetween padding="21px 21px 12px 21px" expand height="auto">
        <Skeleton width={185} height={24} borderRadius={4} />
        <Skeleton width={330} height={48} borderRadius={24} />
        <Skeleton width={56} height={24} borderRadius={6} />
      </SkeletonStructure>
      <SkeletonStructure cols padding={21} gap={10}>
        <SkeletonStructure
          cols
          bgColor="white"
          width={368}
          style={{
            border: '1px solid #cacaca',
            borderRadius: 16,
          }}
          expand
        >
          <SkeletonStructure padding={20}>
            <Skeleton width={100} height={24} borderRadius={4} />
          </SkeletonStructure>
          <SkeletonStructure spaceBetween padding="0 20px 20px 20px">
            <Skeleton width={30} height={30} circle />
            <Skeleton width={51} height={20} borderRadius={6} />
          </SkeletonStructure>
        </SkeletonStructure>
        <SkeletonStructure gap={10} style={{ justifyContent: 'flex-end' }}>
          <Skeleton width={128} height={48} borderRadius={8} />
          <Skeleton width={128} height={48} borderRadius={8} />
        </SkeletonStructure>
      </SkeletonStructure>
    </SkeletonStructure>
  );
};

export default ListManagerSkeleton;
