import React from 'react';

import { ReactComponent as PcCrash } from '../../assets/images/icons/pc/crash.svg';
import FabricError from '../../templates/FabricError';

const InternalError: React.FC<{
  errorDescription?: string;
  errorStack?: string;
}> = ({ errorDescription, errorStack }) => {
  return (
    <FabricError>
      <PcCrash
        style={{
          width: 125,
          height: 145,
          marginBottom: 50,
          color: '#2B38F5',
        }}
      />
      <h4>Hmm... something’s not working</h4>
      <p>We’re sorry about that.</p>
      <a href="/">Click here to return to your dashboard</a>

      {(errorDescription || errorStack) && (
        <pre>
          {errorDescription}
          {'\n'}
          {errorStack}
        </pre>
      )}
    </FabricError>
  );
};

export default InternalError;
