import { Member } from '../types';
import { SimbaState } from '.';

const keyboardModifierState = (capsLock: boolean, numLock: boolean, scrollLock: boolean) =>
  Number(capsLock) + 2 * Number(numLock) + 4 * Number(scrollLock);

export enum RemoteActionType {
  SET_SERVICE_ID = 'REMOTE:SET_SERVICE_ID',
  SET_TWILIO_TOKEN = 'REMOTE:SET_TWILIO_TOKEN',
  SET_HOST = 'REMOTE:SET_HOST',
  SET_PRIVATE = 'REMOTE:SET_PRIVATE',
  SET_CLIPBOARD = 'REMOTE:SET_CLIPBOARD',
  SET_KEYBOARD_MODIFIER = 'REMOTE:SET_KEYBOARD_MODIFIER',
  SET_LOCKED = 'REMOTE:SET_LOCKED',
  SET_IMPLICIT_HOSTING = 'REMOTE:SET_IMPLICIT_HOSTING',
  SET_INACTIVITY_TIMEOUT = 'REMOTE:SET_INACTIVITY_TIMEOUT',
  PRIVATE_RESET = 'REMOTE:PRIVATE_RESET',
  RESET = 'REMOTE:RESET',
}

const initialState: RemoteState = {
  serviceId: '',
  id: '',
  private: '',
  privateUserId: '',
  clipboard: '',
  locked: false,
  implicitHosting: true,
  keyboardModifierState: -1,
  twilioToken: '',
  inactivityDisconnectTime: null,
};

export type RemoteState = {
  serviceId: string;
  id: string;
  private: string;
  privateUserId: string;
  clipboard: string;
  locked: boolean;
  implicitHosting: boolean;
  keyboardModifierState: number;
  twilioToken: string;
  inactivityDisconnectTime: number | null;
};

export type RemoteAction =
  | {
      type: RemoteActionType.SET_SERVICE_ID;
      payload: string;
    }
  | {
      type: RemoteActionType.SET_TWILIO_TOKEN;
      payload: string;
    }
  | {
      type: RemoteActionType.SET_HOST;
      payload: string | Member;
    }
  | {
      type: RemoteActionType.SET_PRIVATE;
      userId: string;
      id?: string;
      member?: Member;
    }
  | {
      type: RemoteActionType.SET_CLIPBOARD;
      clipboard: string;
    }
  | {
      type: RemoteActionType.SET_KEYBOARD_MODIFIER;
      payload: {
        capsLock: boolean;
        numLock: boolean;
        scrollLock: boolean;
      };
    }
  | {
      type: RemoteActionType.SET_LOCKED;
      payload: boolean;
    }
  | {
      type: RemoteActionType.SET_IMPLICIT_HOSTING;
      payload: boolean;
    }
  | {
      type: RemoteActionType.SET_INACTIVITY_TIMEOUT;
      timestamp: number | null;
    }
  | {
      type: RemoteActionType.PRIVATE_RESET;
    }
  | {
      type: RemoteActionType.RESET;
    };

const reducer = (state: RemoteState, action: RemoteAction): RemoteState => {
  switch (action.type) {
    case RemoteActionType.SET_SERVICE_ID:
      return {
        ...state,
        serviceId: action.payload,
      };
    case RemoteActionType.SET_TWILIO_TOKEN:
      return {
        ...state,
        twilioToken: action.payload,
      };
    case RemoteActionType.SET_HOST:
      return {
        ...state,
        id: (action.payload as Member).id || (action.payload as string),
      };
    case RemoteActionType.SET_PRIVATE: {
      // Invalid options:
      if ((action.id && action.member) || (!action.id && !action.member)) return state;

      // When we set private we also change the host
      return {
        ...state,
        id: action.id || (action.member as Member).id,
        private: action.id || (action.member as Member).id,
        privateUserId: action.userId,
      };
    }
    case RemoteActionType.SET_CLIPBOARD:
      return {
        ...state,
        clipboard: action.clipboard,
      };
    case RemoteActionType.SET_KEYBOARD_MODIFIER:
      return {
        ...state,
        keyboardModifierState: keyboardModifierState(
          action.payload.capsLock,
          action.payload.numLock,
          action.payload.scrollLock
        ),
      };
    case RemoteActionType.SET_LOCKED:
      return {
        ...state,
        locked: action.payload,
      };
    case RemoteActionType.SET_IMPLICIT_HOSTING:
      return {
        ...state,
        implicitHosting: action.payload,
      };
    case RemoteActionType.SET_INACTIVITY_TIMEOUT:
      return {
        ...state,
        inactivityDisconnectTime: action.timestamp,
      };
    case RemoteActionType.PRIVATE_RESET:
      return {
        ...state,
        private: '',
      };
    case RemoteActionType.RESET:
      return {
        ...state,
        id: '',
        clipboard: '',
        locked: false,
      };
    default:
      return state;
  }
};

// Using state, root so its closer to the Vue counterpart
const getters = (state: RemoteState, root: SimbaState) => {
  return {
    get hosting() {
      return root.user.id === state.id || state.implicitHosting;
    },
    get hosted() {
      return state.id !== '' || state.implicitHosting;
    },
    get host() {
      return (
        root.user.members.find((member) => member.id === state.id) ||
        (state.implicitHosting && root.user.id) ||
        null
      );
    },
    get private() {
      return (
        root.user.members.find((member) => member.id === state.private) ||
        (state.implicitHosting && root.user.id) ||
        null
      );
    },
    get isSelfPrivate() {
      const isInitialized = state.private !== '' && root.user.id !== '';
      return isInitialized && state.private === root.user.id;
    },
    get isOtherPrivate() {
      return state.private !== '' && state.private !== root.user.id;
    },
  } as const;
};

export default { reducer, getters, initialState } as const;
