import React, { useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';

import ErrorComponent from '../components/Error';

export enum ZazuError {
  GENERIC = 'generic',
  FAILED = 'failed',
  INVALID_ID = 'invalidId',
  DOUBLE_LOGIN = 'doubleLogin',
  TIMEOUT = 'timeout',
  KICKED = 'kicked',
  INACTIVITY = 'inactivity',
  NO_INTERNET_CONNECTION = 'noInternetConnection',
}
type ZazuErrorsMap = Record<ZazuError, (baseError?: Error) => React.ReactNode>;

const ReloadButton: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <button className="underline" onClick={() => window.location.reload()}>
      {children}
    </button>
  );
};

// Using a constant avoids duplicating these errors throughout the app.
const ZazuErrors: ZazuErrorsMap = {
  generic: (baseError?: Error) => (
    <ErrorComponent
      error={
        <span>
          You got disconnected <span className="whitespace-nowrap">:(</span>
        </span>
      }
      description={<ReloadButton>Click here to re-join session</ReloadButton>}
      baseError={baseError}
    />
  ),
  failed: (baseError?: Error) => (
    <ErrorComponent
      error="Umm... looks like our server is down?"
      description="We’re sorry about that."
      baseError={baseError}
      pcEmotion="crash"
    />
  ),
  invalidId: (baseError?: Error) => (
    <ErrorComponent
      error="Invalid ID (we couldn't find your browser)"
      baseError={baseError}
      description={
        <>
          <p>We're sorry about that.</p>
          <br />
          <Link to="/" className="underline">
            Click here to create a new session.
          </Link>
        </>
      }
    />
  ),
  doubleLogin: () => (
    <ErrorComponent
      error="You connected in another tab or device!"
      description="We’ve disconnected this tab so you don’t show up twice"
      pcEmotion="duplicate"
    />
  ),
  timeout: () => (
    <ErrorComponent
      error="It took too long to connect 🐌"
      description={<ReloadButton>Click here or refresh to try again</ReloadButton>}
      pcEmotion="slow"
    />
  ),
  kicked: () => (
    <ErrorComponent
      error={
        <>
          <p>Well, that's awkward...</p>
          <p>You got kicked</p>
        </>
      }
      description="Maybe it was something you said?"
      pcEmotion="surprised"
    />
  ),
  inactivity: () => (
    <ErrorComponent
      error="Browser has gone to sleep"
      description="Click anywhere to resume"
      pcEmotion="sleeping"
      onClickReload
    />
  ),
  noInternetConnection: () => (
    <ErrorComponent
      error="No internet connection"
      description={<ReloadButton>Click here to re-join session</ReloadButton>}
      pcEmotion="crash"
    />
  ),
};

export enum ErrorStatusType {
  FAILED = 'failed',
  LOADING = 'loading',
  SUCCESS = 'success',
}

interface ErrorStatus {
  liveblocks: ErrorStatusType;
  twilio: ErrorStatusType;
  microphone: ErrorStatusType;
  audio: ErrorStatusType;
  camera: ErrorStatusType;
}

interface ErrorHandlerContextData {
  error: React.ReactNode | null;
  errorType: string | null;
  throwError: (error: keyof typeof ZazuErrors, baseError?: Error) => void;
  clearError: () => void;

  status: ErrorStatus;
  setStatus: React.Dispatch<React.SetStateAction<ErrorStatus>>;
}

const ErrorHandler = React.createContext<ErrorHandlerContextData>({} as ErrorHandlerContextData);
const useErrorHandler = () => React.useContext(ErrorHandler);

const useThrow = () => {
  const { throwError } = useErrorHandler();
  return throwError;
};

const useErrorStatus = (): [
  ErrorStatus,
  (type: keyof ErrorStatus, value: ErrorStatusType) => void
] => {
  const { status, setStatus } = useErrorHandler();

  const setErrorStatus = React.useCallback(
    (type: keyof ErrorStatus, value: ErrorStatusType) => {
      setStatus((status) => ({ ...status, [type]: value }));
    },
    [setStatus]
  );

  return [status, setErrorStatus];
};

const useSetupErrorHandler = () => {
  const [error, setError] = React.useState<string | null>(null);
  const [baseError, setBaseError] = React.useState<Error | undefined>();

  const throwError = useCallback(
    (error: keyof typeof ZazuErrors, baseError?: Error) => {
      setError(error as string);
      if (baseError) setBaseError(baseError);
    },
    [setError]
  );

  const clearError = useCallback(() => {
    setError(null);
  }, [setError]);

  // Handling partial errors, like liveblocks, twilio, etc.
  const [status, setStatus] = React.useState<ErrorStatus>({
    liveblocks: ErrorStatusType.LOADING,
    twilio: ErrorStatusType.LOADING,
    microphone: ErrorStatusType.LOADING,
    audio: ErrorStatusType.LOADING,
    camera: ErrorStatusType.LOADING,
  });

  const Component = useMemo(() => {
    if (!error) return null;

    if (error in ZazuErrors) {
      return ZazuErrors[error as keyof typeof ZazuErrors](baseError);
    } else {
      return ZazuErrors['generic'](baseError);
    }
  }, [error, baseError]);

  return { error: Component, errorType: error, throwError, clearError, status, setStatus };
};

const ErrorHandlerProvider: React.FC = ({ children }) => {
  const errorHandler = useSetupErrorHandler();

  return (
    <ErrorHandler.Provider value={errorHandler}>
      <>{errorHandler.error ? errorHandler.error : children}</>
    </ErrorHandler.Provider>
  );
};

export {
  ErrorHandlerProvider,
  useSetupErrorHandler,
  ErrorHandler,
  useThrow,
  useErrorHandler,
  useErrorStatus,
};
