import React from 'react';

import { getTextColor } from '../utils/color';

const UserBadge: React.FC<{ name: string; color: string }> = ({ name, color }) => {
  return (
    <span
      className="px-2 mr-2 text-center text-white rounded-2xl"
      style={{
        backgroundColor: color,
        color: getTextColor(color),
        display: name ? 'inline' : 'none',
      }}
    >
      {name}
    </span>
  );
};

export default UserBadge;
