import { Presence as LiveblocksPresence, User } from '@liveblocks/client';
import { useMap, useRoom, useUpdateMyPresence } from '@liveblocks/react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useSimbaStore } from '../simba/store';
import { UserActionType } from '../simba/store/user';
import { userColors } from '../utils/color';
import { CursorData } from './cursor';
import { useDebug } from './debug';
import { ErrorStatusType, useErrorStatus } from './error';
import log from './log';
import { useConversation } from './twilio-conversations';

const randomEmoji = [
  '🐶',
  '🦊',
  '🐱',
  '🐴',
  '🦄',
  '🐄',
  // import { useAuth } from '../../../hooks/useAuth';
  '🐘',
  '🐫',
  '🦒',
  '🐭',
  '🐼',
  '🦆',
  '🐸',
  '🦜',
  '🐳',
  '🐙',
  '🐝',
  '🌵',
  '🦀',
];

const idToNumber = (id: string) => {
  // Convert all chars to ASCII numbers and sum them up.
  return id.split('').reduce((acc, char) => acc + char.charCodeAt(0), 0);
};

// This type is for storing the last color, name, emoji and timestamp used for a userId&roomId combination.
export type UserCache = {
  color: string;
  name: string | null;
  originalName: string | null;
  emoji: string;
  timestamp: number;
};

/**
 * VolatilePresence is the presence data that is constantly updated.
 */
export type VolatilePresence = {
  cursor: CursorData;
  message?: string;
};

/**
 * SafePresence is the presence data that is not updated as often.
 */
export type SafePresence = {
  name: string;
  color: string;

  talking: boolean;
  userId: string;
  twilioSid: string | null;
  microphoneMuted: boolean;
  joined: boolean; // This represents if the person has clicked the join button or not.
  joinedAt: number | null;
};

export type Presence = SafePresence & VolatilePresence;

export const useOthers = <TPresence extends LiveblocksPresence>(
  filter: (param: keyof TPresence) => boolean = () => true
): User<TPresence>[] => {
  const room = useRoom();
  // Needed to avoid re-rendering the whole component when the others change.
  const [others, setOthers] = useState<User<TPresence>[]>(room.getOthers<TPresence>().toArray());
  const lastOthers = useRef<User<TPresence>[]>(others);

  useEffect(() => {
    const unsubscribe = room.subscribe('others', (_, event) => {
      if (
        event.type === 'update' &&
        Object.keys(event.updates).filter(filter).length === 0 &&
        // If this user is not on others we missed the enter event.
        // We need to trigger rerender to make sure the user is added to the list.
        !!lastOthers.current?.find((other) => event.user.connectionId === other.connectionId)
      )
        return;

      setOthers(room.getOthers<TPresence>().toArray() as User<TPresence>[]);
    });

    return () => {
      unsubscribe();
    };
  }, [filter, room]);

  useEffect(() => {
    if (lastOthers.current === others) return;
    lastOthers.current = others;
  }, [others]);

  return others;
};

export const useSelf = <TPresence extends LiveblocksPresence>(
  filter: (param: keyof TPresence) => boolean = () => true
): User<TPresence> | null => {
  const room = useRoom();
  const [self, setSelf] = useState<User<TPresence> | null>(room?.getSelf<TPresence>());
  const lastSelf = useRef<User<TPresence> | null>(room.getSelf<TPresence>());

  useEffect(() => {
    const setNewSelf = (self: User<TPresence> | null) => {
      setSelf(self);
      lastSelf.current = self;
    };

    const unsubscribePresence = room.subscribe('my-presence', (self) => {
      // This event does not give us the changes, so we need to check if the self has changed from lastSelf.
      if (self === lastSelf.current) return;
      if (!lastSelf.current?.presence) {
        setNewSelf(room.getSelf<TPresence>());
        return;
      }

      // Get keys that are different from the last self.
      const currentPresence = lastSelf.current.presence as { [key: string]: any };
      const keys = Object.keys(self)
        .filter((key) => currentPresence[key] !== self[key])
        .filter(filter);

      if (keys.length === 0) return;

      setNewSelf(room.getSelf<TPresence>());
    });

    const unsubscribeConnection = room.subscribe('connection', () => {
      setSelf(room.getSelf<TPresence>());
    });

    return () => {
      unsubscribePresence();
      unsubscribeConnection();
    };
  }, [room, filter]);

  return self;
};

export const useJoinDetection = () => {
  const room = useRoom();

  const [joined, setJoined] = useState(0);
  // Using initialDate avoids a join being counted duplicated because someone was already in the room
  // or when someone joins and every user sends their own presence.
  const initialDate = useRef(Date.now());

  useEffect(() => {
    const unsubscribeOthers = room.subscribe<Presence>('others', (_, event) => {
      if (event.type === 'update') {
        if (
          event.updates.joined &&
          event.updates.joinedAt &&
          event.updates.joinedAt > initialDate.current
        ) {
          initialDate.current = event.updates.joinedAt;
          setJoined((prev) => prev + 1);
        }
      }
    });
    return () => {
      unsubscribeOthers();
    };
  }, [room]);

  return joined;
};

// Using the /./gu regex so we separate in a unicode way and don't destroy the emojis
export const getAbbreviatedName = (name: string) => /./gu.exec(name)?.[0]?.toUpperCase() || '⟳';

export const firstNameLastLetterFormat = (name: string) => {
  const splitName = name.split(' ');

  if (splitName.length === 1) return splitName[0];

  const lastWord = splitName[splitName.length - 1];
  // Getting the first letter of the last word in a unicode way
  const firstLetter = /./gu.exec(lastWord)?.[0]?.toUpperCase() || '';

  // Return first name + last initial.
  return `${splitName[0]} ${firstLetter}.`;
};

export const firstNameOnly = (name: string) => {
  const splitName = name.split(' ');
  if (splitName.length >= 1) return splitName[0];
  return name;
};

export const useInControl = () => {
  const host = useSimbaStore().getters.remote.host;

  return (userId: string | null | undefined) => {
    return userId === (typeof host !== 'string' ? host?.displayname : false);
  };
};

export const useMembers = <TPresence extends LiveblocksPresence>(
  filter: (presenceParameter: keyof TPresence) => boolean
): User<TPresence>[] => {
  const self = useSelf<TPresence>(filter);
  const others = useOthers<TPresence>(filter);

  const [members, setMembers] = useState<User<TPresence>[]>([...others, ...(self ? [self] : [])]);

  useEffect(() => {
    setMembers([...others, ...(self ? [self] : [])]);
  }, [others, self]);

  return members;
};

export const useSetupSelf = (): [string, (name: string) => void] => {
  const room = useRoom();
  const self = useSelf<Presence>((param) => ['joined', 'name'].includes(param));
  const updateMyPresence = useUpdateMyPresence<Presence>();

  const storageCache = useMap<string, UserCache>('user-cache');

  const [status] = useErrorStatus();
  const liveblocksStatus = status.liveblocks;
  const { name, userId } = self?.presence || {};
  const connectionId = self?.connectionId;

  const colorOffset = useMemo(() => idToNumber(room.id), [room.id]);

  const [userCache, setUserCache] = useState<UserCache | undefined>(undefined);
  const cacheId = `${room.id}.${userId}.cache`;
  const { addTrigger, removeTrigger } = useDebug();
  const { setName } = useConversation();

  useEffect(() => {
    setName(name ?? null);

    return () => {
      setName(null);
    };
  }, [name, setName]);

  useEffect(() => {
    // Get the user cache from the local storage.
    const userCache = localStorage.getItem(cacheId);

    if (userCache) {
      try {
        const parsed = JSON.parse(userCache);
        setUserCache({
          ...parsed,
          name: parsed.name ?? null, // If there is no name let's clean it up.
        });
      } catch (e) {
        setUserCache(undefined);
      }
    }
  }, [cacheId]);

  // Update the local storage with the user cache if it changes.
  useEffect(() => {
    if (!userCache) return;

    localStorage.setItem(cacheId, JSON.stringify(userCache));

    // Also update presence if the user cache changes.
    updateMyPresence({
      name: userCache.name ?? userCache.emoji,
      color: userCache.color,
    });

    if (userId) storageCache?.set(userId, userCache);
  }, [cacheId, userCache, userId, storageCache, updateMyPresence]);

  useEffect(() => {
    if (
      liveblocksStatus === ErrorStatusType.SUCCESS &&
      typeof connectionId === 'number' &&
      !userCache
    ) {
      const index = connectionId + colorOffset;

      // Create a new user cache.
      const newUserCache = {
        color: userColors[index % userColors.length],
        emoji: randomEmoji[index % randomEmoji.length],
        name: name ?? null,
        originalName: name ?? null,
        timestamp: Date.now(),
      };

      setUserCache(newUserCache);
    }
  }, [liveblocksStatus, colorOffset, connectionId, name, userCache]);

  // Update original name first time it comes in that is not null.
  useEffect(() => {
    if (userCache?.originalName === null && name) {
      setUserCache({ ...userCache, originalName: name });
    }
  }, [name, userCache]);

  const cachedName = userCache?.name ?? name ?? userCache?.emoji ?? '';

  const changeCachedName = useCallback(
    (name: string) => {
      if (!userCache) return;
      if (userCache.name === name) return;

      setUserCache({ ...userCache, name });
    },
    [userCache]
  );

  useEffect(() => {
    addTrigger('presence_randomize_color', () => {
      if (!userCache) return;
      // random
      const index =
        idToNumber(room.id) + colorOffset + Math.floor(Math.random() * userColors.length);
      const color = userColors[index % userColors.length];
      if (userCache.color === color) return;

      setUserCache({ ...userCache, color });
      updateMyPresence({ color });
    });

    return () => {
      removeTrigger('presence_randomize_color');
    };
  }, [colorOffset, room.id, userCache, updateMyPresence, addTrigger, removeTrigger]);

  return [cachedName, changeCachedName];
};

type LocalStorageCache = { cache?: UserCache; presence?: SafePresence };
export const getPresenceCache = (id: string): Record<string, LocalStorageCache> => {
  try {
    const cache = JSON.parse(localStorage.getItem(`${id}.user-cache`) || '{}');
    return cache;
  } catch (e) {
    return {};
  }
};

const setCache = (id: string, cache: Record<string, LocalStorageCache>) => {
  localStorage.setItem(`${id}.user-cache`, JSON.stringify(cache));
};

// Extracted because it was causing the useMembers to re-render and unsubscribe from the events.
// this caused the date to be out of sync.
const lowEventFilter = (param: string) => param !== 'message' && param !== 'cursor';

export const useUpdateUserStore = (id: string) => {
  const room = useRoom();
  const { dispatch, state } = useSimbaStore();
  const storageCache = useMap<string, UserCache>('user-cache');
  const { setStatus, debugPanelOpen } = useDebug();
  const lastSelf = useRef<LiveblocksPresence | null>(null);

  // Update anytime the real members change.
  useEffect(() => {
    const unsubscribeOthers = room.subscribe<Presence>('others', (_, event) => {
      if (event.type !== 'enter' && event.type !== 'update') return;
      if (!event.user.presence) return;

      if (event.type === 'update' && Object.keys(event.updates).filter(lowEventFilter).length === 0)
        return;

      const cache = getPresenceCache(id);

      dispatch({
        type: UserActionType.SET_MEMBER_PRESENCE,
        userId: event.user.presence.userId,
        presence: event.user.presence,
      });

      dispatch({
        type: UserActionType.SET_MEMBER_CONNECTION_ID,
        userId: event.user.presence.userId,
        connectionId: event.user.connectionId,
      });

      setCache(id, {
        ...cache,
        [event.user.presence.userId]: {
          ...cache[event.user.presence.userId],
          presence: event.user.presence,
        },
      });
    });

    const unsubscribeSelf = room.subscribe<Presence>('my-presence', (presence) => {
      if (!presence) return;

      // if diff between last self and current self is only low events then don't update.
      const currentPresence = presence as { [key: string]: any };
      if (
        lastSelf.current &&
        Object.keys(presence)
          .filter((k) => lastSelf.current && lastSelf.current[k] !== currentPresence[k])
          .filter(lowEventFilter).length === 0
      ) {
        return;
      }

      const cache = getPresenceCache(id);

      dispatch({
        type: UserActionType.SET_MEMBER_PRESENCE,
        userId: presence.userId,
        presence,
      });

      const connectionId = room.getSelf()?.connectionId;
      if (connectionId)
        dispatch({
          type: UserActionType.SET_MEMBER_CONNECTION_ID,
          userId: presence.userId,
          connectionId,
        });

      setCache(id, {
        ...cache,
        [presence.userId]: {
          ...cache[presence.userId],
          presence,
        },
      });

      lastSelf.current = presence;
    });

    return () => {
      unsubscribeOthers();
      unsubscribeSelf();
    };
  }, [dispatch, id, room]);

  useEffect(() => {
    const cache = getPresenceCache(id);
    if (!storageCache) return;

    const entries = Array.from(storageCache.entries()) ?? [];
    log.debug('cache::updating_user_cache_from_storageCache');

    // for each of storageCache
    for (const [userId, userCache] of entries) {
      dispatch({
        type: UserActionType.SET_MEMBER_CACHE,
        userId,
        cache: userCache,
      });

      setCache(id, {
        ...cache,
        [userId]: {
          ...cache[userId],
          cache: userCache,
        },
      });
    }
  }, [storageCache, dispatch, id]);

  useEffect(() => {
    if (!debugPanelOpen) return;
    const cache = getPresenceCache(id);
    setStatus('Presence Cache', {
      cacheCount: Object.keys(cache).length,
      cache,
    });
  }, [debugPanelOpen, id, setStatus]);

  useEffect(() => {
    if (!debugPanelOpen) return;

    setStatus('Simba Users', {
      'Users Count': state.user.members.length,
      Users: JSON.parse(JSON.stringify(state.user.members, (_, v) => (v === undefined ? null : v))),
    });
  }, [debugPanelOpen, state.user.members, setStatus]);
};
