import { createContext, ReactNode, useContext, useEffect, useMemo, useReducer } from 'react';

import { useDebug } from '../../hooks/debug';
import log from '../../hooks/log';
import { SimbaClient } from '..';
import chatReducer, { ChatAction, ChatState } from './chat';
import controlReducer, { ControlAction, ControlState } from './control';
import mouseReducer, { MouseAction, MouseState } from './mouse';
import onboardingReducer, {
  OnboardingAction,
  OnboardingActionType,
  OnboardingState,
} from './onboarding';
import queueReducer, { QueueAction, QueueActionType, QueueState } from './queue';
import remoteReducer, { RemoteAction, RemoteState } from './remote';
import statusReducer, { StatusAction, StatusState } from './status';
import userReducer, { UserAction, UserState } from './user';
import videoReducer, { VideoAction, VideoState } from './video';
import zoomPanReducer, { ZoomPanAction, ZoomPanState } from './zoom-pan';

export type SimbaState = {
  chat: ChatState;
  user: UserState;
  video: VideoState;
  remote: RemoteState;
  status: StatusState;
  client: SimbaClient;
  zoomPan: ZoomPanState;
  control: ControlState;
  mouse: MouseState;
  queue: QueueState;
  onboarding: OnboardingState;
};

export type SimbaAction =
  | ChatAction
  | UserAction
  | VideoAction
  | RemoteAction
  | StatusAction
  | ZoomPanAction
  | ControlAction
  | MouseAction
  | QueueAction
  | OnboardingAction;

const initialState = {
  chat: chatReducer.initialState,
  user: userReducer.initialState,
  video: videoReducer.initialState,
  remote: remoteReducer.initialState,
  status: statusReducer.initialState,
  zoomPan: zoomPanReducer.initialState,
  control: controlReducer.initialState,
  mouse: mouseReducer.initialState,
  queue: queueReducer.initialState,
  onboarding: onboardingReducer.initialState,
};

export interface ISimbaStore {
  state: SimbaState;
  dispatch: (action: SimbaAction) => void;
  getters: {
    // Adding return types for auto complete
    user: ReturnType<typeof userReducer.getters>;
    video: ReturnType<typeof videoReducer.getters>;
    remote: ReturnType<typeof remoteReducer.getters>;
    // zoomPan: ReturnType<typeof zoomPanReducer.getters>;
  };
}

export const SimbaStore = createContext({} as ISimbaStore);

export const useSimbaStore = () => useContext(SimbaStore);

export const simbaReducer = (state: SimbaState, action: SimbaAction): SimbaState => {
  const { chat, user, video, remote, status, client, zoomPan, control, mouse, queue } = state;

  if (!status.connected && !action.type.startsWith('STATUS:')) {
    log.debug('simbaStore::queue', action);

    return {
      ...state,
      queue: queueReducer.reducer(queue, { type: QueueActionType.ADD, action }),
    };
  }

  log.debug('simbaStore::dispatch', action);

  const currentState = {
    chat: chatReducer.reducer(chat, action as ChatAction),
    user: userReducer.reducer(user, action as UserAction),
    video: videoReducer.reducer(video, action as VideoAction),
    remote: remoteReducer.reducer(remote, action as RemoteAction),
    status: statusReducer.reducer(status, action as StatusAction),
    client,
    zoomPan: zoomPanReducer.reducer(zoomPan, action as ZoomPanAction),
    control: controlReducer.reducer(control, action as ControlAction),
    mouse: mouseReducer.reducer(mouse, action as MouseAction),
    queue: queueReducer.reducer(queue, action as QueueAction),
    onboarding: onboardingReducer.reducer(state.onboarding, action as OnboardingAction),
  };

  return currentState;
};

export const useSetupSimbaStore = (simbaClient: SimbaClient) => {
  const [state, dispatch] = useReducer(simbaReducer, {
    ...initialState,
    client: simbaClient,
  });

  return {
    state,
    dispatch,
    getters: {
      user: userReducer.getters(state.user),
      video: videoReducer.getters(state.video),
      remote: remoteReducer.getters(state.remote, state),
    },
  };
};

export const SimbaProvider = ({ children }: { children: ReactNode }) => {
  const simbaClient = useMemo(() => new SimbaClient(), []);
  const simbaStore = useSetupSimbaStore(simbaClient);
  const { addTrigger, removeTrigger } = useDebug();
  const dispatch = simbaStore.dispatch;

  useEffect(() => {
    // Temporary fix to make sure the simbaClient has a up to date simbaStore.
    simbaClient.setSimbaStore(simbaStore);
  }, [simbaClient, simbaStore]);

  useEffect(() => {
    addTrigger('simba_error_disconnected', () => {
      simbaClient.emit('info', 'connection.disconnected');
    });
    addTrigger('simba_error_kicked', () => {
      simbaClient.emit('error', new Error('connection.kicked'));
    });
    addTrigger('simba_error_double_login', () => {
      simbaClient.emit('error', new Error('connection.double_login'));
    });
    addTrigger('simba_error_timeout', () => {
      simbaClient.emit('error', new Error('connection.timeout'));
    });
    addTrigger('simba_error_inactivity', () => {
      simbaClient.emit('error', new Error('connection.inactivity'));
    });

    addTrigger('store_start_onboarding', () => {
      dispatch({
        type: OnboardingActionType.SET_ACTIVE,
        active: true,
      });
    });

    return () => {
      removeTrigger('simba_error_disconnected');
      removeTrigger('simba_error_kicked');
      removeTrigger('simba_error_double_login');
      removeTrigger('simba_error_timeout');
      removeTrigger('simba_error_inactivity');
      removeTrigger('store_start_onboarding');
    };
  }, [simbaClient, dispatch, addTrigger, removeTrigger]);

  const connected = simbaStore.state.status.connected;
  const queueItems = simbaStore.state.queue.items;

  useEffect(() => {
    // on connected dispatch all queued actions
    if (connected) {
      queueItems.forEach((item) => {
        dispatch(item.action);
        dispatch({ type: QueueActionType.REMOVE, id: item.id });
      });
    }
  }, [connected, queueItems, dispatch]);

  return <SimbaStore.Provider value={simbaStore}>{children}</SimbaStore.Provider>;
};
