export enum StatusActionType {
  SET_CONNECTED = 'STATUS:SET_CONNECTED',
  SET_CONNECTING = 'STATUS:SET_CONNECTING',
  SET_CONNECTING_LONGER_THAN_EXPECTED = 'STATUS:SET_CONNECTING_LONGER_THAN_EXPECTED',
  SET_LOCKED = 'STATUS:SET_LOCKED',
  SET_VIDEO_LOADED = 'STATUS:SET_VIDEO_LOADED',
  SET_FIRST_GESTURE = 'STATUS:SET_FIRST_GESTURE',
  SET_CHANGING_NAME = 'STATUS:SET_CHANGING_NAME',
  RESET = 'STATUS:RESET',
}

const initialState: StatusState = {
  connected: false,
  connecting: false,
  connectingLongerThanExpected: false,
  locked: false,
  videoLoaded: false,
  firstGesture: false,
  changingName: false,
};

export type StatusState = {
  connected: boolean;
  connecting: boolean;
  connectingLongerThanExpected: boolean;
  locked: boolean;
  videoLoaded: boolean;
  firstGesture: boolean; // To toggle audio when the user clicks the screen
  changingName: boolean;
};

export type StatusAction =
  | {
      type:
        | StatusActionType.SET_CONNECTED
        | StatusActionType.SET_CONNECTING
        | StatusActionType.SET_CONNECTING_LONGER_THAN_EXPECTED
        | StatusActionType.SET_LOCKED
        | StatusActionType.SET_VIDEO_LOADED
        | StatusActionType.SET_FIRST_GESTURE;
      payload: boolean;
    }
  | {
      type: StatusActionType.SET_CHANGING_NAME;
      value: boolean;
    }
  | {
      type: StatusActionType.RESET;
    };

const reducer = (state: StatusState, action: StatusAction): StatusState => {
  switch (action.type) {
    case StatusActionType.SET_CONNECTED:
      return {
        ...state,
        connected: action.payload,
      };
    case StatusActionType.SET_CONNECTING:
      return {
        ...state,
        connecting: action.payload,
      };
    case StatusActionType.SET_CONNECTING_LONGER_THAN_EXPECTED:
      return {
        ...state,
        connectingLongerThanExpected: action.payload,
      };
    case StatusActionType.SET_LOCKED:
      return {
        ...state,
        locked: action.payload,
      };
    case StatusActionType.SET_VIDEO_LOADED:
      return {
        ...state,
        videoLoaded: action.payload,
      };
    case StatusActionType.SET_FIRST_GESTURE:
      return {
        ...state,
        firstGesture: action.payload,
      };
    case StatusActionType.SET_CHANGING_NAME:
      return {
        ...state,
        changingName: action.value,
      };
    case StatusActionType.RESET:
      return initialState;
    default:
      return state;
  }
};

export default { reducer, initialState } as const;
