import React, { useCallback } from 'react';

import RealtimeNotification from '../components/RealtimeNotification';
import UserBadge from '../components/UserBadge';
import { Member } from '../simba/types';
import { useGetUserNameAndColor } from './actions';

export type SimpleNotificationBase = {
  id: number;
  message?: React.ReactNode;
  icon?: 'shock' | 'link' | 'private';
  bgColor?: string;
};

export type SimpleNotification = Omit<SimpleNotificationBase, 'id'>;

interface NotificationsData {
  notifications: SimpleNotificationBase[];

  addNotification: (notification: SimpleNotification) => void;
  removeNotification: (id: number) => void;
  clearNotifications: () => void;
}

const RealtimeNotificationsContext = React.createContext<NotificationsData>(
  {} as NotificationsData
);

const RealtimeNotificationsProvider: React.FC = ({ children }) => {
  const [currentId, setCurrentId] = React.useState(0);
  const [notifications, setNotifications] = React.useState<SimpleNotificationBase[]>([]);
  const addNotification = React.useCallback(
    (notification: SimpleNotification) => {
      setNotifications((notifications) => [
        ...notifications,
        {
          ...notification,
          id: currentId,
        },
      ]);
      setCurrentId(currentId + 1);
    },
    [setNotifications, currentId]
  );
  const removeNotification = React.useCallback(
    (id: number) => {
      setNotifications((notifications) => notifications.filter((n) => n.id !== id));
    },
    [setNotifications]
  );
  const clearNotifications = React.useCallback(() => {
    setNotifications([]);
  }, [setNotifications]);

  return (
    <RealtimeNotificationsContext.Provider
      value={{
        notifications,
        addNotification,
        removeNotification,
        clearNotifications,
      }}
    >
      <div className="fixed z-40 flex flex-col items-center justify-center mx-auto -translate-x-1/2 top-1/4 left-1/2">
        {notifications.slice(0, 5).map((notification) => (
          <RealtimeNotification
            key={notification.id}
            notificationId={notification.id}
            message={notification.message}
            icon={notification.icon}
            bgColor={notification.bgColor}
          />
        ))}
      </div>
      {children}
    </RealtimeNotificationsContext.Provider>
  );
};

const useNotifications = () => React.useContext(RealtimeNotificationsContext);

const useNotify = () => {
  const { addNotification } = useNotifications();
  return addNotification;
};

export type NotifyWithMember = (
  icon: 'link' | 'shock' | undefined,
  message: string,
  member?: Member | string,
  bgColor?: string
) => void;

const useNotifyWithMember = (): NotifyWithMember => {
  const { addNotification } = useNotifications();
  const getUserNameAndColor = useGetUserNameAndColor();

  return useCallback(
    (
      icon: 'link' | 'shock' | undefined,
      message: string,
      member?: Member | string,
      bgColor?: string
    ) => {
      const { liveblocksMember, name, color } = getUserNameAndColor(
        typeof member === 'string' ? member : member?.displayname,
        member
      );

      if (liveblocksMember?.presence?.joined || !liveblocksMember) {
        addNotification({
          message: (
            <>
              <UserBadge color={color} name={name} />
              {message}
            </>
          ),
          icon,
          bgColor,
        });
      }
    },
    [addNotification, getUserNameAndColor]
  );
};

export {
  useNotifications,
  RealtimeNotificationsProvider as NotificationsProvider,
  useNotify,
  useNotifyWithMember,
};
