import clsx from 'clsx';
import React, { useEffect } from 'react';
import { createPortal } from 'react-dom';

import useCopyToClipboard from '../../../hooks/useCopyToClipboard';
import styles from './FdocItemContextMenu.module.scss';

/**
 * FdocItemContextMenu is a component that displays a context menu when the user right-clicks on a list item.
 * It provides options to open the item's source URL and to delete the item. The menu is dismissed when
 * the user clicks outside of it or presses the Escape key.
 *
 * @param onGoToSourceClick - a callback function that is called when the user clicks the "Open source URL" button
 * @param onDeleteClick - a callback function that is called when the user clicks the "Delete" button
 * @param dismissContextMenu - a callback function that is called when the context menu needs to be dismissed
 * @param clientX - the x-coordinate of the mouse cursor at the time of the right-click
 * @param clientY - the y-coordinate of the mouse cursor at the time of the right-click
 */
const FdocItemContextMenu: React.FC<{
  onGoToSourceClick: (e: React.MouseEvent) => void;
  onDeleteClick: (e: React.MouseEvent) => void;
  dismissContextMenu: () => void;
  clientX: number;
  clientY: number;
  selectionMode?: boolean;
  selected?: boolean;
  hideDelete?: boolean;
  canDelete?: boolean;
  sourceUrl?: string;
}> = ({
  onGoToSourceClick,
  onDeleteClick,
  dismissContextMenu,
  clientX,
  clientY,
  selectionMode = false,
  selected = false,
  hideDelete = false,
  canDelete,
  sourceUrl,
}) => {
  // Use the useEffect hook to add event listeners for the "keydown" and "click" events
  useEffect(() => {
    const handleKeyPress = (e: KeyboardEvent) => {
      // If the key that was pressed is the Escape key, dismiss the context menu
      if (e.key === 'Escape') {
        dismissContextMenu();
      }
    };

    document.addEventListener('keydown', handleKeyPress);
    // we listen directly to document click because we want to dismiss the context menu
    // even if the user clicks on the context menu itself
    document.addEventListener('click', dismissContextMenu);

    const t = setTimeout(() => {
      document.addEventListener('contextmenu', dismissContextMenu);
    }, 10);

    return () => {
      document.removeEventListener('keydown', handleKeyPress);
      document.removeEventListener('click', dismissContextMenu);
      document.removeEventListener('contextmenu', dismissContextMenu);

      t && clearTimeout(t);
    };
  }, [dismissContextMenu]);

  const [, copy] = useCopyToClipboard();

  const handleCopySourceUrl = (e: React.MouseEvent) => {
    e.stopPropagation();

    if (!sourceUrl) return;

    copy(sourceUrl);
    dismissContextMenu();
  };

  const portalTarget = document.getElementById('tooltip-portal');
  const element = (
    <div
      className={styles.item__context_menu}
      style={{
        top: clientY,
        left: clientX,
      }}
    >
      {sourceUrl && (
        <>
          <button
            className={styles.item__context_menu__button}
            onClick={onGoToSourceClick}
            data-testid="item-context-menu-go-to-source-button"
          >
            Go to original URL
          </button>
          <button
            className={styles.item__context_menu__button}
            onClick={handleCopySourceUrl}
            data-testid="item-context-menu-copy-source-button"
          >
            Copy original URL
          </button>
        </>
      )}
      {(selected || !selectionMode) && !hideDelete && (
        <button
          className={clsx(styles.item__context_menu__button, styles.item__context_menu__button_red)}
          onClick={onDeleteClick}
          data-testid="item-context-menu-delete-button"
        >
          {!canDelete ? 'Remove' : 'Delete'}
        </button>
      )}
    </div>
  );

  return portalTarget ? createPortal(element, portalTarget) : element;
};

export default FdocItemContextMenu;
