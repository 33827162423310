import React from 'react';

import { CursorProps } from '../../hooks/cursor';
import { getTextColor } from '../../utils/color';

const CursorEphemeral: React.FC<CursorProps> = ({ color, style, ...props }) => {
  const outlineColor = getTextColor(color);

  return (
    <svg
      width="52"
      height="53"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        ...style,
        marginTop: '-0.77rem',
        marginLeft: '-7px',
      }}
      {...props}
    >
      <g filter="url(#a)">
        <circle cx="24" cy="24" r="18" fill={color} />
        <circle cx="24" cy="24" r="16" stroke={outlineColor} strokeWidth="4" />
      </g>
      <defs>
        <filter
          id="a"
          x="3"
          y="4"
          width="44"
          height="44"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="1" dy="2" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_319_26" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow_319_26" result="shape" />
        </filter>
      </defs>
    </svg>
  );
};

export default CursorEphemeral;
