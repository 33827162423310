import { checkIfCommServerRunning, createBroadcastService } from '@todesktop/client-comm-server';
import { app, nativeWindow, platform } from '@todesktop/client-core';
import { Auth } from 'aws-amplify';
import { Buffer } from 'buffer';
import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router';

import awsExports from '../aws-exports';
import { useAuth } from './auth';

const ports = [23435, 39213];

export const isInDesktop = platform.todesktop.isDesktopApp();

export type DesktopAuthDeepLink = {
  idToken: string;
  iatIdToken: string;
  accessToken: string;
  iatAccessToken: string;
  refreshToken: string;
  userSub: string;
};

export const useOpenInDesktop = () => {
  const navigate = useNavigate();

  return useCallback(
    async (sessionId: string) => {
      if (isInDesktop) return;

      const hasDesktop = await checkIfCommServerRunning(ports);

      if (!hasDesktop) return;

      const { broadcast } = createBroadcastService(ports);

      const response = await broadcast({ type: 'open-session', sessionId });

      if (response?.success) {
        navigate(`/handoff/${sessionId}`);
      }
    },
    [navigate]
  );
};

export const useHandleBroadcast = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!isInDesktop) return;

    const { handleBroadcast } = createBroadcastService(ports);
    const unsubscribe = handleBroadcast(async (message) => {
      if (message.type === 'open-session') {
        navigate(`/b/${message.sessionId}`);
        await nativeWindow.show();
        await app.focus({
          steal: true,
        });
      }
    });

    return unsubscribe;
  }, [navigate]);
};

export const calculateClockDrift = (iatAccessToken: number, iatIdToken: number) => {
  const now = Math.floor(Date.now() / 1000);
  const iat = Math.min(iatAccessToken, iatIdToken);
  return now - iat;
};

export const useHandleDeeplinks = () => {
  const { isLoggedIn, user, authProcessing } = useAuth();

  useEffect(() => {
    if (authProcessing || !isLoggedIn) return;
    if (user?.attributes?.email) return;

    // This will force a token refresh and update all local data
    Auth.currentAuthenticatedUser({
      bypassCache: true, // If set to true, this call will send a request to Cognito to get the latest user data
    });
  });

  useEffect(() => {
    if (!isInDesktop) return;

    const listener = (_: any, event: any) => {
      event.preventDefault();

      if (!event.url) return;

      const url = new URL(event.url);
      const type = url.searchParams.get('type');
      if (type !== 'desktop-auth') return;

      const unparsed = url.searchParams.get('data');
      if (!unparsed) return;

      const data = JSON.parse(
        Buffer.from(unparsed, 'base64').toString('utf8')
      ) as DesktopAuthDeepLink;

      if (type !== 'desktop-auth') return;

      const clockDrift = calculateClockDrift(Number(data.iatAccessToken), Number(data.iatIdToken));

      const storagePrefix = `CognitoIdentityServiceProvider.${awsExports.aws_user_pools_web_client_id}`;
      const tokenPrefix = `${storagePrefix}.${data.userSub}`;

      localStorage.setItem(`${storagePrefix}.LastAuthUser`, data.userSub);
      localStorage.setItem(`${tokenPrefix}.idToken`, data.idToken);
      localStorage.setItem(`${tokenPrefix}.accessToken`, data.accessToken);
      localStorage.setItem(`${tokenPrefix}.refreshToken`, data.refreshToken);
      localStorage.setItem(`${tokenPrefix}.clockDrift`, String(clockDrift));

      // reload the app
      window.location.reload();
    };

    app.addEventListener('openProtocolURL', listener);

    return () => {
      app.removeEventListener('openProtocolURL', listener);
    };
  }, []);
};

export const useOpenOnBrowser = () => {
  return useCallback(async (url: string) => {
    if (!isInDesktop) return;

    await platform.os.openURL(url);
  }, []);
};
