import { CognitoUser } from '@aws-amplify/auth';
import { ICredentials } from '@aws-amplify/core';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { Auth, Hub } from 'aws-amplify';
import React, { useContext, useEffect, useMemo, useState } from 'react';

type CognitoUserAmplify = ReturnType<typeof useAuthenticator>['user'];
type CognitoUserSession = Awaited<ReturnType<typeof Auth.currentSession>>;

interface SandboxAuthContext {
  authProcessing: boolean; // whether authentication is in progress
  authDone: boolean; // equivalent to !authProcessing
  isLoggedIn: boolean; // the user is a logged in user (equivalent to !authProcessing && !guest)
  isGuest: boolean; // the user is a guest user (equivalent to !authProcessing && !isLoggedIn)
  identityId: string; // every user, both guest and loggedIn have one
  user?: CognitoUserAmplify; // the CognitoUser object, which is null if user is a guest
  groups: string[]; // the list of roles / groups the user is in (e.g. ['admin', 'beta'])
  route?: string;
  jwtToken?: string;
  signOut?: (data?: any) => void;
  userId?: string;
}

const AuthContext = React.createContext<SandboxAuthContext>({
  authProcessing: true,
} as SandboxAuthContext);

/*
 * This is a Wrapper that provides an auth context on top of Amplify's own Authenticator context.
 * Our own authentication needs require identifying each user uniquely. The way we do that for guest users
 * is by fetching the Amplify credentials and extracting an identityId from there. It exists for both
 * guest and logged in users.
 *
 * This wrapper also renders the overlay for the entire duration of the authentication flow to
 * avoid to even render the children before we have the authentication details we need.
 *
 * This component must be nested under Amplify's Authenticator.Provider
 */
export const SandboxAuthProvider: React.FC = ({ children }) => {
  const [credentials, setCredentials] = useState<ICredentials>();
  const { user, route, signOut, isPending } = useAuthenticator((context) => [
    context.user,
    context.route,
  ]);
  const [jwtToken, setJwtToken] = useState<string>();

  useEffect(() => {
    // credentials exist for both guest and logged in users
    // we pull credentials mostly for the guests, because without that
    // we would have no way of uniquely identifying a guest user
    Auth.currentUserCredentials()
      .then((_credentials) => {
        setCredentials(_credentials);
      })
      .catch((err: unknown) => console.error(err));
  }, []);

  useEffect(() => {
    const refreshToken = async () => {
      try {
        const cognitoUser: CognitoUser = await Auth.currentAuthenticatedUser();
        const currentSession = await Auth.currentSession();
        cognitoUser.refreshSession(
          currentSession.getRefreshToken(),
          (err, session: CognitoUserSession) => {
            if (err) {
              console.error(err);
              return;
            }
            const accessToken = session.getAccessToken();
            const jwt = accessToken.getJwtToken();
            setJwtToken(jwt);
          }
        );
      } catch (e) {
        console.log('Unable to refresh Token', e);
      }
    };

    const interval = setInterval(refreshToken, 5 * 60 * 1000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const getJwtToken = async () => {
      try {
        const token = await Auth.currentSession();
        setJwtToken(token.getAccessToken().getJwtToken());
      } catch (e) {
        console.error(e);
      }
    };

    getJwtToken();

    // Check: https://docs.amplify.aws/lib/auth/auth-events/q/platform/js/
    // Every event seems to be worthy of a re-fetch of the token since it could potentially change
    Hub.listen('auth', getJwtToken);

    return () => Hub.remove('auth', getJwtToken);
  }, []);

  const identityId = useMemo((): string => {
    return credentials?.identityId || '';
  }, [credentials]);

  // the auth is only done once Amplify's implicit auth is complete
  // and we have retrieved the identityId from the credentials
  const authProcessing = !!isPending || identityId === '';
  const authDone = !authProcessing;

  // e.g. ['admin', 'beta']
  const groups = (user?.getSignInUserSession()?.getAccessToken()?.payload?.['cognito:groups'] ||
    []) as string[];

  const isLoggedIn = !authProcessing && !!user;
  const isGuest = !authProcessing && !user;

  return (
    <>
      <AuthContext.Provider
        value={{
          authProcessing,
          authDone,
          isLoggedIn,
          identityId,
          isGuest,
          user,
          route,
          jwtToken,
          signOut,
          groups,
        }}
      >
        {children}
      </AuthContext.Provider>
    </>
  );
};

/**
 * This hook abstracts away the context for (slightly) nicer access
 */
export const useAuth = () => {
  const auth = useContext(AuthContext);
  return auth;
};
