import React from 'react';

import { ReactComponent as Fabric } from '../../assets/images/icons/Fabric.svg';
import Skeleton from '../../components/Skeleton/Skeleton';
import SkeletonStructure from '../../components/SkeletonStructure/SkeletonStructure';

const ListInviteSkeleton: React.FC = () => {
  return (
    <SkeletonStructure cols bgColor="#f4f4f7">
      <SkeletonStructure padding="20px 26px" gap={4}>
        <Fabric style={{ width: 30, height: 42, marginRight: 31 }} />
        <Skeleton width={200} height={30} borderRadius={6} />
        <Skeleton width={30} height={30} borderRadius={6} />
        <Skeleton width={30} height={30} circle style={{ marginLeft: 8 }} />
        <Skeleton width={100} height={30} borderRadius={6} />
      </SkeletonStructure>
      <SkeletonStructure
        padding="30px 0 0 91px"
        expand
        style={{
          alignItems: 'flex-start',
        }}
      >
        <SkeletonStructure
          cols
          bgColor="white"
          width={416}
          height={600}
          style={{
            border: '1px solid #cacaca',
            borderRadius: 16,
          }}
        >
          <SkeletonStructure padding={20} spaceBetween>
            <Skeleton width={100} height={24} borderRadius={4} />
            <Skeleton width={151.5} height={30} borderRadius={6} />
          </SkeletonStructure>
          <SkeletonStructure spaceBetween padding="0 20px 20px 20px">
            <Skeleton width={51} height={20} borderRadius={6} />
            <Skeleton width={30} height={30} circle />
          </SkeletonStructure>
        </SkeletonStructure>
        <SkeletonStructure expand padding="40px 158px">
          <SkeletonStructure
            cols
            style={{
              alignItems: 'flex-start',
            }}
          >
            <Skeleton width={340} height={20} borderRadius={6} />
            <Skeleton width={386} height={20} borderRadius={6} />
            <Skeleton width={392} height={20} borderRadius={6} />
            <Skeleton width={320} height={20} borderRadius={6} />
            <Skeleton width={216} height={48} borderRadius={8} style={{ marginTop: 40 }} />
          </SkeletonStructure>
        </SkeletonStructure>
      </SkeletonStructure>
    </SkeletonStructure>
  );
};

export default ListInviteSkeleton;
