import React from 'react';

import InternalError from './InternalError';

type Props = {
  children: React.ReactNode;
};

type State = {
  error: Error | null;
  errorInfo: React.ErrorInfo | null;
};

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      error: null,
      errorInfo: null,
    };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    this.setState({
      error,
      errorInfo,
    });
  }

  static getDerivedStateFromError(error: Error) {
    return { error };
  }

  render() {
    const { error, errorInfo } = this.state;

    if (!this.state.errorInfo && !this.state.error) {
      return this.props.children || null;
    }

    return (
      <InternalError errorDescription={error?.message} errorStack={errorInfo?.componentStack} />
    );
  }
}

export default ErrorBoundary;
