export enum MouseActionType {
  MOUSE_CURSOR = 'STATE:MOUSE:CURSOR',
}

const initialState: MouseState = {
  cursor: 'default',
};

export type MouseState = {
  cursor: string;
};

export type MouseAction = {
  type: MouseActionType.MOUSE_CURSOR;
  payload: string;
};

const reducer = (state: MouseState, action: MouseAction): MouseState => {
  switch (action.type) {
    case MouseActionType.MOUSE_CURSOR:
      return {
        ...state,
        cursor: action.payload,
      };
    default:
      return state;
  }
};

export default { reducer, initialState } as const;
