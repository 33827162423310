import {
  Client,
  Conversation,
  ConversationUpdateReason,
  Message,
  State,
} from '@twilio/conversations';
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { ConversationSidResponse, ConversationTokenResponse } from '../types/pumbaa';
import { useAuth } from './auth';
import log from './log';

interface IConversationContext {
  identity: string | null;
  messages: Message[];
  processed: boolean;
  sendMessage: (text: string) => void;
  readMessages: () => void;
  allRead: boolean;
  setJwtToken: (token?: string) => void;
  setUniqueId: (id: string | null) => void; // the cognito identity ID, for unauntheticated users, and currently used for identifying individual
  // users in a browser session
  setName: (name: string | null) => void;
  setCurrentChat: (sessionId: string | null) => void;

  loadMoreMessages: () => void;
}

const ConversationContext = createContext<IConversationContext>({} as IConversationContext);

const useConversation = () => useContext(ConversationContext);

const ConversationProvider: React.FC = ({ children }) => {
  const [ready, setReady] = useState(false);
  const [processed, setProcessed] = useState(false);
  const [forceRetoken, setForceRetoken] = useState(false);
  const [client, setClient] = useState<Client | null>(null);
  const [messages, setMessages] = useState<Message[]>([]);
  const [conversation, setConversation] = useState<Conversation | null>(null);
  const [sessionId, setSessionId] = useState<string | null>(null);
  const [conversationSid, setConversationSid] = useState<string | null>(null);
  const [retriveConversationSid, setRetriveConversationSid] = useState(false);
  const [lastReadMessageIndex, setLastReadMessageIndex] = useState<number | null>(null);
  const [loadingMessages, setLoadingMessages] = useState(false);

  const [fullFailures, setFullFailures] = useState(0);

  useEffect(() => {
    // This comment is for an empty PR to update amplify env variables, ignore it, please
    log.debug('twilio-conversation::init_env', process.env.REACT_APP_PUMBAA_URL);
  }, []);

  // For authenticated users
  const [jwtToken, setJwtToken] = useState<string | undefined>(undefined);
  // For unauntheticated users
  const [uniqueId, setUniqueId] = useState<string | null>(null);
  // Temporary storage of the user's name so we can read them from the dashboard
  const [name, setName] = useState<string | null>(null);

  const identity = uniqueId;

  const requestHeaders = useMemo(() => {
    const headers: {
      [key: string]: string;
    } = {
      'Content-Type': 'application/json',
    };
    if (jwtToken) headers['Authorization'] = `Bearer ${jwtToken}`;
    if (!jwtToken && uniqueId) headers['X-Identity'] = uniqueId;

    return headers;
  }, [jwtToken, uniqueId]);

  useEffect(() => {
    (async () => {
      if (!requestHeaders['X-Identity'] && !requestHeaders['Authorization']) {
        if (client) {
          client?.shutdown();
          setClient(null);
        }
        return;
      }

      if (fullFailures > 3) {
        log.error('twilio-conversation::Too many failures, giving up');
        return;
      }

      if (client && !ready && !forceRetoken) return;

      // if there is a jwtToken the client?.user.identity shouldn't be equal to the X-Identity header
      // otherwise the client?.user.identity should be equal to the X-Identity header
      const identity = client?.user.identity;
      const thereIsAToken = !!requestHeaders['Authorization'];
      const headerIdentity = `anon-${requestHeaders['X-Identity']}`;

      if (
        client &&
        ((thereIsAToken && identity !== headerIdentity) ||
          (!thereIsAToken && identity === headerIdentity)) &&
        !forceRetoken
      )
        return;

      setForceRetoken(false);
      setReady(false);
      setProcessed(false);

      const url = `${process.env.REACT_APP_PUMBAA_URL}/conversations/token`;

      const data = await fetch(url, {
        method: 'GET',
        headers: requestHeaders,
      });

      if (data.status === 500) {
        setFullFailures((prev) => prev + 1);
        return;
      }
      if (data.status !== 200) return;

      const response: ConversationTokenResponse = await data.json();

      const { token } = response;

      try {
        client?.removeAllListeners();
        client?.shutdown();
        setClient(new Client(token));
      } catch (e) {
        setFullFailures((prev) => prev + 1);
      }

      setFullFailures(0);
    })();
  }, [client, ready, forceRetoken, fullFailures, requestHeaders]);

  useEffect(() => {
    if (!client) return;

    const onStageChanged = (state: State) => {
      if (state === 'initialized') {
        setReady(true);
        setClient(client);
      } else {
        setReady(false);
      }
    };

    const onRetoken = () => {
      setForceRetoken(true);
      setReady(false);
      setProcessed(false);
    };

    const onFailure = () => {
      client?.shutdown();
      setClient(null);
      setFullFailures((prev) => prev + 1);

      onRetoken();
    };

    client.on('stateChanged', onStageChanged);
    client.on('tokenAboutToExpire', onRetoken);
    client.on('tokenExpired', onRetoken);
    client.on('initFailed', onFailure);
    client.on('connectionError', onFailure);

    return () => {
      client.off('stateChanged', onStageChanged);
      client.off('tokenAboutToExpire', onRetoken);
      client.off('tokenExpired', onRetoken);
      client.off('initFailed', onFailure);
      client.off('connectionError', onFailure);
    };
  }, [client]);

  useEffect(() => {
    (async () => {
      if (!client || !conversationSid || !ready) return;

      try {
        const conversation = await client.getConversationBySid(conversationSid);
        setConversation(conversation);
        const messages = await conversation.getMessages(50);
        setMessages(messages.items); // get only the first 50 at setup
        setProcessed(true);
      } catch (error) {
        log.error('twilio-conversations::useConversation', 'Error getting conversation', error);
        setConversation(null);
        setForceRetoken(true);
      }
    })();

    return () => {
      setProcessed(false);
    };
  }, [client, conversationSid, ready]);

  const loadMoreMessages = useCallback(async () => {
    if (!conversation || !client || !ready) return;
    setLoadingMessages(true);
  }, [conversation, client, ready]);

  useEffect(() => {
    if (!conversation || !client || !ready || !loadingMessages) return;

    const loadMessages = async () => {
      if (!loadingMessages) return;
      setLoadingMessages(false);

      const newMessages = await conversation.getMessages(50, messages[0].index);

      // set and filter duplicates by sid
      setMessages((prev) =>
        [...newMessages.items, ...prev].filter(
          (v, i, a) => a.findIndex((t) => t.sid === v.sid) === i
        )
      );
    };

    loadMessages();
  }, [client, conversation, loadingMessages, messages, ready]);

  useEffect(() => {
    if (!conversation || !ready) {
      setMessages([]);
      setLastReadMessageIndex(null);
      return;
    }

    setLastReadMessageIndex(conversation.lastReadMessageIndex);

    const onMessageAdded = (message: Message) => {
      setMessages((messages) => [...messages, message]);
    };

    const onUpdated = (update: {
      conversation: Conversation;
      updateReasons: ConversationUpdateReason[];
    }) => {
      // this is not a perfect solution,
      // this means the conversation is updated/mutated, but we don't know what was updated
      conversation._update(update.conversation);

      // instead we are manually updating certain properties as separate states, to avoid having everything cause a re-render
      update.updateReasons.forEach((reason) => {
        switch (reason) {
          case 'lastReadMessageIndex':
            setLastReadMessageIndex(conversation.lastReadMessageIndex);
            break;
          default:
            break;
        }
      });
    };

    conversation.on('messageAdded', onMessageAdded);
    conversation.on('updated', onUpdated);

    return () => {
      conversation.off('messageAdded', onMessageAdded);
      conversation.off('updated', onUpdated);
    };
  }, [conversation, ready]);

  const sendMessage = useCallback(
    async (text: string) => {
      if (!conversation || !ready || text.trim().length === 0) return;

      await conversation.sendMessage(text, {
        uniqueId,
        user_name: name,
      });
    },
    [conversation, ready, name, uniqueId]
  );

  const readMessages = useCallback(async () => {
    if (!conversation || !ready) return;

    await conversation.setAllMessagesRead();
  }, [conversation, ready]);

  const lastNonOwnMessage = useMemo(() => {
    return messages
      .slice(0)
      .reverse()
      .find((message) => message.author !== identity);
  }, [messages, identity]);

  const allRead = useMemo(() => {
    return (lastReadMessageIndex ?? 0) >= (lastNonOwnMessage ? lastNonOwnMessage.index : 0);
  }, [lastReadMessageIndex, lastNonOwnMessage]);

  useEffect(() => {
    if (!retriveConversationSid) return;

    (async () => {
      if (!requestHeaders['X-Identity'] && !requestHeaders['Authorization']) {
        return;
      }

      const url = `${process.env.REACT_APP_PUMBAA_URL}/conversations/${sessionId}`;

      const data = await fetch(url, {
        method: 'GET',
        headers: requestHeaders,
      });

      if (data.status !== 200) return;

      setRetriveConversationSid(false);

      const response: ConversationSidResponse = await data.json();

      const { conversationSid, token } = response;

      setConversationSid(conversationSid);
      setClient((c) => {
        if (!c) return new Client(token);
        c.updateToken(token);
        return c;
      });
    })();
  }, [retriveConversationSid, requestHeaders, sessionId]);

  const setCurrentChat = useCallback(async (sessionId: string | null) => {
    if (!sessionId) {
      setConversationSid(null);
      return;
    }

    setSessionId(sessionId);
    setRetriveConversationSid(true);
  }, []);

  const { identityId, isLoggedIn, user, jwtToken: authJwtToken } = useAuth();

  useEffect(() => {
    if (!isLoggedIn) return;

    setJwtToken(authJwtToken);
    setUniqueId(identityId);
  }, [isLoggedIn, user, setJwtToken, identityId, setUniqueId, authJwtToken]);

  return (
    <ConversationContext.Provider
      value={{
        identity,
        messages,
        processed,
        sendMessage,
        readMessages,
        allRead,
        setUniqueId,
        setJwtToken,
        setName,
        setCurrentChat,
        loadMoreMessages,
      }}
    >
      {children}
    </ConversationContext.Provider>
  );
};

export { useConversation, ConversationProvider };
