import { Auth } from 'aws-amplify';

const TIMON_ANALYTICS_BASE_URL = process.env.REACT_APP_TIMON_ANALYTICS_URL as string;
if (!TIMON_ANALYTICS_BASE_URL) {
  throw new Error(
    'REACT_APP_TIMON_ANALYTICS_URL env variable is not set. It should be the base URL of the Analytics API (without trailing slash)'
  );
}

type APIResponseStatus = 'success' | 'error' | 'fail';

type APIResponseWrapper<T> = {
  status: APIResponseStatus;
  data: T;
};

export interface Session {
  sessionId: string;
  gatheringId?: string;
  startedAt: Date;
  endedAt: Date | null;
  status: string;
  durationInMinutes?: number;
  maxNumParticipants?: number;
  eventCounts?: { [event: string]: number };
  totalIndividualMinutes?: number;
  liveParticipants?: number;
}

export type UserEvent = {
  t: Date;
  type: EventType;
};

export type EventsByUser = {
  [user: string]: UserEvent[];
};

export type EventType =
  | 'USER_HAS_JOINED_LOBBY'
  | 'USER_HAS_JOINED_VBROWSER'
  | 'USER_HAS_LEFT'
  | 'USER_HAS_HARD_DISCONNECTED'
  | 'USER_HAS_BEEN_INACTIVE'
  | 'USER_HAS_MUTED'
  | 'USER_HAS_UNMUTED'
  | 'USER_HAS_CLICKED_INVITE'
  | 'USER_HAS_DEAFENED'
  | 'USER_HAS_UNDEAFENED'
  | 'USER_HAS_TAKEN_CONTROL'
  | 'USER_HAS_RELEASED_CONTROL'
  | 'USER_HAS_ENABLED_WEBCAM'
  | 'USER_HAS_DISABLED_WEBCAM'
  | 'USER_HAS_KICKED'
  | 'USER_HAS_BEEN_KICKED'
  | 'USER_HAS_SENT_CHAT'
  | 'USER_HAS_RENAMED_SELF'
  | 'USER_HAS_ENABLED_PRIVATE_MODE'
  | 'USER_HAS_DISABLED_PRIVATE_MODE';

export type TimeInterval = 'hour' | 'day' | 'week' | 'month';

export type MetricType = 'NUM_PARTICIPANTS';

export type Metric = {
  t: Date;
  v: number;
};

export type TodayStats = {
  browseTimeToday: number;
  browseTimeYesterday: number;
  duration: number;
  durationYesterday: number;
  sessionCount: number;
  sessionCountYesterday: number;
  liveSessionCount: number;
  liveParticipantCount: number;
};

class SandboxAnalyticsClient {
  private identityId: string = '';

  private async getCognitoIdToken(): Promise<string> {
    const user = await Auth.currentSession();
    const idToken = user.getIdToken();
    return idToken.getJwtToken();
  }

  private async getIdentityId(): Promise<string> {
    if (this.identityId) {
      return this.identityId;
    }
    const credentials = await Auth.currentCredentials();
    this.identityId = credentials.identityId;
    return this.identityId;
  }

  async getSessions(
    from: number = 0,
    size: number = 20
  ): Promise<{ sessions: Session[]; total: number }> {
    const token = await this.getCognitoIdToken();
    const endpoint = '/sessions';
    const url = new URL(TIMON_ANALYTICS_BASE_URL + endpoint);
    url.searchParams.append('from', from.toString());
    url.searchParams.append('size', size.toString());
    const res = await fetch(url.toString(), {
      headers: { Authorization: token },
    });
    if (!res.ok) {
      console.error('Failed to get sessions');
    }
    const data = (await res.json()) as APIResponseWrapper<{ sessions: Session[]; total: number }>;
    const sessions = (data?.data?.sessions || []).map((s) => {
      return {
        ...s,
        startedAt: new Date(s.startedAt),
        endedAt: s.endedAt ? new Date(s.endedAt) : null,
      };
    });
    const total = data?.data?.total || 0;
    return { sessions, total };
  }

  async getSessionById(sessionId: string): Promise<Session> {
    const token = await this.getCognitoIdToken();
    const endpoint = `/sessions/${sessionId}`;
    const url = TIMON_ANALYTICS_BASE_URL + endpoint;
    const res = await fetch(url, {
      headers: { Authorization: token },
    });
    if (!res.ok) {
      console.error('Failed to get sessions');
    }
    const data = (await res.json()) as APIResponseWrapper<Session>;
    const sessions = data?.data || [];
    return sessions;
  }

  async getEventsBySessionId(sessionId: string): Promise<EventsByUser> {
    const token = await this.getCognitoIdToken();
    const endpoint = `/sessions/${sessionId}/events`;
    const url = TIMON_ANALYTICS_BASE_URL + endpoint;
    const res = await fetch(url, {
      headers: { Authorization: token },
    });
    if (!res.ok) {
      console.error('Failed to get sessions');
    }
    const data = (await res.json()) as APIResponseWrapper<{
      [user: string]: { t: string; type: EventType }[];
    }>;
    const eventsByUser = (data?.data || {}) as { [user: string]: { t: string; type: EventType }[] };

    // deserialize datetimes
    const out: EventsByUser = {};
    for (const [user, events] of Object.entries(eventsByUser)) {
      const e: UserEvent[] = [];
      for (const o of events) {
        e.push({
          t: new Date(o.t),
          type: o.type,
        });
      }
      out[user] = e;
    }

    return out;
  }

  async getMetricsBySessionId(sessionId: string, metricType: MetricType): Promise<Metric[]> {
    const token = await this.getCognitoIdToken();
    const endpoint = `/sessions/${sessionId}/metrics`;
    const url = new URL(TIMON_ANALYTICS_BASE_URL + endpoint);
    url.searchParams.append('type', metricType);
    const res = await fetch(url.toString(), {
      headers: { Authorization: token },
    });
    if (!res.ok) {
      console.error('Failed to get metrics for session');
    }
    const data = (await res.json()) as APIResponseWrapper<{ t: string; v: number }[]>;

    const out = (data?.data || []).map((e) => {
      return {
        t: new Date(e.t),
        v: e.v,
      };
    });

    return out;
  }

  async getSessionCountOverTime(interval: TimeInterval): Promise<Metric[]> {
    const token = await this.getCognitoIdToken();
    const endpoint = `/sessions/timeseries`;
    const url = new URL(TIMON_ANALYTICS_BASE_URL + endpoint);
    url.searchParams.append('interval', interval);
    const res = await fetch(url.toString(), {
      headers: { Authorization: token },
    });
    if (!res.ok) {
      console.error('Failed to get session count over time');
    }
    const data = (await res.json()) as APIResponseWrapper<{
      series: { t: string; v: number }[];
    }>;

    const out = (data?.data?.series || []).map((e) => {
      return {
        t: new Date(e.t),
        v: e.v,
      };
    });

    return out;
  }

  async getTodayStats(): Promise<TodayStats> {
    const token = await this.getCognitoIdToken();
    const endpoint = `/sessions/stats`;
    const url = new URL(TIMON_ANALYTICS_BASE_URL + endpoint);
    const res = await fetch(url.toString(), {
      headers: { Authorization: token },
    });
    if (!res.ok) {
      console.error('Failed to get stats for today');
    }
    const data = (await res.json()) as APIResponseWrapper<TodayStats>;
    return data?.data;
  }

  /*
   * @param sessionId - the browserId that the user is in (not the same as gatheringId)
   * @param type - the type of the event
   * @returns
   */
  async logEvent(sessionId: string, type: EventType): Promise<boolean> {
    const identityId = await this.getIdentityId();
    const endpoint = `/sessions/${sessionId}/events`;
    const url = TIMON_ANALYTICS_BASE_URL + endpoint;
    const res = await fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        event: type,
        userId: identityId,
      }),
    });
    if (!res.ok) {
      console.error('Failed to log session event');
    }
    return true;
  }
}

const analytics = new SandboxAnalyticsClient();

export default analytics;
