import { useBroadcastEvent, useEventListener } from '@liveblocks/react';
import clsx from 'clsx';
import getStroke from 'perfect-freehand';
import React, { useCallback, useEffect, useRef } from 'react';

import { CursorMode, PressuredPosition } from '../../hooks/cursor';
import { useConvertCoords } from '../../hooks/interaction';
import { useChangedTimeout } from '../../hooks/timeout';
import { useTwilio } from '../../hooks/twilio';
import { useSimbaStore } from '../../simba/store';
import { Position } from '../../types/global';

interface Props {
  position: Position;
  mode: CursorMode;
  drawing: boolean;
  color: string;
  userId: string;
  localClear: number;
  hasPressure: boolean;
  // This is to avoid having multiple components doing the same thing slightly differently
  isSelf?: boolean;
}

function getSvgPathFromStroke(stroke: number[][]) {
  if (!stroke.length) return '';

  const d = stroke.reduce(
    (acc, [x0, y0], i, arr) => {
      const [x1, y1] = arr[(i + 1) % arr.length];
      acc.push(x0, y0, (x0 + x1) / 2, (y0 + y1) / 2);
      return acc;
    },
    ['M', ...stroke[0], 'Q']
  );

  d.push('Z');
  return d.join(' ');
}

interface Path {
  // Unique generated id for the path
  id: string;
  d: string;
  active: boolean;
  lastChange: number;
  positions: TimedPosition[];
}

// Random ID, first 5 characters of userId, 5 random characters, and a timestamp
const randomId = (userId: string) =>
  userId.substring(0, 5) + Math.random().toString().substring(2, 7) + Date.now();

interface TimedPosition extends PressuredPosition {
  created_at: number;
}

export enum EphemeralEvents {
  ACTIVE_REFERENCE = 'ACTIVE_REFERENCE',
  LAST_REFERENCE = 'LAST_REFERENCE',
  CLEAR_LINES = 'CLEAR_LINES',
}

export type EphemeralEvent =
  | {
      type: EphemeralEvents.ACTIVE_REFERENCE;
      userId: string;
      positions: TimedPosition[];
    }
  | {
      type: EphemeralEvents.LAST_REFERENCE;
      userId: string;
      positions: TimedPosition[];
    }
  | {
      type: EphemeralEvents.CLEAR_LINES;
    };

const toSVGd = (
  positions: TimedPosition[],
  hasPressure: boolean,
  convertCoords: (position: TimedPosition) => TimedPosition
): string => {
  const stroke = getStroke(positions.map(convertCoords), {
    simulatePressure: !hasPressure,
  });
  return getSvgPathFromStroke(stroke);
};

const CursorEphemeral: React.FC<Props> = ({
  position,
  mode,
  drawing,
  color,
  userId,
  localClear,
  hasPressure,
  isSelf,
}) => {
  const simbaStore = useSimbaStore();
  const canBroadcast = isSelf && !simbaStore.getters.remote.isSelfPrivate;
  const { videoWidth, videoHeight } = simbaStore.state.video;
  const broadcast = useBroadcastEvent();
  const { remoteToDom } = useConvertCoords();

  const convertCoords = useCallback(
    (position: TimedPosition) =>
      ({
        ...position,
        ...remoteToDom(position.x, position.y),
      } as TimedPosition),
    [remoteToDom]
  );

  const { faceToFace } = useTwilio();

  const [externalClear, setExternalClear] = React.useState(0);
  const restartTransition = useChangedTimeout(20, [localClear, externalClear]);
  const noDelay = useChangedTimeout(300, [restartTransition], !restartTransition);

  useEffect(() => {
    setPaths((paths) => paths.map((path) => ({ ...path, active: false })));
  }, [localClear, externalClear]);

  const [paths, setPaths] = React.useState<Path[]>([]);
  const currentPathId = useRef('');

  const [lastPositions, setLastPositions] = React.useState<TimedPosition[] | undefined>(undefined);

  const overwritePositions = (
    original: TimedPosition[] | undefined,
    newPositions: TimedPosition[]
  ) => {
    // Assuming both arrays are sorted from oldest to newest
    // Essentially rips out positions that are inside the time of the new positions
    // Then join the new in the same spot, and return the result

    if (!original || !original.length) return newPositions;
    if (newPositions.length < 2) return original;

    const first = newPositions[0].created_at;
    const last = newPositions[newPositions.length - 1].created_at;

    const ripped = original.filter((p) => p.created_at < first || p.created_at > last);

    const closest = ripped.findIndex((p) => p.created_at > first);

    const untreated = closest
      ? [...ripped, ...newPositions]
      : [...ripped.slice(0, closest), ...newPositions, ...ripped.slice(closest)];

    // Clear any with same position or same time
    const cleared = untreated.reduce((acc, p) => {
      const index = acc.findIndex((p2) => p2.x === p.x && p2.y === p.y);
      if (index === -1) return [...acc, p];
      return acc.slice(0, index).concat(acc.slice(index + 1));
    }, [] as TimedPosition[]);

    return cleared;
  };

  useEventListener<EphemeralEvent>(({ event }) => {
    if (event.type === EphemeralEvents.ACTIVE_REFERENCE && event.userId === userId) {
      // This event has a maximum of 20 positions.
      // They are timed, so we need to cut any positions inside the timeframe in the lastPositions
      // And replace them with the new ones
      setLastPositions((lastPositions) => overwritePositions(lastPositions!, event.positions));
    } else if (event.type === EphemeralEvents.LAST_REFERENCE && event.userId === userId) {
      setPaths((paths) => {
        const lastPath = paths[paths.length - 1] || {
          id: randomId(userId),
          d: '',
          active: false,
          lastChange: 0,
          positions: [],
        };

        const positions = overwritePositions(!drawing ? lastPath.positions : [], event.positions);

        const correctPath = {
          ...lastPath,
          id: !drawing ? lastPath.id : randomId(userId),
          positions,
          d: toSVGd(positions, hasPressure, convertCoords),
        };

        return [
          // if not drawing all but last, else add new path
          ...(!drawing ? paths.slice(0, -1) : paths),
          correctPath,
        ];
      });
    } else if (event.type === EphemeralEvents.CLEAR_LINES) {
      setExternalClear((n) => n + 1);
    }
  });

  const cleanOldPaths = (path: Path) => {
    // Only remove the path with the ID provided if:
    // More than 10 seconds have passed since the last change
    // Or if noDelay is true
    if (noDelay || Date.now() - path.lastChange > 10000) {
      setPaths((paths) => paths.filter((p) => p.id !== path.id));
    }

    // Also clean old paths older than 20 seconds in case they don't trigger the event
    setPaths((paths) => paths.filter((p) => Date.now() - p.lastChange < 20 * 1000));
  };

  useEffect(() => {
    // Disable all paths
    setPaths((paths) => paths.map((path) => ({ ...path, active: false })));

    // Add new path
    if (drawing) {
      currentPathId.current = randomId(userId);
      const path = {
        id: currentPathId.current,
        d: '',
        active: true,
        lastChange: Date.now(),
        positions: [],
      };
      setPaths((paths) => [...paths, path]);
    } else {
      currentPathId.current = '';
    }

    setLastPositions((lastPositions) => {
      if (!drawing && lastPositions?.length) {
        if (canBroadcast) {
          // Broadcast before we clean old paths
          broadcast({
            type: EphemeralEvents.LAST_REFERENCE,
            positions: lastPositions.slice(-100),
            userId,
          });
        }
      }

      return undefined;
    });
  }, [drawing, broadcast, userId, canBroadcast]);

  useEffect(() => {
    if (!drawing || mode !== 'ephemeral') return;
    const newPosition = { ...position, created_at: Date.now() };

    setLastPositions((lastPositions) =>
      lastPositions === undefined ? [newPosition, newPosition] : [...lastPositions, newPosition]
    );
  }, [drawing, mode, position]);

  useEffect(() => {
    if (!lastPositions || lastPositions.length < 2) return;
    if (!currentPathId.current) return;

    if (canBroadcast && lastPositions.length % 5 === 0) {
      // Broadcast a max of last 20 positions to avoid overloading liveblocks
      broadcast({
        type: EphemeralEvents.ACTIVE_REFERENCE,
        positions: lastPositions.slice(-20),
        userId,
      });
    }

    // Create path from lastPosition to position
    const d = toSVGd(lastPositions, hasPressure, convertCoords);
    // Append path to last path in list
    setPaths((paths) =>
      paths.map((path) =>
        path.id === currentPathId.current
          ? {
              ...path,
              d,
              active: true,
              lastChange: Date.now(),
              positions: lastPositions,
            }
          : path
      )
    );
  }, [lastPositions, broadcast, isSelf, userId, canBroadcast, hasPressure, convertCoords]);

  return (
    <div className="fixed inset-0 pointer-events-none z-[1]">
      {!faceToFace &&
        paths.map((path) => (
          <svg
            key={path.id}
            className={clsx(
              'absolute inset-0 transition-opacity duration-1000 ',
              path.active ? 'opacity-100' : 'opacity-0',
              restartTransition && '!opacity-100 !duration-[0s]',
              noDelay ? 'delay-[0s] !duration-300' : 'delay-[5s]'
            )}
            viewBox={`0 0 ${videoWidth} ${videoHeight}`}
            onTransitionEnd={() => cleanOldPaths(path)}
          >
            <path d={path.d} fill={color} className="transition-all duration-100" />
          </svg>
        ))}
    </div>
  );
};

export default CursorEphemeral;
