import EventEmitter from 'eventemitter3';
import Twilio, {
  AudioTrack,
  LocalDataTrack,
  LocalVideoTrack,
  RemoteVideoTrack,
  Room,
  VideoTrack,
} from 'twilio-video';

export interface RemoteVideoTrackWithUser extends RemoteVideoTrack {
  userSid: string;
}

export type DataState =
  | 'f2f'
  | 'scout_start'
  | 'scout'
  | 'idle'
  | 'other-screenshare'
  | 'self-screenshare'
  | 'error';

export type ScreenShareActive = 'local' | 'remote' | null;

export interface IDataHandler extends EventEmitter<DataHandlerEvents> {
  get ActiveUserSid(): string | null;
  get ActiveTrackSid(): string | null;
  get LocalVideoTrack(): LocalVideoTrack | null;

  setTrack(track: LocalDataTrack | null): void;
  setRoom(room: Room | null): void;

  setState(state: DataState): void;
  handleMessage(message: DataMessage): void;
  startScreenShare(): void;
  stopScreenShare(): void;
}

export interface TwilioContextData {
  connect: (token: string) => void;
  room: Twilio.Room | null;
  participants: Twilio.RemoteParticipant[];
  audioTracks: AudioTrack[];
  videoTracks: RemoteVideoTrackWithUser[];
  microphone: {
    muted: boolean;
    setMuted: (muted: boolean) => void;
  };
  camera: {
    active: boolean;
    setActive: (active: boolean) => void;
    track: LocalVideoTrack | null;
  };
  screenshare: {
    active: ScreenShareActive;
    dataHandler: IDataHandler;
  };
  faceToFace: boolean;
  toggleFaceToFace: () => void;
}

export enum DataMessages {
  SCOUT = 'SCOUT', // Scout message, sends to every user asking if there is any active screenshare.
  SCOUT_RESPONSE = 'SCOUT_RESPONSE', // A response for a scout message, which contains the screenshare status.
  START_SCREENSHARE = 'START_SCREENSHARE', // Send to everyone informing that the user is starting a screenshare.
  STOP_SCREENSHARE = 'STOP_SCREENSHARE', // Send to everyone informing that the user stopped a screenshare.

  TOGGLE_F2F = 'TOGGLE_F2F', // Send to everyone informing that the user is toggling the face to face mode.
}

export type DataMessage =
  | {
      sentBy: string;
      type: DataMessages.SCOUT;
    }
  | {
      sentBy: string;
      type: DataMessages.SCOUT_RESPONSE;
      active: boolean;
      trackSid: string | null;
      userSid: string | null;
      f2f: boolean;
    }
  | {
      sentBy: string;
      type: DataMessages.START_SCREENSHARE;
      trackSid: string;
      userSid: string;
    }
  | {
      sentBy: string;
      type: DataMessages.STOP_SCREENSHARE;
    }
  | {
      sentBy: string;
      type: DataMessages.TOGGLE_F2F;
      value: boolean;
    };

export interface DataHandlerEvents {
  active: (type: ScreenShareActive) => void;
  trackChanged: (track: VideoTrack | null) => void;
  takeOver: (userSid: string) => void;
  forceStop: (userSid: string) => void;

  toggleF2F: (value: boolean, userSid?: string) => void;
}
