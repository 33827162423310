import { HalFdoc, HalUser } from '@fabric/woody-client';
import plur from 'plur';
import React, { useMemo } from 'react';

import { useGetComments } from '../../../store/helpers';
import useUIStore from '../../../store/ui';
import { getUserColorFromId } from '../../../utils/color';
import { OfViewMode } from '../../ListModeSwitcher/ListModeSwitcher';
import UserBubble from '../../UserBubble/UserBubble';
import styles from './FdocItemComments.module.scss';

const FdocItemComments: React.FC<{
  fdoc: HalFdoc;
  viewMode: OfViewMode;
}> = ({ fdoc, viewMode }) => {
  const user = useUIStore((s) => s.user);

  const { comments } = useGetComments(fdoc);

  const showFirstComment = useMemo(() => {
    // only show if the first comment is by the owner
    return (
      comments.length > 0 &&
      comments[0]._embedded.user.id === fdoc._embedded.user.id &&
      viewMode !== 'List'
    );
  }, [comments, fdoc._embedded.user.id, viewMode]);

  const showOwner = useMemo(() => {
    return user?.id !== fdoc._embedded.user.id && !showFirstComment;
  }, [user?.id, fdoc._embedded.user.id, showFirstComment]);

  const users = useMemo(() => {
    const users = comments
      .slice()
      .reverse()
      .reduce((acc, comment) => {
        if (acc.length >= 3 || acc.find((u) => u.id === comment._embedded.user.id)) return acc;
        // if showFirstComment don't show the first comment user
        if (showFirstComment && comment._embedded.user.id === fdoc._embedded.user.id) {
          return acc;
        }

        acc.push(comment._embedded.user);
        return acc;
      }, [] as HalUser[]);

    if (showOwner) users.push(fdoc._embedded.user);

    return users;
  }, [comments, showFirstComment, fdoc._embedded.user, showOwner]);

  const totalComments = useMemo(() => {
    return comments.length - (showFirstComment ? 1 : 0);
  }, [comments.length, showFirstComment]);

  return !comments ? null : comments.length > 0 || showOwner ? (
    viewMode === 'Grid' ? (
      <div className={styles.item__comments__wrapper}>
        {showFirstComment && (
          <div className={styles.first_comment}>
            <UserBubble
              key={comments[0].id}
              name={comments[0]._embedded.user.name}
              color={getUserColorFromId(comments[0]._embedded.user.id)}
              picture={comments[0]._embedded.user.picture}
              size={16}
            />
            <span>{comments[0].content}</span>
          </div>
        )}
        {(totalComments > 0 || users.length > 0) && (
          <div className={styles.item__comments} data-testid="item-comment-section">
            <div className={styles.item__comments__users}>
              {users?.map((user, index) => (
                <UserBubble
                  key={index}
                  name={user.name}
                  picture={user.picture}
                  color={getUserColorFromId(user.id)}
                  size={16}
                />
              ))}
            </div>

            {totalComments > 0 && (
              <span>
                {comments.length} {plur('comment', comments.length)}
              </span>
            )}
          </div>
        )}
      </div>
    ) : (
      <div className={styles.item__comments} data-testid="item-comment-section">
        {comments.length > 0 && <span>{comments[comments.length - 1].content}</span>}

        <div className={styles.item__comments__total}>
          <div className={styles.item__comments__users}>
            {users?.map((user, index) => (
              <UserBubble
                key={index}
                name={user.name}
                color={getUserColorFromId(user.id)}
                picture={user.picture}
                size={16}
              />
            ))}
          </div>

          {comments.length > 0 && (
            <span>
              {comments.length} {plur('comment', comments.length)}
            </span>
          )}
        </div>
      </div>
    )
  ) : null;
};

export default FdocItemComments;
