import React from 'react';

import Control from '../../components/Onboarding/Steps/Control';
import Invite from '../../components/Onboarding/Steps/Invite';
import Mode from '../../components/Onboarding/Steps/Mode';
import Privacy from '../../components/Onboarding/Steps/Privacy';

export enum OnboardingActionType {
  SET_ACTIVE = 'ONBOARDING:ACTIVE',
  SET_STEP = 'ONBOARDING:STEP',
}

export enum OnboardingStep {
  MODE,
  CONTROL,
  PRIVACY,
  INVITE,
}

export type StepsDictionary = {
  [key in OnboardingStep]: {
    Component: React.FC;
    initial?: boolean;
    final?: boolean;
  };
};

export const steps: StepsDictionary = {
  [OnboardingStep.MODE]: {
    Component: Mode,
    initial: true,
  },
  [OnboardingStep.CONTROL]: {
    Component: Control,
  },
  [OnboardingStep.PRIVACY]: {
    Component: Privacy,
  },
  [OnboardingStep.INVITE]: {
    Component: Invite,
    final: true,
  },
};

export type OnboardingState = {
  active: boolean;
  step: OnboardingStep;
};

const initialState: OnboardingState = {
  active: false,
  step: Object.values(steps).findIndex((step) => step.initial),
};

export type OnboardingAction =
  | {
      type: OnboardingActionType.SET_ACTIVE;
      active: boolean;
    }
  | {
      type: OnboardingActionType.SET_STEP;
      step: OnboardingStep;
    };

const reducer = (state: OnboardingState, action: OnboardingAction): OnboardingState => {
  switch (action.type) {
    case OnboardingActionType.SET_ACTIVE:
      return {
        ...state,
        active: action.active,
      };
    case OnboardingActionType.SET_STEP:
      return {
        ...state,
        // We need to divide the OnboardingSteps length by 2 because of reverse mapping of the enum
        // since it's a number enum.
        step: Math.min(Math.max(action.step, 0), Object.keys(OnboardingStep).length / 2 - 1),
      };
    default:
      return state;
  }
};

export default { reducer, initialState } as const;
