import { Suspense } from 'react';
import { createBrowserRouter, Outlet, RouteObject } from 'react-router-dom';

import App from './App';
import LoadingOverlay from './components/LoadingOverlay';
import { ErrorHandlerProvider } from './hooks/error';
// Made collectionMissing a normal import as it doesn't really make sense to lazy load it.
// Might be a good idea to lazy load it in the future if it becomes a performance issue.
import CollectionMissing from './pages/CollectionMissing';
import NotFound from './pages/errors/NotFound';
import RouterErrorBoundary from './pages/errors/RouterErrorBoundary';
import Notifications from './pages/Notifications';
import ExpandedListSkeleton from './pages/skeletons/ExpandedListSkeleton';
import ListInviteSkeleton from './pages/skeletons/ListInviteSkeleton';
import ListManagerSkeleton from './pages/skeletons/ListManagerSkeleton';
import QuickAddToCollectionSkeleton from './pages/skeletons/QuickAddToCollectionSkeleton';
import { buzzWebStoreExtensionId, webStoreExtensionId } from './services/extension';
import { PumbaaProvider } from './services/pumbaa/Provider';
import { WoodyProvider } from './services/woody/WoodyProvider';
import DashboardSkeleton from './templates/skeletons/DashboardSkeleton';
import { ReactLazyPreload as lazy } from './utils/router';

const Admin = lazy(() => import('./pages/Admin'));
const AuthDebugPage = lazy(() => import('./pages/auth/AuthDebug'));
const Signin = lazy(() => import('./pages/auth/Signin'));
const Signup = lazy(() => import('./pages/auth/Signup'));
const BrowserWrapper = lazy(() => import('./pages/BrowserWrapper'));
const DesktopAuth = lazy(() => import('./pages/DesktopAuth'));
const Handoff = lazy(() => import('./pages/Handoff'));
const Launch = lazy(() => import('./pages/Launch'));
const LeftScreen = lazy(() => import('./pages/LeftScreen'));
const Nudge = lazy(() => import('./pages/Nudge'));
const QuickLaunch = lazy(() => import('./pages/QuickLaunch'));
const WaitingBrowser = lazy(() => import('./pages/WaitingBrowser'));
const AnalyticsHome = lazy(() => import('./pages/analytics/Home'));
const AnalyticsSession = lazy(() => import('./pages/analytics/Session'));
const ReloadBrowser = lazy(() => import('./pages/ReloadBrowser'));
const ListInvite = lazy(() => import('./pages/ListInvite'));
const Dashboard = lazy(() => import('./templates/Dashboard'));
const Notes = lazy(() => import('./pages/Notes'));
const Lists = lazy(() => import('./pages/Lists'));
const ListManager = lazy(() => import('./pages/ListManager'));

// withAuth in here because ExpandedList is a page and not a page at the
// same time
const ExpandedList = lazy(() => import('./pages/ExpandedList'));
const FdocNew = lazy(() => import('./pages/FdocNew'));
const QuickNoteNew = lazy(() => import('./pages/QuickNoteNew'));
const NoteConfirm = lazy(() => import('./pages/NoteConfirm'));
const QuickAddToCollection = lazy(() => import('./pages/QuickAddToCollection'));

const woodyRoutes: RouteObject[] = [
  {
    path: '/',
    element: (
      <Suspense fallback={<DashboardSkeleton />}>
        <Dashboard />
      </Suspense>
    ),
    children: [
      {
        path: '/',
        element: <Notes />,
        children: [
          {
            path: '/quick-add-to-collection',
            element: (
              <Suspense fallback={<QuickAddToCollectionSkeleton />}>
                <QuickAddToCollection />
              </Suspense>
            ),
          },

          {
            path: '/new',
            element: <FdocNew />,
          },
          {
            path: '/quick-note',
            element: <QuickNoteNew />,
          },
          {
            path: '/note-confirm',
            element: <NoteConfirm />,
          },
        ],
      },
      {
        path: '/collections',
        element: <Lists />,
      },
      {
        path: '/notifications',
        element: <Notifications />,
      },
    ],
  },
  {
    path: '/collections/:listId/edit',
    element: (
      <Suspense fallback={<ListManagerSkeleton />}>
        <ListManager />
      </Suspense>
    ),
  },
  {
    path: '/collections/new',
    element: (
      <Suspense fallback={<ListManagerSkeleton />}>
        <ListManager />
      </Suspense>
    ),
  },
  {
    path: '/collections/:listId',
    element: (
      <Suspense fallback={<ExpandedListSkeleton />}>
        <ExpandedList />
      </Suspense>
    ),
  },
  {
    path: '/c/:listId',
    element: (
      <Suspense fallback={<ListInviteSkeleton />}>
        <ListInvite />
      </Suspense>
    ),
  },
  {
    path: '/c404',
    element: <CollectionMissing />,
  },
];

const authLoadRoutes: RouteObject[] = [
  {
    path: '/',
    element: (
      <WoodyProvider>
        <Outlet />
      </WoodyProvider>
    ),
    children: woodyRoutes,
  },
  {
    path: '/',
    element: (
      <Suspense fallback={<LoadingOverlay />}>
        <PumbaaProvider>
          <Outlet />
        </PumbaaProvider>
      </Suspense>
    ),
    children: [
      {
        path: '/quicklaunch',
        element: <QuickLaunch />,
      },
      {
        path: '/auth',
        element: <AuthDebugPage />,
      },
      {
        path: '/admin',
        element: <Admin />,
      },
      {
        path: '/admin/sessions',
        element: <AnalyticsHome />,
      },
      {
        path: '/admin/sessions/:sessionId',
        element: <AnalyticsSession />,
      },
      {
        path: '/sessions',
        element: <Launch />,
      },
      {
        path: '/checkout-extension',
        element: (
          <Nudge
            description="A better experience across your browser. Save notes and content faster, see your annotations on top of websites and PDFs"
            webStoreExtensionId={buzzWebStoreExtensionId}
          />
        ),
      },
      {
        path: '/checkout-sessions-extension',
        element: (
          <Nudge
            dark
            description="Instantly jump into a shared Fabric from any webpage. Browse together in just a click."
            webStoreExtensionId={webStoreExtensionId}
            backLink="/sessions"
          />
        ),
      },
      {
        path: '/desktop-auth',
        element: <DesktopAuth />,
      },

      {
        path: '/wb/:id',
        element: (
          <ErrorHandlerProvider>
            <WaitingBrowser />
          </ErrorHandlerProvider>
        ),
      },
      {
        path: '/b/:id',
        element: <BrowserWrapper />,
      },
      {
        path: '/handoff/:id',
        element: <Handoff />,
      },
      {
        path: '/reload/:id',
        element: <ReloadBrowser />,
      },
    ],
  },
];

export const routes: RouteObject[] = [
  {
    path: '/',
    element: <App />,
    errorElement: <RouterErrorBoundary />,
    children: [
      {
        path: '/',
        children: authLoadRoutes,
        element: (
          <>
            <Outlet />
          </>
        ),
      },
    ],
  },
  {
    path: '/',
    errorElement: <RouterErrorBoundary />,
    element: (
      <Suspense fallback={<LoadingOverlay />}>
        <Outlet />
      </Suspense>
    ),
    children: [
      {
        path: '/signin',
        element: <Signin />,
      },
      {
        path: '/beta-sign-up-exclusive-link',
        element: <Signup />,
      },
      {
        path: '/left-session',
        element: <LeftScreen />,
      },
    ],
  },
  {
    path: '*',
    errorElement: <RouterErrorBoundary />,
    element: <NotFound />,
  },
];

const router = createBrowserRouter(routes);

export default router;
