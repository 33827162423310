import { IWoodyAuthenticator } from '@fabric/woody-client';
import { Auth } from 'aws-amplify';

class WoodyAuthenticator implements IWoodyAuthenticator {
  public async getToken() {
    const session = await Auth.currentSession();
    const token = session.getAccessToken();
    return token;
  }
}

export default WoodyAuthenticator;
