import './Loading.css';

import clsx from 'clsx';
import React from 'react';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  description?: string;
}

export const Spinner = ({description=""})=>{
  return (
    <>
      <div className="pointer-events-none la-square-loader la-2x" style={{ color: '#3654f1' }}><div></div></div>
      <div className="text-center text-white mt-14">{description ? description : <br />}</div>
    </>
  )
  }

const LoadingOverlay: React.FC<Props> = ({ className, description, ...props }) => {
  return (
    <div
      className={clsx(
        'fixed top-0 left-0 z-10 flex flex-col items-center justify-center w-screen h-screen bg-black ',
        className
      )}
      {...props}
    >
      <Spinner description={description}/>
    </div>
  );
};

export default LoadingOverlay;
