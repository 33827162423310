/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:53289883-eef9-4a0a-9dfe-ae4624b8645c",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_Atb7Um5WA",
    "aws_user_pools_web_client_id": "3h90hogbulklbe2nr1m6ds2f6",
    "oauth": {
        "domain": "sandbox-live.auth.eu-west-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://go.sandbox.so/,http://localhost:3012/,https://www.projectsimba.live/,https://main.projectsimba.live/,https://staging.projectsimba.live/,https://develop.projectsimba.live/",
        "redirectSignOut": "https://go.sandbox.so/bye/,http://localhost:3012/bye/,https://www.projectsimba.live/bye/,https://main.projectsimba.live/bye/,https://staging.projectsimba.live/bye/,https://develop.projectsimba.live/bye/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_AND_IDENTITY_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "GOOGLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "NAME"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://apgtngr312.execute-api.eu-west-1.amazonaws.com/live",
            "region": "eu-west-1"
        },
        {
            "name": "zazurest",
            "endpoint": "https://ibxgomxfb3.execute-api.eu-west-1.amazonaws.com/live",
            "region": "eu-west-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://z5xs4gndifcjng2knuftxzoqja.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS"
};


export default awsmobile;
