import React from 'react';

import Skeleton from '../../components/Skeleton/Skeleton';
import SkeletonStructure from '../../components/SkeletonStructure/SkeletonStructure';

const QuickAddToCollectionSkeleton: React.FC = () => {
  return (
    <SkeletonStructure
      padding={30}
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        background: 'rgba(0,0,0,0.4)',
        backdropFilter: 'blur(12px)',
        zIndex: 1000,
      }}
    >
      <SkeletonStructure cols expand padding={10}>
        <SkeletonStructure>
          <Skeleton width={73} height={37} borderRadius={20} />
        </SkeletonStructure>
        <SkeletonStructure cols style={{ justifyContent: 'center' }} expand>
          <SkeletonStructure cols>
            <Skeleton
              width={266}
              height={180}
              borderRadius={10}
              style={{ transform: 'translateX(30%) translateY(50%) rotate(9.71deg)', zIndex: 2 }}
            />
            <Skeleton
              width={266}
              height={180}
              borderRadius={10}
              style={{ transform: 'translateX(-50%)' }}
            />
            <Skeleton
              width={266}
              height={180}
              borderRadius={10}
              style={{ transform: 'translateX(32%) translateY(-50%) rotate(-9.71deg)' }}
            />
          </SkeletonStructure>
          <SkeletonStructure padding={40} style={{ justifyContent: 'center' }}>
            <Skeleton width={160} height={34} borderRadius={6} />
          </SkeletonStructure>
        </SkeletonStructure>
      </SkeletonStructure>
      <SkeletonStructure
        cols
        expand
        style={{ maxWidth: '640px', borderRadius: 20 }}
        bgColor="white"
      >
        <SkeletonStructure
          spaceBetween
          padding="16px 20px"
          style={{
            borderBottom: '1px solid #e3e3e4',
          }}
        >
          <Skeleton width={236} height={24} borderRadius={6} />
          <Skeleton width={150} height={38} borderRadius={10} />
        </SkeletonStructure>
      </SkeletonStructure>
    </SkeletonStructure>
  );
};

export default QuickAddToCollectionSkeleton;
