import './index.scss';

import { Authenticator } from '@aws-amplify/ui-react';
import { Amplify } from 'aws-amplify';
import LogRocket from 'logrocket';
import React from 'react';
import ReactDOM from 'react-dom';
import { RouterProvider } from 'react-router-dom';

import { SandboxAuthProvider } from './hooks/auth';
import { DialogueProvider } from './hooks/dialogue';
import { getLogRocketAppId } from './hooks/logrocket';
import { NotificationsProvider } from './hooks/realtimeNotifications';
import { ConversationProvider } from './hooks/twilio-conversations';
import ErrorBoundary from './pages/errors/ErrorBoundary';
import reportWebVitals from './reportWebVitals';
import router from './router';
import { getAmplifyConfig } from './services/config';

const LOGROCKET_APP_ID = getLogRocketAppId();
if (LOGROCKET_APP_ID) {
  LogRocket.init(LOGROCKET_APP_ID);
} else {
  console.log('No LOGROCKET_APP_ID ENV variable. Not using LogRocket.');
}
getAmplifyConfig().then((conf) => Amplify.configure(conf));

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <Authenticator.Provider>
        <SandboxAuthProvider>
          <ConversationProvider>
            <NotificationsProvider>
              <DialogueProvider>
                <RouterProvider router={router} />
              </DialogueProvider>
            </NotificationsProvider>
          </ConversationProvider>
        </SandboxAuthProvider>
      </Authenticator.Provider>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
