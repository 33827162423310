import './HoverItem.scss';

import React from 'react';
import { createPortal } from 'react-dom';

import { ReactComponent as LinkExternal } from '../../assets/images/icons/LinkExternal.svg';

type hoverItemType = {
  show: boolean;
  x: number;
  y: number;
  title: string;
  url: string;
};

const HoverItem: React.FC<hoverItemType> = ({ show, x, y, title, url }) => {
  const portalTarget = document.getElementById('tooltip-portal');
  const element = (
    <div
      style={{
        top: y + 10,
        left: x + 10,
        display: !show || (x === 0 && y === 0) ? 'none' : undefined,
      }}
      className="hover_item"
    >
      <div className="hover_item__header">
        <span>{title}</span>
        <LinkExternal />
      </div>
      <p className="hover_item__url">{url}</p>
    </div>
  );

  return portalTarget ? createPortal(element, portalTarget) : element;
};

export default HoverItem;
