import { HalFdoc, WoodyError } from '@fabric/woody-client';
import { useCallback, useEffect, useState } from 'react';

import { useWoody } from '../services/woody/WoodyProvider';
import { OptimisticDraft } from '../types/draftable';
import useCommentsStore from './comment';
import useFdocStore from './fdoc';
import useListsStore from './list';
import useWebnoteStore from './webnote';

export const useGetListWithFdocs = (listId?: string) => {
  const { client } = useWoody();
  const [fetched, setFetched] = useState(false);
  const [error, setError] = useState<WoodyError | null>(null);
  const { list, setList } = useListsStore((s) => ({
    list: s.lists.find((l) => l.id === listId),
    setList: s.setList,
  }));
  const { fdocs, setFdocs } = useFdocStore((state) => ({
    fdocs: state.listFdocs[listId ?? ''] ?? [],
    setFdocs: state.setListFdocs,
  }));

  const [loading, setLoading] = useState(!list);

  useEffect(() => {
    if (fetched || !listId) return;

    const fetchNotes = async () => {
      setLoading(true);
      const response = await client.fetchFdocsByListId(listId);

      if (response.error) {
        setError(response.error);
        setLoading(false);
        return;
      }

      setList(response.data._embedded.list);
      setFdocs(listId, response.data._embedded.fdocs);
      setLoading(false);
      setFetched(true);
    };

    fetchNotes();
  }, [listId, fetched, client, setList, setFdocs]);

  const removeFdocsFromList = useCallback(
    (fdocIds: string[]) => {
      if (!list?.id) return;
      setFdocs(
        list.id,
        fdocs.filter((fdoc) => !fdocIds.includes(fdoc.id))
      );
    },
    [list?.id, fdocs, setFdocs]
  );

  return {
    list,
    fdocs,
    loading,
    error,
    removeFdocsFromList,
  };
};

export const useGetWebnoteImage = (fdoc?: OptimisticDraft<HalFdoc>) => {
  const { client } = useWoody();

  const { image, setImage, description, setDescription } = useWebnoteStore((state) => ({
    image: fdoc ? state.images[fdoc.id] : undefined,
    setImage: state.setImage,
    description: fdoc ? state.descriptions[fdoc.id] : undefined,
    setDescription: state.setDescription,
  }));

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<WoodyError | null>(null);
  const [fetched, setFetched] = useState(false);

  useEffect(() => {
    if (
      !fdoc ||
      loading ||
      fetched ||
      fdoc.isDraft ||
      (fdoc.type !== 'page' && fdoc.type !== 'image')
    )
      return;

    const fetchImage = async () => {
      setLoading(true);

      const response =
        fdoc.type === 'image'
          ? await client.fetchImage(fdoc.id)
          : await client.fetchLinkPreview(fdoc.id);
      setLoading(false);
      setFetched(true);

      const imageUrl = 'image' in response.data ? response.data.image?.url : response.data.url;
      const description = 'image' in response.data ? response.data.description : null;

      if (description) {
        setDescription(fdoc.id, description);
      }

      if (response.error || !imageUrl) {
        setError(response.error);
        return;
      }

      setImage(fdoc.id, imageUrl);
    };

    fetchImage();
  }, [fdoc, loading, fetched, client, setImage, setDescription]);

  return {
    image,
    description,
    loading: fdoc?.isDraft ? false : loading,
    error,
  };
};

export const useGetComments = (fdoc: HalFdoc) => {
  const { client } = useWoody();
  const [loading, setLoading] = useState(false);
  const [fetched, setFetched] = useState(false);
  const [error, setError] = useState(false);

  const { comments, setComments } = useCommentsStore((s) => ({
    comments: s.comments[fdoc.id] || [],
    setComments: s.setComments,
  }));

  useEffect(() => {
    if (fetched || loading) {
      return;
    }

    if (fdoc.commentCount === 0) {
      setFetched(true);
      return;
    }

    const fetchComments = async () => {
      setLoading(true);
      const response = await client.fetchComments(fdoc.id);

      setLoading(false);
      setFetched(true);

      if (response.error) {
        setError(true);
        return;
      }

      const loadedComments = response.data._embedded.item;

      setComments(loadedComments, fdoc.id);
    };

    fetchComments();
  }, [fdoc, loading, fetched, client, setComments]);

  /* // every 30 seconds, we refresh the comments list
  useEffect(() => {
    if (!fetched) return;

    const interval = setInterval(() => {
      setFetched(false);
    }, 30000);

    return () => clearInterval(interval);
  }, [fetched]); */

  return {
    comments,
    loading,
    error,
  };
};
