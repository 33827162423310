import React from 'react';
import { matchPath, RouteObject } from 'react-router-dom';

import { routes } from '../router';

type PreloadableLazyExoticComponent<T extends React.ComponentType<any>> =
  React.LazyExoticComponent<T> & {
    preload?: () => Promise<{
      default: React.ComponentType<any>;
    }>;
    type?: {
      preload?: () => Promise<{
        default: React.ComponentType<any>;
      }>;
    };
  };

export const ReactLazyPreload = (
  importStatement: () => Promise<{
    default: React.ComponentType<any>;
  }>
) => {
  const Component = React.lazy(importStatement) as PreloadableLazyExoticComponent<any>;
  Component.preload = importStatement;
  return Component;
};

export const findRouteFromPath = (path: string, routes: RouteObject[]) => {
  // recursively search for the route that matches the path (also check for children)
  const matchingRoute: RouteObject[] = routes.reduce((acc, route) => {
    if (route.path && matchPath(path, route.path)) {
      acc.push(route);
    }
    if (route.children) {
      acc.push(...findRouteFromPath(path, route.children));
    }
    return acc;
  }, [] as RouteObject[]);

  return matchingRoute;
};

export const preloadRouteComponent = (path: string) => {
  const components = findRouteFromPath(path, routes).map(
    (route) => route.element as PreloadableLazyExoticComponent<any>
  );

  components.forEach((component) => {
    if (component && 'preload' in component) {
      component.preload?.();
    }
    if (component && 'type' in component) {
      component.type?.preload?.();
    }
  });
};
