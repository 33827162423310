import Color from 'color';

/**
 * An assortment of random colors.
 */
export const userColors = [
  '#d92a2a',
  '#c2a800',
  '#2ad92a',
  '#2ad9d9',
  '#2a2ad9',
  '#d92ad9',
  '#2f4f4f',
  '#2e8b57',
  '#191970',
  '#8b0000',
  '#808000',
  '#ff0000',
  '#ff8c00',
  '#ffd700',
  '#7cfc00',
  '#ba55d3',
  '#e9967a',
  '#00ffff',
  '#ff00ff',
  '#1e90ff',
  '#eee8aa',
  '#dda0dd',
  '#ff1493',
  '#98fb98',
  '#87cefa',
];

/**
 * Receives a color string (hex, rgb, hsl) that represents the background color,
 * and returns a color string that represents the foreground color, either white or black.
 * For use together with user colors so they have an appropriate contrast ratio.
 * @param color
 * @returns 'white' | 'black'
 */
export const getTextColor = (color: string) => {
  try {
    const c = Color(color);

    const r = c.red();
    const g = c.green();
    const b = c.blue();

    const yiq = (r * 299 + g * 587 + b * 114) / 1000;

    return yiq >= 140 ? 'black' : 'white';
  } catch (e) {
    return 'white';
  }
};

/**
 * Returns a color string that represents the background color for a user.
 * Based on the user's first and last character of their sub.
 * @param sub string
 * @returns string
 */
export const getUserColorFromId = (sub: string) => {
  const index = (sub.charCodeAt(0) + sub.charCodeAt(sub.length - 1)) % userColors.length;
  const color = userColors[index];

  return color;
};
