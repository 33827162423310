import React from 'react';
import { Link } from 'react-router-dom';

import FabricError from '../../templates/FabricError';

const NotFound: React.FC = () => {
  return (
    <FabricError>
      <h1>404</h1>
      <h4>There’s nothing here!</h4>
      <p>How did you even get here?</p>
      <Link to="/">Let’s get you back to your dashboard</Link>
    </FabricError>
  );
};

export default NotFound;
