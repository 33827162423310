import { Member } from '../types';

export enum ChatActionType {
  TOGGLE_PANEL = 'CHAT:TOGGLE_PANEL',

  ADD_MESSAGE = 'CHAT:ADD_MESSAGE',
  ADD_EMOTE = 'CHAT:ADD_EMOTE',
  DEL_EMOTE = 'CHAT:DEL_EMOTE',

  SET_OPEN = 'CHAT:SET_OPEN',
  TOGGLE_OPEN = 'CHAT:TOGGLE_OPEN',

  RESET = 'CHAT:RESET',
}

export interface Emote {
  id: string;
  type: string;
}

export interface Message {
  id: string;
  content: string;
  created: Date;
  type: 'text' | 'event';
}

export interface Notification {
  id: number;
  icon?: 'shock' | 'link' | 'private';
  message: string;
  userId?: string;
  member?: Member | string; // So we can show a member action.
  bgColor?: string;
}

export type NotificationPayload = Omit<Notification, 'id'>;

const initialState: ChatState = {
  panelOpen: false,
  notification_id: 0,

  history: [],
  emotes: [],
  notifications: [],
  texts: 0,
  open: false,

  dialogueId: 0,
};

export type ChatState = {
  panelOpen: boolean;
  notification_id: number;

  history: Message[];
  emotes: Emote[];
  notifications: Notification[];
  texts: number;
  open: boolean; // For the chat bubble on the cursor.

  dialogueId: number;
};

export type ChatAction =
  | {
      type: ChatActionType.ADD_MESSAGE;
      payload: Message;
    }
  | {
      type: ChatActionType.ADD_EMOTE;
      payload: Emote;
    }
  | {
      type: ChatActionType.DEL_EMOTE;
      payload: string;
    }
  | {
      type: ChatActionType.SET_OPEN | ChatActionType.TOGGLE_PANEL;
      payload: boolean;
    }
  | {
      type: ChatActionType.RESET | ChatActionType.TOGGLE_OPEN;
    };

const reducer = (state: ChatState, action: ChatAction): ChatState => {
  switch (action.type) {
    case ChatActionType.ADD_MESSAGE:
      return {
        ...state,
        history: [...state.history, action.payload],
      };
    case ChatActionType.ADD_EMOTE:
      return {
        ...state,
        emotes: [...state.emotes, action.payload],
      };
    case ChatActionType.DEL_EMOTE:
      return {
        ...state,
        emotes: state.emotes.filter((e) => e.id !== action.payload),
      };
    case ChatActionType.SET_OPEN:
      return {
        ...state,
        open: action.payload,
      };
    case ChatActionType.TOGGLE_OPEN:
      return {
        ...state,
        open: !state.open,
      };
    case ChatActionType.TOGGLE_PANEL:
      return {
        ...state,
        panelOpen: action.payload,
      };
    case ChatActionType.RESET:
      return initialState;
    default:
      return state;
  }
};

export default { reducer, initialState } as const;
