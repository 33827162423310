import { SafePresence, UserCache } from '../hooks/member';

export interface Member {
  id: string;
  displayname: string;
  admin: boolean;
  muted: boolean;
  connected?: boolean;
  ignored?: boolean;

  connectionId?: number;
  presence?: SafePresence;
  cache?: UserCache;
}

export interface ScreenResolution {
  width: number;
  height: number;
  rate: number;
}

export enum BrowserInteractions {
  WHEEL = 'wheel',
  MOUSE_MOVE = 'mousemove',
  MOUSE_DOWN = 'mousedown',
  MOUSE_UP = 'mouseup',
  KEY_DOWN = 'keydown',
  KEY_UP = 'keyup',
  ACTIVITY = 'activity',
}

export type BrowserInteraction =
  | {
      type: BrowserInteractions.WHEEL | BrowserInteractions.MOUSE_MOVE;
      x: number;
      y: number;
    }
  | {
      type:
        | BrowserInteractions.MOUSE_DOWN
        | BrowserInteractions.MOUSE_UP
        | BrowserInteractions.KEY_DOWN
        | BrowserInteractions.KEY_UP
        | BrowserInteractions.ACTIVITY;
      key: number;
    };
