import React from 'react';

import { ReactComponent as Fabric } from '../../assets/images/icons/InvertedFabric.svg';
import Skeleton from '../../components/Skeleton/Skeleton';
import SkeletonStructure from '../../components/SkeletonStructure/SkeletonStructure';

const DashboardSkeleton: React.FC = () => (
  <SkeletonStructure expand>
    <SkeletonStructure
      width={72}
      padding={26}
      spaceBetween
      cols
      style={{
        borderRight: '1px solid #E3E3E4',
      }}
    >
      <Fabric />
      <Skeleton width={24} height={24} circle />
    </SkeletonStructure>
    <SkeletonStructure cols expand>
      <SkeletonStructure
        height={80}
        padding={'16px 30px'}
        gap={20}
        style={{
          borderBottom: '1px solid #E3E3E4',
        }}
      >
        <Skeleton width={163} height={48} />
        <Skeleton width={163} height={48} />
        <Skeleton
          style={{ marginLeft: 'auto', marginRight: 'auto' }}
          width={330}
          height={48}
          borderRadius={24}
        />
        <Skeleton width={150} height={40} borderRadius={8} />
      </SkeletonStructure>
      <SkeletonStructure expand />
    </SkeletonStructure>
  </SkeletonStructure>
);

export default DashboardSkeleton;
