import React, { memo, useMemo, useState } from 'react';

import { OfViewMode } from '../../ListModeSwitcher/ListModeSwitcher';
import Skeleton from '../../Skeleton/Skeleton';
import styles from './FdocItemSkeleton.module.scss';

enum RandomTypes {
  text = 'text',
  image = 'image',
  page = 'page',
}

const FdocItemSkeleton: React.FC<{
  viewMode: OfViewMode;
}> = ({ viewMode }) => {
  /**
   * FdocItemSkeleton
   *
   * @description Skeleton or placeholder for a note item. When the real note items are being loaded,
   * the FdocItemSkeleton component is used to show a temporary representation of the note until the real
   * data is ready to be displayed. The FdocItemSkeleton component randomly generates the number of lines,
   * height, and sizes of the placeholder elements to mimic the appearance of a real note item.
   *
   * @example
   * <FdocItemSkeleton />
   *
   * @returns {JSX.Element}
   */

  const noteType = useMemo(() => {
    const noteTypeKeys = Object.keys(RandomTypes);
    const randomIndex = Math.floor(Math.random() * noteTypeKeys.length);
    return noteTypeKeys[randomIndex] as RandomTypes;
  }, []);

  const [{ numTextLines, commentUsers, imageHeight, titleSize, urlSize }] = useState({
    // For text notes, we want to generate a random number of lines
    numTextLines: Array.from({ length: 1 + Math.round(Math.random() * 3) }, () => ({
      size: 60 + Math.round(Math.random() * 35), // % of line
    })),
    // For image notes, we want to generate a random height
    imageHeight: 68 + Math.round(Math.random() * 80),
    // For page notes, we want to generate a random title and url size
    titleSize: 60 + Math.round(Math.random() * 35),
    urlSize: 60 + Math.round(Math.random() * 35),
    // For all notes, we want to show a random number of comment users
    // If 0, we don't show the comment section
    commentUsers: Math.round(Math.random() * 3),
  });

  return (
    <div className={styles.item__skeleton}>
      {(viewMode === 'List' || noteType === 'page') && (
        <div className={styles.item__skeleton__page}>
          {viewMode === 'List' && (
            <Skeleton circle width={16} height={16} style={{ flexShrink: 0 }} />
          )}

          <Skeleton
            height={viewMode === 'Grid' ? 22 : 18}
            borderRadius={12}
            style={{
              width: `${titleSize}%`,
            }}
          />

          {noteType === 'page' && (
            <Skeleton
              height={14}
              borderRadius={8}
              style={{
                width: `${urlSize}%`,
              }}
            />
          )}
        </div>
      )}

      {noteType === 'text' ? (
        <div className={styles.item__skeleton__text}>
          {numTextLines
            // if list view we can only show 2 lines
            .slice(0, viewMode === 'List' ? 2 : undefined)
            .map((line, index) => (
              <Skeleton
                key={index}
                height={18}
                style={{
                  width: `${line.size}%`,
                }}
              />
            ))}
        </div>
      ) : (
        noteType === 'image' && (
          <Skeleton height={imageHeight} width={viewMode === 'Grid' ? undefined : imageHeight} />
        )
      )}

      {commentUsers > 0 && (
        <div className={styles.item__skeleton__comments}>
          <div className={styles.item__skeleton__comments__users}>
            {Array.from({
              length: commentUsers,
            }).map((_, index) => (
              <Skeleton key={index} height={16} width={16} circle />
            ))}
          </div>

          <Skeleton height={14} width={60} borderRadius={8} />
        </div>
      )}

      {viewMode === 'List' && (
        <Skeleton width={80} height={16} borderRadius={8} style={{ marginLeft: 'auto' }} />
      )}
    </div>
  );
};

export default memo(FdocItemSkeleton);
