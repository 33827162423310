import clsx from 'clsx';
import React from 'react';
import { useLocation, useNavigate } from 'react-router';
import { Link } from 'react-router-dom';

import { ReactComponent as Fabric } from '../assets/images/icons/Fabric.svg';
import { ReactComponent as Crash } from '../assets/images/icons/pc/crash.svg';
import { ReactComponent as Duplicate } from '../assets/images/icons/pc/duplicate.svg';
import { ReactComponent as Sleeping } from '../assets/images/icons/pc/sleeping.svg';
import { ReactComponent as Slow } from '../assets/images/icons/pc/slow.svg';
import { ReactComponent as Surprised } from '../assets/images/icons/pc/surprised.svg';
import { useAuth } from '../hooks/auth';

const emotions = {
  crash: Crash,
  surprised: Surprised,
  slow: Slow,
  duplicate: Duplicate,
  sleeping: Sleeping,
};

interface ErrorComponentProps {
  error: string | React.ReactNode;
  description?: string | React.ReactNode;
  pcEmotion?: keyof typeof emotions;
  baseError?: Error;
  onClickReload?: boolean;
}

const ErrorComponent: React.FC<ErrorComponentProps> = ({
  error,
  description,
  pcEmotion = 'crash',
  baseError,
  onClickReload,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const Emotion = emotions[pcEmotion];
  const onClick = onClickReload
    ? () => navigate(location.pathname.replace(/^\/b\//, '/reload/'))
    : undefined;

  const { isLoggedIn } = useAuth();

  const forceUpdate = (e: React.MouseEvent) => {
    e.stopPropagation();
    // if the link is / we reload
    if (location.pathname === '/') {
      window.location.reload();
      e.preventDefault();
    }
  };

  return (
    <div
      className="flex flex-col w-full h-full overflow-y-auto gradient-primary p-[30px]"
      onClick={onClick}
    >
      <div className="flex w-full mb-[49px] lg:mb-0 items-center">
        <Fabric className="w-[60px] h-[60px]" />
        {isLoggedIn && (
          <Link onClick={forceUpdate} to="/" state={undefined}>
            <button className="bg-[rgba(255,255,255,0.8)] hover:bg-[rgba(255,255,255,0.9)] rounded-[33px] flex items-center py-[11px] px-[19px] text-[15px] font-[500] h-[39px] ml-[15px]">
              open dashboard
            </button>
          </Link>
        )}
      </div>
      <div className="flex flex-col max-w-full mx-auto text-white md:m-auto">
        <div className="pb-[39px] md:pb-[44px]">
          <Emotion className="w-auto max-h-[68px] md:max-h-[72px]" />
        </div>
        <h1
          className="mb-[44px] text-[32px] font-bold leading-[38px] md:text-[28px] md:leading-[34px]"
          onClick={onClick}
        >
          {error}
        </h1>
        {description && (
          <h4
            className="text-[15px] md:text-[14px] text-left leading-[18px] md:leading-[16.94px]"
            onClick={onClick}
          >
            {description}
          </h4>
        )}
        {baseError && (
          <div
            className={clsx(
              'flex flex-col w-full overflow-auto max-h-[200px] font-anonymous-pro text-[#664BB1] opacity-80',
              description && 'mt-[89px]'
            )}
          >
            <h4 className={clsx('text-[14px] md:text-[15px]')}>
              {baseError.name}: {baseError.message}
            </h4>
            {baseError.stack && (
              <pre className="text-[11px]">
                <code>{baseError.stack}</code>
              </pre>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ErrorComponent;
