import clsx from 'clsx';
import Color from 'color';
import React, { useRef } from 'react';

import { Dialogue as IDialogue, useDialogue } from '../hooks/dialogue';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  color: string;
}

const DialogueButton: React.FC<ButtonProps> = ({ color, className, style, ...props }) => {
  const parsedColor = new Color(color);
  const textColor = parsedColor.isDark() ? 'white' : 'black';

  return (
    <button
      className={clsx(
        'rounded-lg flex items-center justify-center transition-colors py-2 px-5 text-[14px]',
        className
      )}
      style={{ backgroundColor: color, color: textColor, ...style }}
      {...props}
    >
      {props.children}
    </button>
  );
};

const Dialogue: React.FC<IDialogue> = ({ options, content, id }) => {
  const { removeDialogue } = useDialogue();
  const containerRef = useRef<HTMLDivElement>(null);
  const {
    showCancel = true,
    cancelText,
    confirmText,
    confirmColor,
    showConfirm = true,
    onCancel,
    onConfirm,
  } = options;

  const onHandleClick = (callback: (() => void) | undefined) => {
    removeDialogue(id);
    return callback && callback();
  };

  return (
    <div
      ref={containerRef}
      className={clsx(
        `z-50 fixed 
         -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2
         bg-white
         rounded-2xl p-[30px]
         flex flex-col items-center justify-center text-left 
         transition-all duration-200
         max-w-[421px]
         select-none`
      )}
    >
      <div className="w-full mb-[30px]">{content}</div>
      <div className="flex justify-end w-full">
        {showCancel && (
          <DialogueButton
            className="mr-[30px]"
            color="#6A6A6A"
            onClick={() => onHandleClick(onCancel)}
          >
            {cancelText ?? 'Cancel'}
          </DialogueButton>
        )}
        {showConfirm && (
          <DialogueButton
            color={confirmColor ?? '#0029FF'}
            onClick={() => onHandleClick(onConfirm)}
          >
            {confirmText ?? 'Confirm'}
          </DialogueButton>
        )}
      </div>
    </div>
  );
};

export default Dialogue;
