import React from 'react';

import { ReactComponent as DocumentIcon } from '../assets/images/icons/Document.svg';
import useFavicon from '../hooks/favicon';

interface FaviconProps {
  url: string;
  size: number;
  className?: string;
}

const Favicon: React.FC<FaviconProps> = ({ url, size, className }) => {
  const faviconUrl = useFavicon(url, size);
  return faviconUrl ? (
    <img
      className={className}
      style={{
        width: size,
        height: size,
      }}
      src={faviconUrl}
      alt="favicon"
    />
  ) : (
    <DocumentIcon className={className} />
  );
};

export default Favicon;
